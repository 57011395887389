import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ReactHtmlParser from "react-html-parser";
import * as Constant from "../../Constant";
import axios from "axios";
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from "universal-cookie";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

const cookies = new Cookies();

class DetailProgram extends Component {
  constructor() {
    super();
    this.state = {
      program: {},
      image_link: "",

      target: 0,
      collected: 0,
      percentage: 0,
      final_day: Date.now(),
      donatur: 0,

      total_donatur: 0,
      donaturs: [],

      page: 1,
      last_page: 1,
      loading: true,

      go_back: false,
      go_donate: false,

      kategori: "",
      info: [],
    };

    this.trackScrolling = this.trackScrolling.bind(this);
  }

  componentDidMount() {
    document.body.style.backgroundImage = "none";
    this.getProgramDetail();

    document.addEventListener("scroll", this.trackScrolling);
  }
  componentWillUnmount() {
    document.body.style.backgroundImage = `url('/images/.jpg')`;

    document.removeEventListener("scroll", this.trackScrolling);
  }

  setAgency() {
    let program = this.state.program;
    let currentUrl = window.location.href;
    let breakUrl = currentUrl.split("/");

    const queryParams = new URLSearchParams(window.location.search);
    let agency = queryParams.get("agency");
    console.log("QUERY = ", agency);

    if (agency != null) {
      // console.log(breakUrl)
      const array = {
        id: program.id,
        seo: program.seo,
        agency: agency,
      };
      cookies.set(program.seo, array);

      axios
        .get(Constant.GET_AGENCY + agency)
        .then((res) => {
          // console.log(res.data.id)
          let parameter = {
            id_program: program.id,
            id_agency: res.data.id,
          };
          axios
            .post(Constant.COUNT_AGENCY, parameter)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let parameter = {
        id_program: program.id,
        id_agency: 0,
      };
      axios
        .post(Constant.COUNT_AGENCY, parameter)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  trackScrolling() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.getElementById("programs_scroller");
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight && this.state.page != this.state.last_page) {
      // console.log(windowBottom + " == " + docHeight)
      // reach to bottom
      let next = this.state.page + 1;
      this.setState({
        page: next,
        loading: true,
      });
      this.getDonaturs();
    }
  }
  getProgramDetail() {
    let {
      match: { params },
    } = this.props;

    let currentUrl = window.location.href;
    let breakUrl = currentUrl.split("/");

    axios
      .get(Constant.GET_PROGRAM_DETAIL + breakUrl[5])
      .then((response) => {
        window.ViewContent(response.data.data.judul, "Program");
        let program = response.data.data;
        let target = program.program.dana_target;
        let collected = program.program.donatur[0]
          ? program.program.donatur[0].total
          : 0;

        let percentage = 0;

        if (
          program.program.dana_target != null ||
          program.program.dana_target >= 0
        ) {
          percentage = (collected / target) * 100;
        } else {
          percentage = 100;
        }

        let tanggal_akhir = null;

        if (!response.data.data.program.tanggal) {
          tanggal_akhir = null;
        } else {
          new Date(response.data.data.program.tanggal);
        }

        this.setState({
          program: program,
          image_link:
            Constant.GET_PROGRAMS_IMAGE + response.data.data.id_program,

          target: target,
          collected: collected,
          percentage: percentage,

          final_day: tanggal_akhir,
        });
        axios
          .get(Constant.GET_COUNT_DONATION + response.data.data.id_program)
          .then((res) => {
            console.log(res.data.countDonasi, "asu");
            this.setState({
              donatur: res.data.countDonasi,
            });
          });
        this.setAgency();
        this.getDonaturs();
        this.countDonatur();
        this.getInfo(program.id_program);
      })
      // Catch any error here
      .catch((error) => {
        console.log(error);
      });
  }
  getDonaturs() {
    const { program, page } = this.state;

    let query_url = `?page=${page}`;

    axios
      .get(Constant.GET_DONATURS + program.id + query_url)
      .then((response) => {
        if (page == 1) {
          this.setState({
            donaturs: response.data.data,
            last_page: response.data.last_page,
            loading: false,
          });
        } else {
          console.log("tambah");
          let newdonaturs = this.state.donaturs.concat(response.data.data);
          this.setState({
            donaturs: newdonaturs,
            last_page: response.data.last_page,
            loading: false,
          });
          console.log(response.data.data);
        }
      })
      .catch((error2) => {
        console.log(error2);
      });
  }
  countDonatur() {
    const { program } = this.state;

    axios
      .get(Constant.COUNT_DONATURS + program.id)
      .then((response3) => {
        this.setState({
          total_donatur: response3.data.data,
        });
      })
      .catch((error3) => {
        console.log(error3);
      });
  }
  numberFormat(value) {
    let val = 0;
    if (value) {
      val = value;
    }
    return val
      .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
      .replace(/\,/g, ".");
  }
  dateFormat(date) {
    if (date) {
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jum`at",
        "Sabtu",
      ];
      let months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      let new_date = new Date(date);
      let formatted_date =
        days[new_date.getDay()] +
        ", " +
        new_date.getDate() +
        " " +
        months[new_date.getMonth()] +
        " " +
        new_date.getFullYear() +
        " " +
        new_date.getHours() +
        ":" +
        new_date.getMinutes();

      return formatted_date;
    } else {
      return "Tanpa Batas Waktu";
    }
  }
  gotToDonate(v) {
    window.AddToCart();
    axios
      .get(Constant.GET_CATEGORY + v)
      .then((res) => {
        console.log(res);
        this.setState({
          kategori: res.data.seo,
          go_donate: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getInfo(id_program) {
    console.log(id_program);
    axios
      .get(Constant.GET_INFO_TERBARU + id_program)
      .then((res) => {
        console.log(res.data.data);
        this.setState({
          info: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const {
      match,
      match: { params },
    } = this.props;
    const {
      program,
      image_link,
      go_back,
      go_donate,
      kategori,
      target,
      collected,
      percentage,
      final_day,
      donatur,
      total_donatur,
      donaturs,
      info,
    } = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    let agency = queryParams.get("agency");

    let seo = program.seo;

    let url = this.props.location.pathname.split("/");
    let pls = null;

    let linkFacebook =
      "https://www.facebook.com/sharer/sharer.php?u=https://mizanamanah.or.id/detailprogram/" +
      program.seo;
    let linkTwitter =
      "http://twitter.com/intent/tweet?url=" +
      program.judul +
      " Klik Link Berikut https://mizanamanah.or.id/detailprogram/" +
      program.seo;
    let linkEmail =
      "mailto:?Subject=&Body=" +
      program.judul +
      " " +
      program.resume +
      " Klik Link Berikut https://mizanamanah.or.id/detailprogram/" +
      program.seo;
    let linkWhatsapp =
      "https://api.whatsapp.com/send?text=" +
      program.judul +
      " " +
      program.resume +
      " Klik Link Berikut https://mizanamanah.or.id/detailprogram/" +
      program.seo;

    if (url[3]) {
      pls = seo + "/" + url[3];
    } else {
      pls = seo;
    }

    if (go_back) return <Redirect exact to="/programs" />;
    else if (go_donate) {
      if (agency != null) {
        return (
          <Redirect
            exact
            to={
              `/programs/` + kategori + `/` + seo + `/donate?agency=` + agency
            }
          />
        );
      } else {
        return (
          <Redirect
            exact
            to={`/programs/` + kategori + `/` + seo + `/donate`}
          />
        );
      }
    }
    // return <Redirect exact to={`/donateform/${pls}`} />
    // return <Redirect exact to={`/detailprogram/${seo}`+'/donateform'} />

    return (
      <div>
        <link rel="canonical" href={window.location.href}></link>

        <div>
          <header id="header">
            <nav className="left header-kembali">
              <i
                id="ic-sidebar"
                className="fa fa-arrow-left"
                onClick={() => this.setState({ go_back: true })}
              ></i>
              {String(program.judul).substring(0, 32)}...
            </nav>
          </header>
        </div>

        <CustomBreadcrumbs link={this.props.location.pathname} />

        <section
          style={{ width: "400px", margin: "auto" }}
          id="btn-donasi-bawah"
          onClick={() => this.gotToDonate(program.program.id_category)}
        >
          <p className="txt-btn-donasi">DONASI SEKARANG</p>
        </section>

        {/* popup list info */}
        <div id="info_popup" className="modalfilter">
          <div className="filter-content" style={{ height: "90vh" }}>
            <span
              className="close"
              onClick={() =>
                (document.getElementById("info_popup").style.display = "none")
              }
            >
              &times;
            </span>

            <p className="head-filter">List Info Terbaru</p>
            <div
              className="scroller-category"
              style={{ height: 70 + "vh", padding: "30px" }}
            >
              {info.map((inf, i) => {
                return (
                  <div key={i}>
                    <h5>{inf.detail.judul}</h5>
                    {this.dateFormat(inf.detail.created_at)}
                    <br />
                    {ReactHtmlParser(inf.detail.content)}
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <section id="riwayat-box">
          <div
            className="scroller-program"
            id="programs_scroller"
            style={{ marginTop: 10 + "px" }}
          >
            <div className="card-program">
              <div
                className="banner-program"
                style={{ backgroundImage: `url(${image_link})` }}
              ></div>
              <p className="title-card-program txt-18">{program.judul}</p>
              <p className="txt-batas-waktu">{this.dateFormat(final_day)}</p>
              <progress id="file" value={percentage} max="100">
                {" "}
                {percentage}%{" "}
              </progress>
              <p className="txt-donasi">{donatur} Donasi</p>
              <p className="txt-batas-waktu">
                Terkumpul Rp. {this.numberFormat(collected)} dari Rp.{" "}
                {this.numberFormat(target)}
              </p>
              <div className="content-detail">
                {ReactHtmlParser(program.deskripsi)}
              </div>
              <div className="content-detail">
                <hr />
                Mari berbagi informasi yang insha Allah bermanfaat kepada rekan
                dan saudara kita. Semoga menjadi amal soleh yang membawa
                keberkahan untuk anda. klik tombol share di bawah ini.
                <table style={{ marginBottom: "0px" }}>
                  <tr>
                    <td width="25%" align="center">
                      <a href={linkFacebook}>
                        <img src="/facebook.png" style={{ maxWidth: "50px" }} />
                      </a>
                    </td>
                    <td width="25%" align="center">
                      <a href={linkTwitter}>
                        <img src="/twitter.png" style={{ maxWidth: "50px" }} />
                      </a>
                    </td>
                    <td width="25%" align="center">
                      <a href={linkEmail}>
                        <img src="/gmail.png" style={{ maxWidth: "50px" }} />
                      </a>
                    </td>
                    <td width="25%" align="center">
                      <a href={linkWhatsapp}>
                        <img src="/whatsapp.png" style={{ maxWidth: "50px" }} />
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            {/* progress */}
            {info.length ? (
              <div className="card-program">
                <div className="item-donatur">
                  {/* <div className="box-nominal" style={{ minWidth: "100%" }}>
                <button
                  onClick={() =>
                    (document.getElementById("info_popup").style.display =
                      "block")
                  }
                  style={{ minWidth: "100%", backgroundColor: "#E91D25" }}
                >
                  Lihat Info Terbaru
                </button>
              </div> */}
                  <div className="filter-content" style={{ height: "90vh" }}>
                    <span
                      className="close"
                      onClick={() =>
                        (document.getElementById("info_popup").style.display =
                          "none")
                      }
                    >
                      &times;
                    </span>

                    <p className="head-filter">Info Terbaru</p>
                    <div
                      className="scroller-category"
                      style={{ height: 80 + "vh", padding: "30px" }}
                    >
                      {info.map((inf, i) => {
                        return (
                          <div key={i}>
                            <h5>{inf.detail.judul}</h5>
                            {this.dateFormat(inf.detail.created_at)}
                            <br />
                            {ReactHtmlParser(inf.detail.content)}
                            <hr />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* <div className='container-fluid' >
                            <OwlCarousel items={2} className="owl-theme" loop dots ={false} margin={8} >  
                                <div className="card-mitra-home">
                                    <div className="mitra" style={{backgroundImage: 'url("https://mizanamanah.or.id/admin/assets/media/logo-mitra-web/ALFAMART.jpg")'}}>
                                        
                                    </div>
                                </div>
                                <div className="card-mitra-home">
                                    <div className="mitra" style={{backgroundImage: 'url("https://mizanamanah.or.id/admin/assets/media/logo-mitra-web/forum-zakat.jpg")'}}>
                                        
                                    </div>
                                </div>
                                <div className="card-mitra-home">
                                    <div className="mitra" style={{backgroundImage: 'url("https://mizanamanah.or.id/admin/assets/media/logo-mitra-web/kementrian-agama.jpg")'}}>
                                        
                                    </div>
                                </div>
                                <div className="card-mitra-home">
                                    <div className="mitra" style={{backgroundImage: 'url("https://mizanamanah.or.id/admin/assets/media/logo-mitra-web/gopay.jpg")'}}>
                                        
                                    </div>
                                </div>
                                <div className="card-mitra-home">
                                    <div className="mitra" style={{backgroundImage: 'url("https://mizanamanah.or.id/admin/assets/media/logo-mitra-web/emco.jpg")'}}>
                                        
                                    </div>
                                </div>
                            </OwlCarousel>  
                        </div> */}

            <div className="card-program">
              <h3 className="color-red">Donatur ({total_donatur})</h3>
            </div>

            <div className="card-program">
              {donaturs.map((pendonasi, i) => {
                let foto = null;
                if (pendonasi.guest != null) {
                  foto =
                    "https://cfd-v1.mizanamanah.or.id/admin/assets/media/icons/icon-people.png";
                } else {
                  if (pendonasi.user != null) {
                    foto = Constant.GET_PROFILE_PICTURE + pendonasi.user.id;
                  } else {
                    foto =
                      "https://cfd-v1.mizanamanah.or.id/admin/assets/media/icons/icon-people.png";
                  }
                }
                return (
                  <div className="item-donatur" key={i}>
                    <div
                      className="img-donatur"
                      style={{ backgroundImage: `url(${foto})` }}
                    ></div>
                    <div className="box-nominal">
                      <p>
                        {pendonasi.guest
                          ? pendonasi.guest.nama_lengkap
                          : pendonasi.user
                          ? pendonasi.user.name
                          : "-"}
                      </p>
                      <h4>Rp. {this.numberFormat(pendonasi.total_donasi)}</h4>
                    </div>
                  </div>
                );
              })}
              <div
                className="sweet-loading"
                style={{ height: 50 + "px", marginBottom: 30 + "px" }}
              >
                <ClipLoader
                  css={override}
                  size={40}
                  color={"#e91d24"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default DetailProgram;
