import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../Constant'
import './home.css'
import axios from 'axios'

import Header from '../Components/Header'
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs'

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`; 

class Home extends Component {
    // Adds a class constructor that assigns the initial state values:
    constructor() {
        super()
        this.state = {
            banner: '',
            banner_title: '',
            banner_desc: '',
            banner_button: '',
            categories: [],
            category_id: null,
            total_zakat: 0,
            total_donasi: 0,
            total_donatur: 0,

            donate_link: '/',
            go_donate_banner: false,
            go_zakat: false,
            go_donate: false,
            go_detail_program: false,
            go_detail_update: false,
            go_detail_sukses: false,
            
            seo_key: '',

            link_youtube: '',
            programs: [],
            kisah_sukses: [],
            program_infaq: [],
            program_zakat: [],
            updateArtikel: [],

            loading: true,

            go_chat: false,
            go_list_cs: false,
            kategoriProgram: ''
        }

        this.timer = null
    }
    // This is called when an instance of a component is being created and inserted into the DOM.
    componentDidMount() {
        document.body.style.backgroundImage = "none"
        this.getContent()
        // console.log(this.state.link_youtube != '')
        // if(this.state.link_youtube != ''){
            
        // }
    }

    getDashboardBanner() {
        // get banner
        axios.get(Constant.GET_DASHBOARD_BANNER)
            .then(response => {
                // console.log(response.data)
                this.setState({ 
                    banner: Constant.GET_DASHBOARD_BANNER_SLIDER + response.data.data[0].id,
                    banner_title: response.data.data[0].judul,
                    banner_desc: response.data.data[0].deskripsi,
                    banner_button: response.data.data[0].title_button,
                    donate_link: response.data.data[0].link
                });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getCategories() {
        // get categories
        axios.get(Constant.GET_CATEGORIES)
            .then(response => {
                // console.log(response.data)
                this.setState({ categories: response.data.data });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    getStatistik(){
        //zakat
        axios.get(Constant.GET_TOTAL_ZAKAT)
            .then(response => {
                // console.log(response.data)
                this.setState({ total_zakat: response.data.total })
            })
            .catch(error => {
                console.log(error)
            })
            
        //donasi
        axios.get(Constant.GET_TOTAL_DONASI)
            .then(response => {
                // console.log(response.data)
                this.setState({ total_donasi: response.data.total })
            })
            .catch(error => {
                console.log(error)
            })
        
        //donatur
        axios.get(Constant.GET_TOTAL_DONATUR)
            .then(response => {
                // console.log(response.data)
                this.setState({ total_donatur: response.data.total })
            })
            .catch(error => {
                console.log(error)
            })
    }
    getContent(){
        axios.get(Constant.GET_DASHBOARD_CONTENT)
        .then(response => {
            // console.log(response.data)
            this.setState({
                link_youtube: response.data.linkYoutube,
                programs: response.data.program,
                kisah_sukses: response.data.kisah_sukses,
                program_infaq: response.data.program_infaq,
                program_zakat: response.data.program_zakat,
                updateArtikel: response.data.updateArtikel
            })

            this.getDashboardBanner()
            this.getCategories()
            this.getDashboardBanner()
            this.getStatistik()
            this.setState({
                loading: false
            })
            this.infaqClicked()
        }).catch(error => {
            console.log(error)
        })
    }


    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    nFormatter(num, digits) {
        var si = [
          { value: 1, symbol: "" },
          { value: 1E3, symbol: "K" },
          { value: 1E6, symbol: "M" },
          { value: 1E9, symbol: "B" },
          { value: 1E12, symbol: "T" },
          { value: 1E15, symbol: "P" },
          { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
            break;
          }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret', 
                'April', 'Mei', 'Juni', 'Juli', 
                'Agustus', 'September', 'Oktober', 
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", " 
                                + new_date.getDate() + " "
                                + months[new_date.getMonth()] + " "
                                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    infaqClicked(){
        document.getElementById("tab-infaq").classList.add("item-tab-active");
        document.getElementById("tab-infaq").classList.remove("item-tab");
        document.getElementById("tab-zakat").classList.add("item-tab");
        document.getElementById("tab-zakat").classList.remove("item-tab-active");
        document.getElementById("infaq-scroller").classList.remove("hide");
        document.getElementById("zakat-scroller").classList.add("hide");
    }
    zakatClicked(){
        document.getElementById("tab-infaq").classList.add("item-tab");
        document.getElementById("tab-infaq").classList.remove("item-tab-active");
        document.getElementById("tab-zakat").classList.add("item-tab-active");
        document.getElementById("tab-zakat").classList.remove("item-tab");
        document.getElementById("zakat-scroller").classList.remove("hide");
        document.getElementById("infaq-scroller").classList.add("hide");
    }

    // The render method contains the JSX code which will be compiled to HTML.
    render() {
        const { 
            banner, banner_title, banner_desc, banner_button,
            categories, category_id, go_donate_banner, donate_link,
            total_zakat, total_donasi, total_donatur, go_zakat, go_donate, seo_key, go_detail_program, go_detail_update,
            link_youtube, kisah_sukses, program_infaq, program_zakat, updateArtikel, programs, go_chat, go_list_cs, go_detail_sukses,
            kategoriProgram
        } = this.state

        if (this.props.match.url === "/home")
            window.location.href = "/"

        if (go_donate_banner)
            return <Redirect exact to={donate_link} />

        if (go_zakat)
            return <Redirect exact to="/bayar-zakat" />

        else if (go_detail_update)
            return <Redirect exact to={`/update/${seo_key}`} />

        else if (go_detail_sukses)
            return <Redirect exact to={`/kisah-sukses/${seo_key}`} />

        else if (go_detail_program)
            return <Redirect exact to={`/programs/`+kategoriProgram+`/`+seo_key} />

        else if (go_donate)
            return <Redirect exact to={`/programs/`+kategoriProgram+`/`+seo_key+`/donate`} />

        if (category_id)
            return <Redirect exact to={{
                            pathname: "/programs",
                            state: { category_id: category_id }
                        }}
                    />
                    
        else if(go_chat)
            return <Redirect exact to={`/firebase/messaging/form`} />
        
        else if(go_list_cs)
            return <Redirect exact to={`/list_customer_service`} />

        return (
        <div>
            
            <Header />
            <CustomBreadcrumbs link={this.props.location.pathname} />

            <section id="banner" style={{backgroundImage: `url(${banner})`, zIndex: 99}}>
                <div className="content">
                    <div className="text" id="text-banner">
                        <p id="title-banner">{banner_title}</p>
                        <p id="desc-banner">{banner_desc}</p>
                    </div>
                    <div className="btn-donasi" style={{position: 'absolute', right: 0, bottom: 0, marginRight: 20+'px'}}>
                        <ul className="actions">
                            <li>
                                <a href={donate_link} className="btn-banner scrolly" style={{padding: 10+"px"}}>
                                    {banner_button ? banner_button : "Donasi Sekarang"}
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </section>

            <div className="statistik-box" style={{display: 'none'}}>
				<div className="item-statistik">
					<p className="title-text">Zakat B</p>
					<p className="nominal-text">{this.nFormatter(total_zakat, 2).replace('.00', '')}</p>
				</div>
				<div className="item-statistik">
					<p className="title-text">Donasi Terkumpul</p>
                    <p className="nominal-text">{this.nFormatter(total_donasi, 2).replace('.00', '')}</p>
				</div>
				<div className="item-statistik">
					<p className="title-text">Donatur</p>
					<p className="nominal-text">{this.nFormatter(total_donatur, 2).replace('.00', '')}</p>
				</div>
			</div>

            <div className="sweet-loading" style={{height: 50+'px'}}>
                <ClipLoader
                    css={override}
                    size={40}
                    color={"#e91d24"}
                    loading={this.state.loading}
                />
            </div>
            
            <h1 className="title-home" style={{fontSize: '20px'}}>Program Infaq dan Zakat</h1>
			<div className="tab-program-home">
				<div className="item-tab-active" id="tab-infaq" onClick={() => this.infaqClicked()}>Infaq</div>
				<div className="item-tab" id="tab-zakat" onClick={() => this.zakatClicked()}>Zakat</div>
				{/* <div className="item-tab-active" id="tab-infaq">Infaq</div>
				<div className="item-tab" id="tab-infaq">Zakat</div> */}
			</div>

            {/* infaq */}
            <div className='container-fluid hide' id="infaq-scroller">            
                <OwlCarousel items={1}  
                className="owl-theme"  
                loop  
                dots ={false}
                stagePadding = {25}
                lazyLoad = {true}
                margin={8} >  
				 {program_infaq.map((infaq, i) => {
                    let percent = 0
                    percent = (infaq.total/infaq.dana_target)*100
                    if(percent > 100){
                        percent = 100
                    }
                    let image_link = Constant.GET_PROGRAMS_IMAGE + infaq.id
                    return(
                        <div className="card-program-home" key={i} style={{marginLeft: `13px`}}>
                            <div className="img-card" style={{backgroundImage: `url(${image_link})`, backgroundColor: '#e0e0e0'}}>
                                <div className="label">{infaq.category}</div>
                            </div>
                            <p className="title-card">{infaq.judul}</p>
                            <ul className="skills mb-3" style={{padding: 10+'px'}}>
                                <li data-percent="">
                                    <div className="progress skills-animated" style={{width: `${percent}%`}}>
                                    </div>
                                </li>
                            </ul>
                            <h6 className="padding-left-right" style={{whiteSpace: 'normal', paddingRight: 10+'px !important', paddingLeft: 10+'px !important'}}>
                                Terkumpul Rp. {this.numberFormat(infaq.total)} dari Rp. {this.numberFormat(infaq.dana_target)}
                            </h6>
                            
                            <p className="padding-left-right mb-0" style={{fontSize: 13+'px', whiteSpace: 'normal'}}>
                            { ReactHtmlParser(infaq.resume) }
                            </p>
                            <a href="" className="red-text padding-left-right" onClick={() => this.setState({ go_detail_program: true, seo_key: infaq.seo, kategoriProgram: infaq.seo })}>
                                Read More
                            </a>
                            <div className="footer-card">
                            <div className="batas-waktu-box">
                                <p className="mb-1">Batas Waktu</p>
                                <p className="mb-1"><b>{this.dateFormat(infaq.tanggal)}</b></p>
                            </div>
                            <a href="" style={{textDecoration: 'none'}} onClick={() => this.setState({ go_donate: true, seo_key: infaq.seo, kategoriProgram: infaq.seo })}><p className="card-btn">Donasi Sekarang</p></a><br/><br/>
                            </div>
                        </div>
                    )
                })}
                </OwlCarousel>  
            </div>

            {/* zakat */}
            <div className='container-fluid hide' id="zakat-scroller">            
                <OwlCarousel items={1}  
                className="owl-theme"  
                loop  
                dots ={false}
                stagePadding = {25}
                lazyLoad = {true}
                margin={8} >  
				{program_zakat.map((zakat, i) => {
                    let percent = 0
                    percent = (zakat.total/zakat.dana_target)*100
                    if(percent > 100){
                        percent = 100
                    }
                    let image_link = Constant.GET_PROGRAMS_IMAGE + zakat.id

                    return(
                        <div className="card-program-home" key={i} style={{marginLeft: `13px`}}>
                            <div className="img-card" style={{backgroundImage: `url(${image_link})`, backgroundColor: '#e0e0e0'}}>
                                <div className="label">{zakat.category}</div>
                            </div>
                            <p className="title-card">{zakat.judul}</p>
                            <ul className="skills mb-3" style={{padding: 10+'px'}}>
                                <li data-percent="">
                                    <div className="progress skills-animated" style={{width: `${percent}%`}}>
                                    </div>
                                </li>
                            </ul>
                            <h6 className="padding-left-right" style={{whiteSpace: 'normal', paddingRight: 10+'px !important', paddingLeft: 10+'px !important'}}>
                                Terkumpul Rp. {this.numberFormat(zakat.total)} dari Rp. {this.numberFormat(zakat.dana_target)}
                            </h6>
                            
                            <p className="padding-left-right mb-0" style={{fontSize: 13+'px', whiteSpace: 'normal'}}>
                            { ReactHtmlParser(zakat.resume) }
                            </p>
                            <a href="" className="red-text padding-left-right" onClick={() => this.setState({ go_detail_program: true, seo_key: zakat.seo, kategoriProgram: zakat.seo })}>
                                Read More
                            </a>
                            <div className="footer-card">
                            <div className="batas-waktu-box">
                                <p className="mb-1">Batas Waktu</p>
                                <p className="mb-1"><b>{this.dateFormat(zakat.tanggal)}</b></p>
                            </div>
                            <a href="" style={{textDecoration: 'none'}} onClick={() => this.setState({ go_donate: true, seo_key: zakat.seo, kategoriProgram: zakat.seo })}><p className="card-btn">Donasi Sekarang</p></a><br/><br/>
                            </div>
                        </div>
                    )
                })}
                </OwlCarousel>  
            </div>

            {/* program */}
            <h3 className="title-home" style={{marginBottom: 20+'px'}}>Program</h3>
            <div style={{
                width: '100%',
                padding: '25px',
                paddingTop: '0px'
            }}>
                <center>
                    <p>Beragam Program Lembaga Amil Zakat Mizan Amanah seperti Panti Asuhan Islam, Santunan Anak Yatim, dan berbagai pilihan donasi online untuk membantu sesama.</p>
                </center>
            </div>
            <div className='container-fluid' >            
                <OwlCarousel items={1}  
                className="owl-theme"  
                loop  
                dots ={false}
                stagePadding = {25}
                lazyLoad = {true}
                margin={8} >  
				{programs.map((program, i) => {
                    let percent = 0
                    percent = (program.total/program.dana_target)*100
                    if(percent > 100){
                        percent = 100
                    }
                    let image_link = Constant.GET_PROGRAMS_IMAGE + program.id

                    return(
                        <div className="card-program-home" key={i} style={{marginLeft: `13px`}}>
                            <div className="img-card" style={{backgroundImage: `url(${image_link})`, backgroundColor: '#e0e0e0'}}>
                                <div className="label">{program.category}</div>
                            </div>
                            <p className="title-card">{program.judul}</p>
                            <ul className="skills mb-3" style={{padding: 10+'px'}}>
                                <li data-percent="">
                                    <div className="progress skills-animated" style={{width: `${percent}%`}}>
                                    </div>
                                </li>
                            </ul>
                            <h6 className="padding-left-right" style={{whiteSpace: 'normal', paddingRight: 10+'px !important', paddingLeft: 10+'px !important'}}>
                                Terkumpul Rp. {this.numberFormat(program.total)} dari Rp. {this.numberFormat(program.dana_target)}
                            </h6>
                            
                            <p className="padding-left-right mb-0" style={{fontSize: 13+'px', whiteSpace: 'normal'}}>
                            { ReactHtmlParser(program.resume) }
                            </p>
                            <a href="" className="red-text padding-left-right" onClick={() => this.setState({ go_detail_program: true, seo_key: program.seo, kategoriProgram: program.seo })}>
                                Read More
                            </a>
                            <div className="footer-card">
                                <div className="batas-waktu-box">
                                    <p className="mb-1">Batas Waktu</p>
                                    <p className="mb-1"><b>{this.dateFormat(program.tanggal)}</b></p>
                                </div>
                                <a href="" style={{textDecoration: 'none'}} onClick={() => this.setState({ go_donate: true, seo_key: program.seo, kategoriProgram: program.seo })}><p className="card-btn">Donasi Sekarang</p></a><br/><br/>
                            </div>
                        </div>
                    )
                })}
                </OwlCarousel>  
            </div>

            {/* embed */}
            <iframe className="frame-video" src="https://www.youtube.com/embed/fA18GMvdNY0">
			</iframe>
			<br/><br/><br/><br/>

            {/* update artikel */}
            <h3 className="title-home" style={{marginBottom: 20+'px'}}>Update & Berita</h3>

            <div className='container-fluid' >            
                <OwlCarousel items={1}  
                className="owl-theme"  
                loop  
                dots ={false}
                stagePadding = {30}
                lazyLoad = {true}
                margin={8} >  
				{updateArtikel.map((artikel, i) => {
                    let image_link = Constant.GET_UPDATES_IMAGE + artikel.id_posting
                    return(
                        <div className="card-artikel-home" key={i} style={{marginLeft: `13px`}}>
                            <div className="img-card" style={{backgroundImage: `url(${image_link})`, backgroundColor: '#e0e0e0'}}>
                                <div className="label">{artikel.category}</div>
                            </div>
                            <p className="title-card">{artikel.judul}</p>
            
                            <h6 className="padding-left-right" style={{whiteSpace: 'normal', marginTop: 10+'px', paddingRight: 10+'px !important', paddingLeft: 10+'px !important'}}>
                                {artikel.created_at}
                            </h6>
                            
                            <p className="padding-left-right mb-0" style={{fontSize: 13+'px', whiteSpace: 'normal'}}>
                            { ReactHtmlParser(String(artikel.content).substring(0, 200)) }
                            </p>
                            <a href="" className="red-text padding-left-right" onClick={() => this.setState({ go_detail_update: true, seo_key: artikel.seo })}>
                                Read More
                            </a>
                            
                        </div>
                    )
                })}
                </OwlCarousel>  
            </div>
            
            {/* kata mereka */}
            <h3 className="title-home" style={{marginBottom: 20+'px'}}>Kata Mereka</h3>
            <div className='container-fluid' >            
                <OwlCarousel items={1}  
                className="owl-theme owl-height"  
                loop  
                dots ={false}
                stagePadding = {30}
                lazyLoad = {true}
                margin={8} >  
				{kisah_sukses.map((kisah, i) => {
                    let image_link = Constant.GET_UPDATES_IMAGE + kisah.id_posting
                    return(
                        <div className="card-artikel-home" key={i} style={{marginLeft: `13px`}}>
                            <div className="img-card" style={{backgroundImage: `url(${image_link})`, backgroundColor: '#e0e0e0'}}>
                                
                            </div>
                            <p className="title-card">{kisah.judul}</p>
            
                            <h6 className="padding-left-right" style={{whiteSpace: 'normal', marginTop: 10+'px', paddingRight: 10+'px !important', paddingLeft: 10+'px !important'}}>
                                {kisah.created_at}
                            </h6>
                            
                            <p className="padding-left-right mb-0" style={{fontSize: 13+'px', whiteSpace: 'normal'}}>
                            { ReactHtmlParser(String(kisah.content).substring(0, 200)) }
                            </p>
                            <a href="" className="red-text padding-left-right" onClick={() => this.setState({ go_detail_sukses: true, seo_key: kisah.seo })}>
                                Read More
                            </a>
                            
                        </div>  
                    )
                })}
                </OwlCarousel>  
            </div>

            {/* sponsor */}
            <h3 className="title-home" style={{marginBottom: 20+'px'}}>Mitra Perusahaan</h3>
			<p style={{textAlign: 'center'}}>
				Alhamdulilah Beberapa Perusahaan Telah Menyalurkan Donasinya Sebagai Bentuk Dukungan Kepada Yayasan Mizan Amanah
			</p>
			<br/>
            <div className='container-fluid' >            
                <OwlCarousel items={2}  
                className="owl-theme"  
                loop  
                dots ={false}
                margin={8} >  
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/ALFAMART.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/forum-zakat.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/kementrian-agama.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/gopay.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/emco.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/kitabisa.com.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/garuda-indonesia.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/mubadala.jpg")'}}>
                            
                        </div>
                    </div>
                    <div className="card-mitra-home">
                        <div className="mitra" style={{backgroundImage: 'url("https://cfd-v1.mizanamanah.or.id/admin/assets/media/logo-mitra-web/kementrian-sosial.jpg")'}}>
                            
                        </div>
                    </div>
                </OwlCarousel>  
            </div>
            
            <div style={{display: 'none'}} className="live-btn" onClick={() => this.setState({go_chat: true})}>
                <img src="images/ic-live.png" className="ic-live"/>
            </div>
            
            <div className="wa-btn" onClick={() => this.setState({go_list_cs: true})}>
                <img src="whatsapp.png" className="ic-live"/>
            </div>
            {/* footer */}
			<div className="footer-home">
                <img src="images/logo-mizan-amanah-white.png" alt="" className="logo-footer" /> <br/><br/>

				Jl. Ulujami Raya No.111 Kel. Ulujami Kec. Pesanggrahan Jakarta Selatan - Telp. (021) 2765 9993
                <br/><br/>
                Mizan Amanah tidak menerima segala bentuk dana yang bersumber dari bentuk kejahatan.
			</div>
        </div>
        )
    }
}

export default Home