import * as firebase from 'firebase'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const config = {
  apiKey: "AIzaSyBQRNQ_uac4lVUgZJT8ayeDAcwRVUpbIFQ",
  authDomain: "mizan-amanah-ios-cfd.firebaseapp.com",
  databaseURL: "https://mizan-amanah-ios-cfd-default-rtdb.firebaseio.com",
  projectId: "mizan-amanah-ios-cfd",
  storageBucket: "mizan-amanah-ios-cfd.appspot.com",
  messagingSenderId: "784721883986",
  appId: "1:784721883986:web:4a8bde378e2c7462f3c08b",
  measurementId: "G-NH9LFPTFT0"
}
firebase.initializeApp(config)

const initializeFirebase = () => {
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
            firebase.messaging().useServiceWorker(registration);
        });
}

const askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
    //   console.log('user token:', token);
      cookies.set('fcmToken', token)
      
      return token;
    } catch (error) {
      console.error(error);
    }
  }

const database = firebase.database();

export {
  initializeFirebase,
  askForPermissioToReceiveNotifications,
  database
}