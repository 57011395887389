import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import axios from 'axios'
import * as Constant from '../Constant'
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

class ZakatNew extends Component {
    constructor() {
        super()
        this.state = {
            programs: [],
            category_id: 37,
            page: 1,
            last_page: 1,
            seo_key: '',

            loading: true,
            go_detail: false,
            go_donate: false,
            go_home: false,
            go_zakat: false,
            go_calculator: false
        }
        this.timer = null
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
        document.addEventListener('scroll', this.trackScrolling)
        this.getPrograms()

    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
        document.removeEventListener('scroll', this.trackScrolling)
    }

    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight && 
            this.state.page != this.state.last_page) 
        {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({ 
                page: next,
                loading: true 
            })
            this.getPrograms()
            // console.log('there it is')
        }
    }
    getPrograms() {
        const { page, category_id } = this.state
        let halaman = 1;
        console.log(category_id, 'id yeuh')

        let query_url = `?page=${halaman}`
        if (category_id)
            query_url += `&idKategori=${category_id}`

        axios.get(Constant.GET_PROGRAMS + query_url)
            .then(response => {
                console.log(response.data)
                if (page == 1) {
                    this.setState({ 
                        programs: response.data.data,
                        last_page: response.data.last_page,
                        loading: false
                    })
                } else {
                    console.log('timpa')
                    this.setState({ 
                        programs: response.data.data,
                        last_page: response.data.last_page,
                        page: 1,
                        loading: false
                    })  
                    console.log(response.data.data)
                }
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) ) /(1000 * 60 * 60 * 24))
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret', 
                'April', 'Mei', 'Juni', 'Juli', 
                'Agustus', 'September', 'Oktober', 
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", " 
                                + new_date.getDate() + " "
                                + months[new_date.getMonth()] + " "
                                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    render() {
        const {
            programs, loading,
            go_home, go_zakat, go_detail, kategori, seo_key,
            go_calculator
        } = this.state

        if (go_home)
            return <Redirect exact to='/home'/>
        else if (go_zakat)
            return <Redirect exact to='/bayar-zakat/form-zakat'/>
        else if (go_calculator)
            return <Redirect exact to='/bayar-zakat/kalkulator'/>
        else if (go_detail)
            return <Redirect exact to={`/programs/`+kategori+`/`+seo_key} />

        return(
        <div>
            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.setState({ go_home: true })}>
                        </i>
                        Zakat
                    </nav>
                </header>
            </div>

            <CustomBreadcrumbs link={this.props.location.pathname} />

            <section style={{backgroundColor:'#f5f5f5'}}>
                <div className="ayo-zakat">
                    <center><h1 className="zakat-title" style={{marginTop:'0px'}}>Bayar Zakat sekarang dengan Mizan Amanah</h1></center>
                
                    <p className="txt-zakat">
                        Saatnya Bayar Zakat. Bersihkan harta anda dengan zakat di Mizan Amanah. Insha Allah Mudah, berkah dan amanah.
                    </p>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <button style={{width: '49%', backgroundColor: '#e91d25'}} onClick={() => this.setState({go_zakat: true})}>
                            Tunaikan Zakat
                        </button>

                        <button style={{width: '49%', backgroundColor: '#e91d25'}} onClick={() => this.setState({go_calculator: true})}>
                            Kalkulator Zakat
                        </button>
                    </div>
                </div>

                {/* PROGRAM ZAKAT */}
                <div className="scroller-program" id="programs_scroller" style={{marginTop:'0px' , paddingBottom : '100px'}}>
                    {programs.map((program, i) => {
                        let image_link = Constant.GET_PROGRAMS_IMAGE + program.id_program

                        let target = program.program.dana_target
                        let collected = (program.program.donatur[0]) ? program.program.donatur[0].total : 0

                        let percentage = 0

                        if(program.program.dana_target != null || program.program.dana_target >= 0){
                            percentage = (collected / target) * 100
                        }else{
                            percentage = 100
                        }

                        let today = Date.now()
                        let final_day = new Date(program.program.tanggal)
                        let day_remaining = this.dateDiff(today, final_day)

                        if (!program.program.tanggal) {
                            final_day = null
                            day_remaining = "∞"
                        }
                        
                        return (
                            <div className="card-program" key={i}>
                                <div onClick={() => this.setState({ go_detail: true, seo_key: program.seo, kategori: program.program.kategori.seo})}>
                                <div className="img-program" style={{backgroundImage: `url(${image_link})`}}></div>
                                <div className="desc-program">
                                    <p className="title-card-program">{program.judul}</p>
                                    <p className="desc-card-program">{program.resume}</p>
                                </div>
                                <div className="progres-program"><progress id="file" value={percentage} max="100"> {percentage}% </progress></div>
                                <div className="terkumpul">
                                    <p>Terkumpul</p>
                                    <p>Rp. {this.numberFormat(collected)} dari Rp. {this.numberFormat(target)}</p>
                                </div>
                                <div className="sisahari">
                                    <p>Sisa Hari</p>
                                    <p>{day_remaining}</p>
                                </div>
                                <div className="garis-card"></div>
                                <div className="tanggal-card">
                                    <i id="ic-kalendar" className="fa fa-calendar"></i>
                                    <p id="tanggal">
                                        {this.dateFormat(final_day)}
                                    </p>
                                </div>
                                <div className="kategori-card">{program.program.kategori.category}</div>
                                </div>
                                <div className="tanggal-card" style={{width: 100 +'%'}} onClick={() => this.setState({ go_donate: true, seo_key: program.seo, kategori: program.program.kategori.seo })}>
                                    <center>
                                        <p className="txt-btn-donasi">Donasi Sekarang</p>
                                    </center>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="sweet-loading" style={{height: 50+'px'}}>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={"#e91d24"}
                        loading={loading}
                    />
                </div>
            </section>
        </div>
        )
    }
}

export default ZakatNew