// Import React and Component
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Import CSS from App.css
// import './App.css'
// Import the Home component to be used below
import Home from "./Home/Home";

import Program from "./Program/Program";
import DetailProgram from "./Program/Detail/DetailProgram";
import Donate from "./Program/Donate/Donate";
import InfaqSedekah from "./Program/InfaqSedekah";

// import Zakat from './Zakat/Zakat'
import HitungZakat from "./Zakat/Hitung/HitungZakat";
import TunaikanZakat from "./Zakat/Tunaikan/TunaikanZakat";

import Zakat from "./ZakatNew/ZakatNew";
import ZakatPenghasilan from "./ZakatNew/ZakatPenghasilan/ZakatPenghasilan";
import ZakatPenghasilanHitung from "./ZakatNew/ZakatPenghasilan/ZakatPenghasilanHitung";
import ZakatFitrah from "./ZakatNew/ZakatFitrah/ZakatFitrah";
import ZakatFitrahHitung from "./ZakatNew/ZakatFitrah/ZakatFitrahHitung";
import ZakatEmas from "./ZakatNew/ZakatEmas/ZakatEmas";
import ZakatEmasHitung from "./ZakatNew/ZakatEmas/ZakatEmasHitung";
import ZakatPerak from "./ZakatNew/ZakatPerak/ZakatPerak";
import ZakatPerakHitung from "./ZakatNew/ZakatPerak/ZakatPerakHitung";

import Qurban from "./Qurban/Qurban";
import Aqiqah from "./Aqiqah/Aqiqah";

import RiwayatDonasi from "./Riwayat/RiwayatDonasi";
import RiwayatZakat from "./Riwayat/RiwayatZakat";
import RiwayatQurban from "./Riwayat/RiwayatQurban";

import Login from "./Login/Login";
import Logout from "./Login/Logout";
import Daftar from "./Login/Daftar";
import LupaPassword from "./Login/LupaPassword";

import AfterPay from "./Notification/AfterPay/AfterPay";

import Update from "./Update/Update";
import DetailUpdate from "./Update/Detail/DetailUpdate";

import ProfileMizan from "./ProfileMizan/ProfileMizan";

import Profile from "./Profile/Profile";
import GantiPassword from "./Profile/GantiPassword";

import Notification from "./Notification/Notification/Notification";

import PaymentMethod from "./Xendit/PaymentMethod";

import RekeningDonasi from "./RekeningDonasi/RekeningDonasi";
import KantorCabang from "./KantorCabang/KantorCabang";
import SyaratDanKetentuan from "./SyaratDanKetentuan/SyaratDanKetentuan";
import KetentuanPrivasi from "./KetentuanPrivasi/KetentuanPrivasi";
import LaporanAudit from "./LaporanAudit/LaporanAudit";

import GalangDana from "./GalangDana/GalangDana";
import GalangDanaWarning from "./GalangDana/GalangDanaWarning";
import WaitGalangDana from "./GalangDana/WaitGalangDana";
import GalangDanaDetail from "./GalangDana/Detail/DetailGalangDana";
import GalangDanaCreate from "./GalangDana/Create/GalangDanaCreate";

import Form from "./Messaging/Firebase/Form/Form";
import FirebaseChatting from "./Messaging/Firebase/Chatting/Chatting";

import ListCustomerService from "./ListCustomerService/ListCustomerService";

class App extends Component {
  render() {
    return (
      
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />

            <Route
              exact
              path="/programs/:kategori/:seo/donate"
              component={Donate}
            />
            <Route
              exact
              path="/programs/:kategori/:seo/donate/:agency"
              component={Donate}
            />
            <Route
              exact
              path="/programs/:kategori/:seo/:agency"
              component={DetailProgram}
            />
            <Route
              exact
              path="/programs/:kategori/:seo"
              component={DetailProgram}
            />
            <Route exact path="/programs/:search" component={Program} />
            <Route exact path="/programs" component={Program} />
            <Route exact path="/programs/pencarian" component={Program} />
            <Route
              exact
              path="/detailprogram/:seoKey/donateform"
              component={Donate}
            />
            <Route
              exact
              path="/detailprogram/:seoKey/donateform/:agency"
              component={Donate}
            />
            <Route
              exact
              path="/detailprogram/:seoKey"
              component={DetailProgram}
            />
            <Route
              exact
              path="/detailprogram/:seoKey/:agency"
              component={DetailProgram}
            />
            {/* <Route exact path="/donateform/:seoKey" component={Donate} />
  <Route exact path="/donateform/:seoKey/:agency" component={Donate} /> */}
            <Route exact path="/infaq-sedekah" component={InfaqSedekah} />

            <Route exact path="/bayar-zakat" component={Zakat} />

            <Route
              exact
              path="/bayar-zakat/zakat-penghasilan"
              component={ZakatPenghasilan}
            />
            <Route
              exact
              path="/bayar-zakat/zakat-penghasilan-hitung"
              component={ZakatPenghasilanHitung}
            />
            <Route
              exact
              path="/bayar-zakat/zakat-fitrah"
              component={ZakatFitrah}
            />
            <Route
              exact
              path="/bayar-zakat/zakat-fitrah-hitung"
              component={ZakatFitrahHitung}
            />
            <Route exact path="/bayar-zakat/zakat-emas" component={ZakatEmas} />
            <Route
              exact
              path="/bayar-zakat/zakat-emas-hitung"
              component={ZakatEmasHitung}
            />
            <Route
              exact
              path="/bayar-zakat/zakat-perak"
              component={ZakatPerak}
            />
            <Route
              exact
              path="/bayar-zakat/zakat-perak-hitung"
              component={ZakatPerakHitung}
            />

            <Route
              exact
              path="/bayar-zakat/kalkulator"
              component={HitungZakat}
            />
            <Route
              exact
              path="/bayar-zakat/form-zakat"
              component={TunaikanZakat}
            />
            <Route
              exact
              path="/bayar-zakat/form-zakat/:agency"
              component={TunaikanZakat}
            />

            <Route exact path="/form-qurban" component={Qurban} />
            <Route exact path="/aqiqah" component={Aqiqah} />

            <Route exact path="/riwayat_donasi" component={RiwayatDonasi} />
            <Route exact path="/riwayat_zakat" component={RiwayatZakat} />
            <Route exact path="/riwayat_qurban" component={RiwayatQurban} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/daftar" component={Daftar} />
            <Route exact path="/lupa_password" component={LupaPassword} />

            <Route exact path="/notif/notif-wait" component={AfterPay} />

            <Route exact path="/update" component={Update} />
            <Route exact path="/update/pencarian" component={Update} />
            <Route exact path="/update/:seoKey" component={DetailUpdate} />

            <Route
              exact
              path="/kisah-sukses/:seoKey"
              component={DetailUpdate}
            />

            <Route exact path="/tentang" component={ProfileMizan} />

            <Route exact path="/profile" component={Profile} />
            <Route
              exact
              path="/profile/ganti_password"
              component={GantiPassword}
            />

            <Route exact path="/notification" component={Notification} />

            <Route exact path="/payment_method" component={PaymentMethod} />

            <Route exact path="/rekening-donasi" component={RekeningDonasi} />
            <Route exact path="/kantor-cabang" component={KantorCabang} />
            <Route
              exact
              path="/menu-syarat-dan-ketentuan"
              component={SyaratDanKetentuan}
            />
            <Route
              exact
              path="/ketentuan-privasi"
              component={KetentuanPrivasi}
            />
            <Route exact path="/laporan-audit" component={LaporanAudit} />

            <Route exact path="/galangdana" component={GalangDana} />
            <Route
              exact
              path="/galangdana/warning"
              component={GalangDanaWarning}
            />
            <Route exact path="/galang_dana/post" component={WaitGalangDana} />
            <Route
              exact
              path="/detaildalangdana/:seoKey"
              component={GalangDanaDetail}
            />
            <Route
              exact
              path="/galang_dana_create"
              component={GalangDanaCreate}
            />

            <Route exact path="/firebase/messaging/form" component={Form} />
            <Route
              exact
              path="/firebase/messaging/chatting"
              component={FirebaseChatting}
            />

            <Route
              exact
              path="/list_customer_service"
              component={ListCustomerService}
            />
          </Switch>
        </Router>
    );
  }
}

export default App;
