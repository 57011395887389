import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../../../Constant'
import { database } from '../../../push-notification'
// import {database} from '../../../firebase'
import axios from 'axios'
import Cookies from 'universal-cookie'
import swal from 'sweetalert'
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import SimpleDateTime  from 'react-simple-timestamp-to-date';

// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

const cookies = new Cookies();

class Form extends Component {
    constructor() {
        super()
        this.state = {
            room: '',
            message: '',
            chats: [],
            countChat: 0,

            userId: 0,
            email: '',
            username: '',

            go_home: false,
            go_form: false,
            loading: true
        }
    }

    componentDidMount() {
        this.getChat()
        setInterval( () => {
            this.checkMessage()
        }, 5000)
        let userId = 0
        if(cookies.get('user_id')){
            this.setState({
                userId: cookies.get('user_id'),
                email: cookies.get('email'),
                username: cookies.get('username')
            })
        }else{
            let email = this.props.location.state.room.replace(/-dot-/g, ".")
            console.log(email, 'email is here')
            axios.get(Constant.GET_PROFILE_GUEST + email)
            .then(res => {
                console.log(res.data.data)
                let nama = ''
                if(res.data.data.nama_lengkap){
                    nama = res.data.data.nama_lengkap
                }else{
                    nama = res.data.data.name
                }
                // console.log(res.data.data.name)
                this.setState({
                    userId: res.data.data.id,
                    email: res.data.data.email,
                    username: nama
                })
            })
            .catch(err => {
                console.log(err)
            })
        }

        // setInterval(() => {
        //     console.log(this.state.userId)
        // }, 2000)
    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }

    checkMessage(){
        let roomName = this.props.location.state.room
        database.ref(roomName).once('value', (snapshot) => {
            let chats = [];
            snapshot.forEach((snap) => {
                chats.push(snap.val())
            });
            if(chats.length != this.state.countChat){
                this.getChat()
            }
        })
    }
    getChat(){
        if(this.props.location.state){
            let roomName = this.props.location.state.room
            window.scrollTo(0,document.body.scrollHeight);
            database.ref(roomName).once('value', (snapshot) => {
                let chats = [];
                snapshot.forEach((snap) => {
                    chats.push(snap.val())
                });
                // console.log(chats)
                this.setState({
                    chats: chats,
                    countChat: chats.length,
                    loading: false
                })
                window.scrollTo(0,document.body.scrollHeight);
            })
        }else{
            this.setState({
                go_form: true
            })
        }
    }
    async sendMessage(){
        const { 
            message, userId, email, username
        } = this.state

        if(message == ""){
            swal('Pesan Tidak Boleh Kosong')
        }else{
            let room = this.props.location.state.room
            let name = this.props.location.state.name

            var timeStamp = Date.now();
            // var hours = new Date().getHours();
            // var hours = (hours + 24-2)%24;
            // var mid = 'AM';
            // if (hours == 0) { //At 00 hours we need to show 12 AM
            //     hours = 12;
            // } else if(hours>12) {
            //     hours = hours%12;
            //     mid = 'PM';
            // }
            // var today = new Date(), currentTime = today.getHours() + ':' + today.getMinutes() + ' ' + mid;
            // var today = new Date(), createdAt = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() +' '+ today.getHours()+':'+ today.getMinutes()+':'+ today.getSeconds();

            
            var date_format = '12'; /* FORMAT CAN BE 12 hour (12) OR 24 hour (24)*/
 
            var d       = new Date();
            var hour    = d.getHours();  /* Returns the hour (from 0-23) */
            var minutes     = d.getMinutes();  /* Returns the minutes (from 0-59) */
            var result  = hour;
            var ext     = '';
            
            if(date_format == '12'){
                if(hour > 12){
                    ext = 'PM';
                    hour = (hour - 12);
                    result = hour;

                    if(hour < 10){
                        result = "0" + hour;
                    }else if(hour == 12){
                        hour = "00";
                        ext = 'AM';
                    }
                }
                else if(hour < 12){
                    result = ((hour < 10) ? "0" + hour : hour);
                    ext = 'AM';
                }else if(hour == 12){
                    ext = 'PM';
                }
            }
            
            if(minutes < 10){
                minutes = "0" + minutes; 
            }

            result = (result == '00') ? '12' + ":" + minutes + ' ' + ext : result + ":" + minutes + ' ' + ext; 

            console.log(result);

            let newUserId = ''
            if(cookies.get('user_id')){
                newUserId = cookies.get('user_id')
            }else{
                newUserId = 'G'+userId
            }

            await database.ref(room).push({
                "formattedTime": result,
                "message": message,
                "name": name,
                "timestamp": timeStamp,
                "userId": newUserId
            });

            var postId = await database.ref(room).push().key

            let value = {
                "from" : userId,
                "to" : room,
                "message_id" : postId,
                "message" : message,
                "name" : name,
                "email" : email,
                "fcmToken" : cookies.get('fcmToken')
            }

            // console.log(value)

            axios.post(Constant.BASE_URL+"/api/chat/save", value )
                .then(response => {
                    console.log("Berhasil", response)
                }).catch(error => {
                    console.log(error)
                })
            
            this.setState({ message: '' });
            this.getChat()
        }
    }

    convertStampDate(unixtimestamp){

        // Unixtimestamp
        
        // Months array
        var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        
        // Convert timestamp to milliseconds
        var date = new Date(unixtimestamp*1000);
        
        // Year
        var year = date.getFullYear();
        
        // Month
        var month = months_arr[date.getMonth()];
        
        // Day
        var day = date.getDate();
        
        // Hours
        var hours = date.getHours();
        
        // Minutes
        var minutes = "0" + date.getMinutes();
        
        // Seconds
        var seconds = "0" + date.getSeconds();
        
        // Display date time in MM-dd-yyyy h:m:s format
        var fulldate = month+' '+day+'-'+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        
        // filtered fate
        var convdataTime = month+' '+day;
        return fulldate;
    }

    timeAgo(v){
        const diff = Number(new Date()) - v;
        const minute = 60 * 1000;
        const hour = minute * 60;
        const day = hour * 24;
        const month = day * 30;
        const year = day * 365;
        switch (true) {
            case diff < minute:
                const seconds = Math.round(diff / 1000);
                 return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
            case diff < hour:
                return Math.round(diff / minute) + ' minutes ago';
            case diff < day:
                return Math.round(diff / hour) + ' hours ago';
            case diff < month:
                return Math.round(diff / day) + ' days ago';
            case diff < year:
                return Math.round(diff / month) + ' months ago';
            case diff > year:
                return Math.round(diff / year) + ' years ago';
            default:
                return "";
        }
    }

    endChat(){
        axios.get(Constant.BASE_URL+"/api/chat/solve/"+localStorage.getItem('lc_email'))
        .then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })

        localStorage.clear()

        window.location.href = '/'
    }

    render() {
        const { match } = this.props
        const { 
            chats, userId,
            message, go_home, go_form
        } = this.state

        let tanggal = []

        // let today - new Date(Date.now()).toLocaleDateString("en-US")
        // console.log('today is here', today)

        if (go_home)
            return <Redirect exact to="/home" />
        else if (go_form)
            return <Redirect exact to="/firebase/messaging/form" />
        
        return (
        <section id="program-list-box" >
            <header id="header" className="header-program">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    Live Messaging
				</nav>

                <a 
                    className="ic-filter"
                    onClick={() => this.endChat()}
                >
                    Akhiri Chat
                </a>
            </header>
            
            <div className="scroller-program scroller-chat" id="programs_scroller">
                {chats.map((chat, i) => {
                    if(i<1){
                        tanggal.push(new Date(chat.timestamp).toLocaleDateString("en-US"))
                    }else{
                        if(new Date(chats[i].timestamp).toLocaleDateString("en-US") != new Date(chats[i-1].timestamp).toLocaleDateString("en-US")){
                            tanggal.push(new Date(chat.timestamp).toLocaleDateString("en-US"))
                        }
                    }
                })}     

                {tanggal.map((tgl, t) => {
                    return(
                        <>
                            <div className="container-pemisah">
                                <div className="pemisah-tanggal">{(tgl == new Date(Date.now()).toLocaleDateString("en-US")) ? 'Today' : tgl}</div>
                            </div>

                            {chats.map((chat, i) => {
                                if(tgl == new Date(chat.timestamp).toLocaleDateString("en-US")){
                                    if(chat.userId == userId || chat.userId == 'G'+userId){
                                        return (
                                            <div className="bubble-box-user" key={i}>
                                                <div className="bubble-chat-user">
                                                    <p className="chat-name">{chat.name}</p>
                                                    {chat.message}
                                                    <div className="time-chat">({(chat.formattedTime) ? chat.formattedTime : chat.formatedTime})</div>
                                                </div>
                                            </div>
                                        )   
                                    }else{
                                        return (
                                            <div className="bubble-box-mizan" key={i}>
                                                <div className="bubble-chat-mizan" >
                                                    <p className="chat-name">{chat.name}</p>
                                                    {chat.message}
                                                    <div className="time-chat">({(chat.formattedTime) ? chat.formattedTime : chat.formatedTime})</div>
                                                </div>   
                                            </div>
                                        )   
                                    }
                                }
                            })}
                        </>
                    )
                })}           
            </div>
            <div className="send-chat">
                <input 
                    type="text" 
                    name="message" 
                    id="message" 
                    className="input-form"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => this.handleInputChange(e)}
                />

                <br/>

                <a 
                    className="btn-send" 
                    style={{marignLeft: "10px"}}
                    onClick={() => this.sendMessage()}
                >
                    {/* image na aya di images/a.png */}
                    <img src="/images/a.png" className="ic-send" style={{padding: '7px'}}/>
                </a>
            </div>
            <div className="sweet-loading" style={{height: 50+'px'}}>
                <ClipLoader
                    css={override}
                    size={40}
                    color={"#e91d24"}
                    loading={this.state.loading}
                />
            </div>
        </section>
        )
    }
}

export default Form