import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie'
import swal from '../../../node_modules/sweetalert'
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';

const cookies = new Cookies();

class ZakatPenghasilan extends Component {
    constructor() {
        super()
        this.state = {
            go_back: false,
            go_zakat: false,
            go_count: false,
            go_zakat_from_count: false,
            zakat: 0,
            penghasilanPerbulan: 0,
            bonus: 0,
            hargaBeras: 0,
            total: 0
        }
    }

    componentDidMount() {
        
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    onAmountChanged(e) {
        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })
    }
    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }
    btnHandlerChange(v){
        console.log(v)
        if(v == 'hitung'){
            this.setState({go_count:true})
        }
    }

    validateZakat(){
        const {
            zakat
        } = this.state

        if(zakat <= 0){
            swal('Minimal zakat adalah Rp.1')
            // swal("Here's the title!", "...and here's the text!")
        }else{
            this.setState({go_zakat: true})
        }
    }
    validateZakatCounted(){
        const {
            zakat
        } = this.state

        if(zakat <= 0){
            swal('Minimal zakat adalah Rp.1')
        }else{
            this.setState({go_zakat_from_count: true})
        }
    }
    countZakat(){
        const {
            penghasilanPerbulan, bonus, hargaBeras, total
        } = this.state

        if(penghasilanPerbulan <= 0){
            swal("Penghasilan Bulanan Harus Lebih Dari Rp.0")
        }else if(bonus < 0){
            swal("Bonus Tidak Bisa Kurang Dari Rp.0")
        }else if(hargaBeras <= 0){
            swal("Harga Beras Harus Lebih Dari Rp.0")
        }else{
            let qtyBeras = 0
            axios.get(Constant.GET_PARAMETER+'qty_beras')
            .then(res => {
                console.log(res.data.data.value)
                qtyBeras = res.data.data.value
            }).catch(err => {
                console.log(err)
            })
            let totalPenghasilan = penghasilanPerbulan+bonus
            let nishab = hargaBeras*qtyBeras
            let jumlah = totalPenghasilan * 0.025
            console.log(totalPenghasilan, nishab, jumlah)
            this.setState({total: jumlah})

            if (totalPenghasilan > nishab) {
                document.getElementById('btn_from_hitung').hidden = false
            } else {
                swal("Belum wajib zakat")
            }
        }
    }

    render() {
        const {
            go_back, go_zakat, go_count, zakat, penghasilanPerbulan, bonus, hargaBeras, total
        } = this.state

        if (go_back)
            return <Redirect exact to="/bayar-zakat" />
        else if(go_zakat)
            return <Redirect exact to={{
                pathname: "/bayar-zakat/form-zakat",
                state: { 
                    kategori: "Zakat Penghasilan",
                    total_zakat: zakat
                }
            }} />
        else if(go_count)
            return <Redirect exact to="/bayar-zakat/zakat-penghasilan-hitung" />

        return(
        <div>

            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.setState({ go_back: true })}>
                        </i>
                        Zakat Penghasilan
                    </nav>
                </header>
            </div>

            <CustomBreadcrumbs link={this.props.location.pathname} />

            <section id="donasi-box">
                <center>
                    <p className="zakat-title" style={{marginTop: '0px', maxWidth: 'fit-content'}}>
                        Bayar Zakat Penghasilan
                    </p>
                </center>

                <div className="box-kategori-zakat-detail">
                    <div className="item-kategori-zakat-detail-active" onClick={() => this.btnHandlerChange('bayar')}>
                            <p className="title-kategori-zakat-active">Bayar</p>
                    </div>
                    <div className="item-kategori-zakat-detail" onClick={() => this.btnHandlerChange('hitung')}>
                            <p className="title-kategori-zakat">Hitung</p>
                    </div>
                </div>

                <hr style={{marginTop: '10px'}}/>

                <div className="box-kategori-zakat-detail" id="bayar">
                    <p className="label-form">Jumlah Yang Akan di Bayarkan : </p>
                    <input 
                        type="text" 
                        name="zakat" 
                        id="zakat" 
                        className="input-form" 
                        value={this.numberFormat((zakat)?zakat:0)} 
                        onChange={(e) => this.onAmountChanged(e)}
                    />
                    <a
                        className="btn-dns" 
                        style={{padding: "10px", marginTop: '10px'}}
                        onClick={() => {this.validateZakat()}}
                    >
                        <p>Lanjutkan Pembayaran</p>
                    </a>
                </div>

            </section>
        </div>
        )
    }
}

export default ZakatPenghasilan