import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'

class WaitGalangDana extends Component {
    constructor() {
        super()
        this.state = {
            go_home: true,
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
    }

    render() {
        const { go_home } = this.state

        if (go_home)
            return <Redirect exact to='/home' />

        return(
        <div>         

        </div>
        )
    }
}

export default WaitGalangDana