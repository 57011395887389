import React, { Component } from 'react'
import {
  Redirect,
  Link
} from "react-router-dom"
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

class CustomBreadcrumbs extends Component {
    constructor() {
        super()
        this.state = {
			links: [],
        }
    }

    componentDidMount() {
		this.generateBreadcrumbsLink()
    }

	generateBreadcrumbsLink() {
		var splitted_link = this.props.link.split('/')
		this.setState({
			links: splitted_link
		})
	}

	redirectTo(link) {
		return <Redirect exact to={link} />
	}

	render() {
		const { links } = this.state
		const custClassName = this.props.className
		var linkTo = ""

		return(
		<div id="breadcrumbs" className={custClassName}>
			<Breadcrumbs maxItems={2} separator="›" aria-label="breadcrumb">
				{links.map((link, i) => {
					// Home Page
					if (i == 0 && this.props.link == "/") {
						return (
							<Typography>
								<p class="active">
									{link == "" ? "Home" : link}
								</p>
							</Typography>
						)
					}

					// Handle for Home Page
					if (i != 0 && link == "")
						return(null)

					if (i == 0) {
						linkTo += "/"
						return (
							<Link to={linkTo}>
								<p onClick={this.redirectTo(linkTo)}>Home</p>
							</Link>
						)
					} else if (i < links.length-1) {
						linkTo += link + "/"
						return (
							<Link to={linkTo}>
								<p onClick={this.redirectTo(linkTo)}>{link}</p>
							</Link>
						)
					} else {
						return (
							<Typography>
								<p class="active">
									{link == "" ? "Home" : link}
								</p>
							</Typography>
						)
					}
				})}
				
			</Breadcrumbs>
		</div>
		)
	}
}

export default CustomBreadcrumbs