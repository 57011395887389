import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'

class GalangDanaWarning extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
            go_login: false,
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
    }

    render() {
        const { go_home, go_login } = this.state

        if (go_home)
            return <Redirect exact to='/home' />
        else if (go_login)
            return <Redirect exact to='/login' />

        return(
        <div>         
            <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    Galang Dana
                </nav>
            </header>

            <section id="detprogram-box">
                <center><p className="zakat-title" style={{marginTop: 0+'px'}}>Maaf, untuk galang dana anda diharuskan untuk login</p></center>

                <br />
                <button 
                    className="btn-dns" 
                    onClick={() => this.setState({go_home: true})}
                >
                    Kembali ke Home
                </button>

                <br />
                <br />

                <button 
                    className="btn-dns" 
                    onClick={() => this.setState({go_login: true})}
                >
                    Login
                </button>
            </section>
        </div>
        )
    }
}

export default GalangDanaWarning