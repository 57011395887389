import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../Constant'
import axios from 'axios'
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

class Program extends Component {
    constructor() {
        super()
        this.state = {
            categories: [],
            programs: [],
            page: 1,
            last_page: 1,
            search: null,
            category_id: null,
            category_id_before: null,
            go_home: false,
            go_detail: false,
            seo_key: '',
            loading: true,
            kategori: '',

            go_donate: false
        }

        this.timer = null
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        let currentUrl = window.location.href
        let breakUrl = currentUrl.split('/')
        // console.log('here', breakUrl)
        if(breakUrl.length == 5){
            this.setQueryUrl()
        }
        if(breakUrl.length == 4){
            this.setQueryUrl4()
        }
        
        this.getCategories()
        this.timer = setTimeout(() => this.checkCategoryFromProps(), 250)

        document.addEventListener('scroll', this.trackScrolling)

        // Modal Filter Close
        window.onclick = function (event) {
            if (event.target == document.getElementById("modal_filter")) {
                document.getElementById("modal_filter").style.display = "none";
            }
        }

        if(this.props.location.search != null){
            this.setValueFromQueryUrl()
        }
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }

    setQueryUrl(){
        let currentUrl = window.location.href
        let breakUrl = currentUrl.split('/')
        let queryUrl = breakUrl[4]
        let pecahUrl = queryUrl.split('?')
        let kategory = pecahUrl[0].replace(/\-/g,' ')
        let checker = queryUrl.split('=')
        // console.log(checker, checker.length)
        if(checker.length <= 2){
            if(pecahUrl[1]){
                let keySearch = pecahUrl[1].replace('search=', '')
                // console.log('keySearch', keySearch);

                this.setState({
                    search: keySearch
                })
            }

            axios.get(Constant.GET_CATEGORY + kategory)
            .then(res => {
                this.setState({
                    category_id: res.data.id
                })

                // setTimeout(() => 
                    this.getPrograms()
                // , 1000)
            }).catch(err => {
                // console.log(err)
            })
        }
    }

    setQueryUrl4(){
        let currentUrl = window.location.href
        let breakUrl = currentUrl.split('/')
        let queryUrl = breakUrl[3]
        let pecahUrl = queryUrl.split('?')
        if(pecahUrl[1]){
            let keySearch = pecahUrl[1].replace('search=', '')

            this.setState({
                search: keySearch
            })
        }
        
        this.getPrograms()
    }

    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= (docHeight * 0.8) && 
            this.state.page != this.state.last_page) 
        {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({ 
                page: next,
                loading: true 
            })
            this.getPrograms()
            // console.log('there it is')
        }
    }
    handleInputChange(e) {
        clearTimeout(this.timer)

        const { name, value } = e.target
        this.setState({ [name]: value, page: 1})

        this.timer = setTimeout(() => this.getPrograms(), 1000)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)
            
            this.getPrograms()
        }
    }
    categorySelected(category_id) {
        this.setState({ category_id, page: 1 })
        
        this.timer = setTimeout(() => this.getPrograms(), 250)
        
        document.getElementById("modal_filter").style.display = "none";
    }
    setValueFromQueryUrl(){
        const { page, search, category_id } = this.state

        let url = this.props.location.search
        let split_url = String(url).substring(1).split('&')

        // console.log(split_url)
        split_url.map((query) => {
            let pisah = query.split('=')
            if(pisah[0] == "page"){
                this.setState({page: pisah[1]})
            }else if(pisah[0] == "search_query_first"){
                this.setState({search: pisah[1]})
            }else if(pisah[0] == "search_query_last"){
                this.setState({category_id: pisah[1]})
            }
        })
    }

    getCategories() {
        // get categories
        axios.get(Constant.GET_CATEGORIES)
            .then(response => {
                // console.log(response.data)

                // seo category from url
                let currentUrl = window.location.href
                let breakUrl = currentUrl.split('/')
                var category_id = null
                if (breakUrl.length == 5 || (breakUrl.length == 6 && breakUrl[5] == "")) {
                    response.data.data.forEach(val => {
                        if (val.seo == breakUrl[4])
                            category_id = val.id
                    });
                }

                if (category_id == null)
                    this.setState({ 
                        categories: response.data.data 
                    });
                else
                    this.setState({ 
                        categories: response.data.data,
                        category_id: category_id
                    });
            })
            // Catch any error here
            .catch(error => {
                // console.log(error)
            })
    }
    getPrograms() {
        const { page, search, categories, category_id, category_id_before } = this.state
        let halaman = 1;
        if(category_id != category_id_before){
            this.setState({
                page: 1
            })
            halaman = 1
        }else{
            halaman = page
        }

        let query_url = `?page=${halaman}`
        if (search)
            query_url += `&keyword=${search}`
        if (category_id)
            query_url += `&idKategori=${category_id}`
        
        // this.setState({
        //     category_id_before: category_id
        // })

        axios.get(Constant.GET_PROGRAMS + query_url)
            .then(response => {
                // console.log(response.data)
                if (page == 1) {
                    this.setState({ 
                        programs: response.data.data,
                        last_page: response.data.last_page,
                        loading: false,
                        category_id_before: category_id
                    })
                } else {
                    if(category_id != category_id_before){
                        // console.log('timpa')
                        this.setState({ 
                            programs: response.data.data,
                            last_page: response.data.last_page,
                            page: 1,
                            loading: false,
                            category_id_before: category_id
                        })  
                        // console.log(response.data.data)
                    }else{
                        // console.log('tambah')
                        let newPrograms = this.state.programs.concat(response.data.data)
                        this.setState({ 
                            programs: newPrograms,
                            last_page: response.data.last_page,
                            loading: false,
                            category_id_before: category_id
                        })
                        // console.log(response.data.data)
                    }
                }
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }

    checkCategoryFromProps() {
        let props_category = (this.props.location.state)
                                ? this.props.location.state.category_id
                                : null
        // console.log("AWEUW ~ " + props_category)
        if (props_category) this.setState({ category_id: props_category })

        this.timer = setTimeout(() => this.getPrograms(), 250)
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateDiff(startDate, endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) ) /(1000 * 60 * 60 * 24))
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret', 
                'April', 'Mei', 'Juni', 'Juli', 
                'Agustus', 'September', 'Oktober', 
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", " 
                                + new_date.getDate() + " "
                                + months[new_date.getMonth()] + " "
                                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    render() {
        const { match } = this.props
        const { 
            categories, search, category_id, kategori,
            go_home, go_detail, seo_key, go_donate
        } = this.state

        if (go_home)
            return <Redirect exact to="/home" />
        else if (go_detail)
            return <Redirect exact to={`/programs/`+kategori+`/`+seo_key} />
        else if (go_donate)
            return <Redirect exact to={`/programs/`+kategori+`/`+seo_key+`/donate`} />

        
        // else if (go_detail)
        //     return <Redirect exact to={`/detailprogram/${seo_key}`} />
        // else if (go_donate)
        //     // return <Redirect exact to={`/donateform/${seo_key}`} />
        //     return <Redirect exact to={`/detailprogram/${seo_key}`+'/donateform'} />

        return (
        <section id="program-list-box">

            <div>
                <header id="header" className="header-program">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.setState({ go_home: true })}>
                        </i>
                        Program
                    </nav>

                    <a 
                        className="ic-filter"
                        onClick={() => document.getElementById("modal_filter").style.display = "block"}
                    >
                        <i id="ic-sidebar" className="fa fa-filter"></i>
                    </a>

                    <a className="ic-search">
                        <i id="ic-sidebar" className="fa fa-search"></i>
                    </a>
                    <input type="text" name="search" id="search" className="form-search" placeholder="  Cari Program" value={search?search:''} onChange={(e) => this.handleInputChange(e)} onKeyDown={(e) => this.handleKeyDown(e)} />

                </header>


            </div>

            <CustomBreadcrumbs style={{width: '400px'}} link={this.props.location.pathname} className="breadcrumbs-program"/>

            {/* Filter */}
            <div id="modal_filter" className="modalfilter">
                <div className="filter-content">
                    <span 
                        className="close"
                        onClick={() => document.getElementById("modal_filter").style.display = "none"}
                    >
                        &times;
                    </span>

                    <p className="head-filter">Kategori</p>
                    <p 
                        className="all-category"
                        onClick={() => this.categorySelected(null)}
                    >
                        Semua Kategori
                    </p>``
                    <div className="scroller-category">
                    {categories.map((category, i) => {
                        return(
                            <p 
                                className="item-category" 
                                value={category.id} 
                                onClick={() => this.categorySelected(category.id)}
                                key={i}
                            >
                                {category.category}
                            </p>
                        )
                    })}
                    </div>
                </div>
            </div>

            <div className="scroller-program" id="programs_scroller">
            {this.state.programs.map((program, i) => {
                let image_link = Constant.GET_PROGRAMS_IMAGE + program.id_program

                let target = program.program.dana_target
                let collected = (program.program.donatur[0]) ? program.program.donatur[0].total : 0

                let percentage = 0

                if(program.program.dana_target != null || program.program.dana_target >= 0){
                    percentage = (collected / target) * 100
                }else{
                    percentage = 100
                }

                let today = Date.now()
                let final_day = new Date(program.program.tanggal)
                let day_remaining = this.dateDiff(today, final_day)

                if (!program.program.tanggal) {
                    final_day = null
                    day_remaining = "∞"
                }
                
                return (
                    <div className="card-program" key={i}>
                        <div onClick={() => this.setState({ go_detail: true, seo_key: program.seo, kategori: program.program.kategori.seo})}>
                        {/* <div onClick={() => this.setState({ go_detail: true, seo_key: program.seo })}> */}
                        <img src={image_link}></img>
                        {/* <div className="img-program" style={{backgroundImage: `url(${image_link})`}}></div> */}
                        <div className="desc-program">
                            <p className="title-card-program">{program.judul}</p>
                            <p className="desc-card-program">{program.resume}</p>
                        </div>
                        <div className="progres-program"><progress id="file" value={percentage} max="100"> {percentage}% </progress></div>
                        <div className="terkumpul">
                            <p>Terkumpul</p>
                            <p>Rp. {this.numberFormat(collected)} dari Rp. {this.numberFormat(target)}</p>
                        </div>
                        <div className="sisahari">
                            <p>Sisa Hari</p>
                            <p>{day_remaining}</p>
                        </div>
                        <div className="garis-card"></div>
                        <div className="tanggal-card">
                            <i id="ic-kalendar" className="fa fa-calendar"></i>
                            <p id="tanggal">
                                {this.dateFormat(final_day)}
                            </p>
                        </div>
                        <div className="kategori-card">{program.program.kategori.category}</div>
                        </div>
                        {/* <div className="tanggal-card" style={{width: 100 +'%'}} onClick={() => this.setState({ go_donate: true, seo_key: program.seo, kategori: program.program.kategori.seo })}> */}
                        <div className="tanggal-card" style={{width: 100 +'%'}} onClick={() => this.setState({ go_donate: true, seo_key: program.seo, kategori: program.program.kategori.seo })}>
                            <center>
                                <p className="txt-btn-donasi">D O N A S I</p>
                            </center>
                        </div>
                    </div>
                )
            })}
            </div>

            {/* <div className="sweet-loading" style={{height: 50+'px'}}> */}
            <div className="sweet-loading">
                <ClipLoader
                    css={override}
                    size={40}
                    color={"#e91d24"}
                    loading={this.state.loading}
                />
            </div>
        </section>
        )
    }
}

export default Program