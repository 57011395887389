import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../Constant'
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie'
import swal from 'sweetalert'

const cookies = new Cookies();

class LupaPassword extends Component {
    constructor() {
        super()
        this.state = {
            email: '',

            go_back: false,
            go_home: false
        }
    }

    componentDidMount() {
        
    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }

    send(){
        const {
            email
        } = this.state

        if(email == null || email == ''){
            swal('Email Tidak Boleh Kosong')
        }else{
            const parameter = {
                "email": email
            }

            axios.post(Constant.FORGOT_PASSWORD, parameter).
            then(response => {
                if(response.data.status == 400){
                    swal(response.data.message)
                }else{
                    swal('Email Terkirim, mohon cek email untuk reset password !')
                    this.setState({go_home: true})
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    render() {
        const {
            email, go_back, go_home
        } = this.state
        
        if (go_back)
            return <Redirect exact to="/login" />
        else if(go_home)
            return <Redirect exact to="/home" />

        return(
        <div>

            <header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_back:true})}>
                    </i>
                    Lupa Password
				</nav>
			</header>
            
			<section id="donasi-box">
                <center><p className="title-donasi">Lupa Password</p></center>
                <br/>

                <form>
                    <input 
                        type="email" 
                        name="email" 
                        id="email" 
                        className="input-form"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <br/><br/>
                    
                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}}
                        onClick={() => this.send()}
                    >
                        <p>CONTINUE</p>
                    </a>
                </form>
                <br/><br/>
            </section>
        </div>
        )
    }
}

export default LupaPassword