import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';

class Aqiqah extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('')`
    }

    render() {
        const { go_home } = this.state

        if (go_home)
            return <Redirect exact to='/home' />

        return(
        <div>
            
            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.setState({ go_home: true })}>
                        </i>
                        Aqiqah Online
                    </nav>
                </header>
            </div>

            <CustomBreadcrumbs link={this.props.location.pathname}/>

            <section id="detprogram-box">
                <img src="/ilustrasi_temanaqiqah.png" style={{marginTop: 50+'px'}}/>
                <center><p className="zakat-title" style={{marginTop: 0+'px'}}>Ayo Tunaikan Aqiqah Sekarang</p></center>

                <br />
                <p className="txt-zakat">
                    Mari tunaikan aqiqah anda bersana Mizan Amanah dan Teman Aqiqah, Insha Allah jadi lebih mudah.
                </p>

                <a 
                    className="btn-dns" 
                    style={{padding: "10px"}} 
                    href="https://order.temanaqiqah.com"
                    target="_BLANK"
                >
                    <p>Tunaikan Aqiqah</p>
                </a>
                <br/>
                <br/>
                
            
                <p className="txt-zakat">
                    Jika anda masih bingung dan ragu tentang tata cara aqiqah konsultasikan saja bersama kami
                </p>

                <a 
                    className="btn-dns" 
                    style={{padding: "10px"}} 
                    href="https://wa.me/6281282032011?text=Assalamualaikum%20Aqiqah%20Mizan%20Amanah,%20saya%20butuh%20informasi"
                    target="_BLANK"
                >
                    <p>Konsultasi Aqiqah</p>
                </a>
            </section>
        </div>
        )
    }
}

export default Aqiqah