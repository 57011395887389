import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../../Constant'
import axios from 'axios'
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from 'universal-cookie';
import Axios from 'axios';
import ImageUploader from 'react-images-upload';
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

const cookies = new Cookies();

class GalangDanaCreate extends Component {
    constructor() {
        super()
        this.state = {
            go_back: false,

            categories: [],

            foto_ktp: '',
            foto_slider: '',
            no_ktp: '',
            pic: '',
            judul: '',
            category_id: 0,
            target: 0,
            jangka_waktu: '',
            resume: '',
            deskripsi: '',

            pictures: []
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none"
        this.getCategory()
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
    }

    getCategory(){
        Axios.get(Constant.GET_CATEGORIES)
        .then(res => {
            this.setState({
                categories: res.data.data
            })
        }).catch(err => {
            console.log(err)
        })
    }
    
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret', 
                'April', 'Mei', 'Juni', 'Juli', 
                'Agustus', 'September', 'Oktober', 
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", " 
                                + new_date.getDate() + " "
                                + months[new_date.getMonth()] + " "
                                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }
    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
        this.sendData()
    }
    onAmountChanged(e) {
        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })
    }
    onDrop(picture) {
        console.log(
            this.state.pictures.concat(picture)
        )
        // this.setState({
        //     pictures: ,
        // });
    }

    sendData(){
        const { 
            foto_ktp, foto_slider, no_ktp, pic, judul, category_id, target, jangka_waktu, resume, deskripsi
        } = this.state

        const parameter = {
            'foto_ktp': foto_ktp,
            'foto_slider': foto_slider,
            'no_ktp': no_ktp,
            'pic': pic,
            'judul': judul,
            'category_id': category_id,
            'target': target,
            'jangka_waktu': jangka_waktu,
            'resume': resume,
            'deskripsi': deskripsi
        }

        console.log(parameter)
    }

    render() {
        const { 
            go_back, categories,
            foto_ktp, foto_slider, no_ktp, pic, judul, category_id, target, jangka_waktu, resume, deskripsi
        } = this.state

        if (go_back)
            return <Redirect exact to='/list_galang_dana' />
            
        return(
            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i id="ic-sidebar" className="fa fa-arrow-left" onClick={() => this.setState({ go_back: true })}></i>
                        Buat Penggalangan Dana
                    </nav>
                </header>

                <section id="riwayat-box">
                    <div className="scroller-program" id="programs_scroller" style={{marginTop: 10+'px'}}>
                        <div className="card-program">
                            {/* <img src="/foto-ktp.png" id="gambar_nodin" height="250" alt="Preview Gambar"></img> */}
                            {/* <input 
                                type="file"
                                name="foto_ktp" 
                                value={foto_ktp}
                                id="foto_ktp" 
                                // value={usernam}
                                onChange={(e) => this.handleInputChange(e)}
                            /> */}
                            <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                onChange={this.onDrop()}
                                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                maxFileSize={5242880}
                            />

                            {/* <img src="/foto-program.png" id="gambar_nodin" height="250" alt="Preview Gambar"></img> */}
                            <input 
                                type="file"
                                name="foto_slider" 
                                value={foto_slider}
                                id="foto_slider" 
                                // value={foto_slide}
                                onChange={(e) => this.handleInputChange(e)}
                            />

                            <input 
                                type="text"
                                name="no_ktp" 
                                value={no_ktp}
                                id="no_ktp" 
                                className="input-form"
                                placeholder="Nomor KTP"
                                // value={no_kt}
                                onChange={(e) => this.handleInputChange(e)}
                            />

                            <input 
                                type="text"
                                name="pic" 
                                value={pic}
                                id="pic" 
                                className="input-form"
                                placeholder="Nama Penanggung Jawab"
                                // value={pi}
                                onChange={(e) => this.handleInputChange(e)}
                            />

                            <input 
                                type="text"
                                name="judul" 
                                value={judul}
                                id="judul" 
                                className="input-form"
                                placeholder="Judul Galang Dana"
                                // value={judu}
                                onChange={(e) => this.handleInputChange(e)}
                            />

                            <select
                                name="category_id" 
                                value={category_id}
                                id="category_id" 
                                className="input-form"
                                placeholder="Judul Galang Dana"
                            >
                                {categories.map((cat, c) => {
                                    return(
                                        <option key={c} value={cat.id}>{cat.category}</option>
                                    )
                                })}
                            </select>

                            <input 
                                type="text" 
                                name="target" 
                                value={target}
                                id="target" 
                                className="input-form" 
                                placeholder="Dana Target"
                                value={this.numberFormat((target)?target:0)}
                                onChange={(e) => this.onAmountChanged(e)}
                            />

                            <input 
                                type="date" 
                                name="jangka_waktu" 
                                id="jangka_waktu" 
                                className="input-form" 
                                onChange={(e) => this.onAmountChanged(e)}
                            />

                            <textarea
                                name="resume" 
                                value={resume}
                                id="resume" 
                                // className="input-form" 
                                placeholder="Resmue"
                            />

                            <textarea
                                name="deskripsi" 
                                value={deskripsi}
                                id="deskripsi" 
                                // className="input-form" 
                                placeholder="Deskripsi"
                            />
                        </div>
                    </div>
                    
                    
                </section>
            </div>
        )
    }
}

export default GalangDanaCreate