import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../../Constant'
import axios from 'axios'

class ZakatFitrah extends Component {
    constructor() {
        super()
        this.state = {
            harga_beras_perkilo: 0,
            total_zakat: 0,

            without_calculator: false,
            niat: false,
            go_zakat: false
        }

        this.timer = null
    }

    componentDidMount() {

    }

    onAmountChanged(e) {
        clearTimeout(this.timer)

        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })

        this.timer = setTimeout(() => {
                        if (name !== "total_zakat")
                            this.calculateZakat()
                    }, 250)
    }
    handleKeyDown(e) {
        if (e.keyCode === 13) {
            clearTimeout(this.timer)

            this.calculateZakat()
        }
    }
    handleCheckBox(e) {
        const { name, checked } = e.target
        this.setState({ [name]: checked })

        if (name === "without_calculator") this.withWithoutCalculator(checked)
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    calculateZakat() {
        const {
            harga_beras_perkilo
        } = this.state
        let total = harga_beras_perkilo * 2.5

        this.setState({ 
            total_zakat: (total > 0) ? total : 0
        })
    }
    withWithoutCalculator(without_calculator) {
        let form = document.getElementById("form_hide")
        let zakat = document.getElementById("total_zakat")

        if (without_calculator) {
            form.style.display = "none"
            zakat.removeAttribute("readonly")
            zakat.classList.remove("readonly")
        } else {
            form.style.display = "block"
            zakat.setAttribute("readonly", "")
            zakat.classList.add("readonly")
        }
    }
    validateZakat() {
        const { niat } = this.state

        if (niat)
            this.setState({ go_zakat: true })
        else
            alert("Harap baca dan centang niat zakat!")
    }

    render() {
        const {
            harga_beras_perkilo, total_zakat, go_zakat
        } = this.state

        if (go_zakat)
            return <Redirect exact to={{
                            pathname: "/zakat/form-zakat",
                            state: { 
                                kategori: "Zakat Fitrah",
                                total_zakat: total_zakat
                            }
                        }}
                    />

        return(
        <section id="fitrah" className="zakat-box">

            <p className="label-form">Perhitungan Zakat Fitrah</p>
            <hr/>
            <input 
                type="checkbox"
                id="check-without_calculator" 
                name="without_calculator"
                onClick={(e) => this.handleCheckBox(e)}
            />
            <label className="label-form" htmlFor="check-without_calculator">
                SAYA PUNYA PERHITUNAN SENDIRI (TANPA KALKULATOR)
            </label>
            <hr/>

            <div id="form_hide">
                <p className="label-form">HARGA BERAS / KG</p>
                <input 
                    type="text" 
                    name="harga_beras_perkilo" 
                    id="harga_beras_perkilo" 
                    className="input-form" 
                    value={this.numberFormat((harga_beras_perkilo)?harga_beras_perkilo:0)}
                    onChange={(e) => this.onAmountChanged(e)}
                    onKeyDown={(e) => this.handleKeyDown(e)}
                />
            </div>

            <p className="label-form">JUMLAH ZAKAT (HARGA BERAS X 2.5 KG)</p>
            <input 
                type="text" 
                name="total_zakat" 
                id="total_zakat" 
                className="input-form readonly" 
                value={this.numberFormat((total_zakat)?total_zakat:0)}
                onChange={(e) => this.onAmountChanged(e)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                readOnly 
            />

            <input 
                type="checkbox"
                id="check-niat" 
                name="niat"
                onClick={(e) => this.handleCheckBox(e)}
            />
            <label className="label-form" htmlFor="check-niat">
                Bismillah. Saya serahkan zakat saya kepada Yayasan Mizan Amanah agar dapat di kelola dengan sebaik baiknya sesuai dengan ketentuan syariat agama.
            </label>
            <br/><br/>

            <a 
                className="btn-dns" 
                style={{padding: "10px"}}
                onClick={() => this.validateZakat()}
            >
                <p>BAYAR</p>
            </a>
            <div className="padding-scroller">
                    
            </div>
        </section>
        )
    }
}

export default ZakatFitrah