import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import * as Constant from "../../Constant";
import axios from "axios";
import Cookies from "universal-cookie";
import swal from "sweetalert";
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

const cookies = new Cookies();

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

class Donate extends Component {
  constructor() {
    super();
    this.state = {
      user_id: null,
      program: {},
      donatian: 0,
      full_name: localStorage.getItem("nama")
        ? localStorage.getItem("nama")
        : "",
      phone_number: localStorage.getItem("phone_number")
        ? localStorage.getItem("phone_number")
        : "",
      email: localStorage.getItem("email") ? localStorage.getItem("email") : "",

      phone: "",
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvn: "",
      alfamart_code: "ALFAMARTCODEHERE!!!",
      payment_method: "",

      go_back: false,
      go_paid: false,

      go_payment: false,
      checkout_url: "",
      go_via_checkout: false,

      mootaMethod: [],

      nominals: [],
      agency: "",

      transaction_id: "",
      kategori: "",

      loading: true,
      loadingDiv: false,
    };
    this.submitDonation = this.submitDonation.bind(this);
  }

  componentDidMount() {
    this.getProgramDetail();
    this.setUserIfLogin();
    this.getNominal();

    if (cookies.get("selected_method")) {
      this.getPaymentMethod();
    } else {
      document.getElementById("logo_payment").hidden = true;
      document.getElementById("label_phone").hidden = true;
      document.getElementById("phone").hidden = true;
      document.getElementById("setSamePhone").hidden = true;
      document.getElementById("labelSetSamePhone").hidden = true;

      window.Lead();
    }
  }

  getProgramDetail() {
    let {
      match: { params },
    } = this.props;

    let currentUrl = window.location.href;
    let breakUrl = currentUrl.split("/");
    let seo = "";

    if (params.seoKey) {
      seo = params.seoKey;
    } else {
      seo = breakUrl[5];
    }

    axios
      .get(Constant.GET_PROGRAM_DETAIL + seo)
      .then((response) => {
        // console.log(response.data)
        this.setState({
          program: response.data.data,
          image_link:
            Constant.GET_PROGRAMS_IMAGE + response.data.data.id_program,
        });

        this.setAgency();
      })
      // Catch any error here
      .catch((error) => {
        console.log(error);
      });
  }
  setUserIfLogin() {
    if (cookies.get("user_id") != null) {
      axios
        .get(Constant.GET_USER, {
          headers: {
            Authorization: cookies.get("accessToken"),
          },
        })
        .then((response) => {
          this.setState({
            user_id: response.data.id,
            full_name: response.data.name,
            phone_number: response.data.no_telp,
            email: response.data.email,
          });

          document.getElementById("full_name").readOnly = true;
          // document.getElementById('phone_number').readOnly = true
          document.getElementById("email").readOnly = true;
          document.getElementById("btn_for_login").hidden = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  getNominal() {
    axios
      .get(Constant.GET_NOMINAL_BUTTON)
      .then((response) => {
        this.setState({ nominals: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getPaymentMethod() {
    let selected_method = cookies.get("selected_method");
    if (selected_method != null || selected_method != "") {
      cookies.remove("linkBefore");

      if (cookies.get("customerBio")) {
        this.setState({
          donation: cookies.get("customerBio").donation,
          user_id: cookies.get("customerBio").user_id,
          full_name: cookies.get("customerBio").full_name,
          phone_number: cookies.get("customerBio").phone_number,
          email: cookies.get("customerBio").email,

          payment_method: cookies.get("selected_method"),
          vendor: cookies.get("vendor"),
        });
      }

      if (
        cookies.get("vendor").payment_name == "OVO" ||
        cookies.get("vendor").payment_name == "LINKAJA"
      ) {
        document.getElementById("label_phone").hidden = false;
        document.getElementById("phone").hidden = false;
        document.getElementById("setSamePhone").hidden = false;
        document.getElementById("labelSetSamePhone").hidden = false;
      } else {
        document.getElementById("label_phone").hidden = true;
        document.getElementById("phone").hidden = true;
        document.getElementById("setSamePhone").hidden = true;
        document.getElementById("labelSetSamePhone").hidden = true;
      }

      document.getElementById("btn_pay").innerHTML = "Ganti Metode Pembayaran";
    }
  }
  onSuggestButtonClicked(value) {
    window.InitiateCheckout(value.replace(/\./g, ""));
    this.setState({ donation: parseInt(value.replace(/\./g, "")) });
  }
  onAmountChanged(e) {
    const { name, value } = e.target;
    // window.InitiateCheckout(value.replace(/\./g,''))
    this.setState({
      [name]: parseInt(value.split(".").join("").split(",").join("")),
    });
  }
  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSpaceEmail(e) {
    const { name, value } = e.target;

    this.setState({
      email: value.replace(/\ /g, ""),
    });
  }
  goToPayment() {
    const { donation, user_id, full_name, phone_number, email } = this.state;

    let url = this.props.location.pathname;
    let array = {
      donation: donation,
      user_id: user_id,
      full_name: full_name,
      phone_number: phone_number,
      email: email,
    };

    cookies.set("linkBefore", url);
    cookies.set("customerBio", array);
    this.setState({ go_payment: true });
  }
  setAgency() {
    let program = this.state.program;
    let currentUrl = window.location.href;
    let breakUrl = currentUrl.split("/");
    // console.log(breakUrl)
    if (breakUrl.length == 8) {
      let agency = breakUrl[breakUrl.length - 1];
      // console.log(agency)
      const array = {
        id: program.id,
        seo: program.seo,
        agency: agency,
      };
      cookies.set(program.seo, array);
      this.setState({
        agency: cookies.get(program.seo).agency,
      });

      axios
        .get(Constant.GET_AGENCY + agency)
        .then((res) => {
          // console.log(res.data.id)
          let parameter = {
            id_program: program.id,
            id_agency: res.data.id,
          };
          axios
            .post(Constant.LEAD_FORM_PROGRAM, parameter)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let parameter = {
        id_program: program.id,
        id_agency: 0,
      };
      axios
        .post(Constant.LEAD_FORM_PROGRAM, parameter)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  submitDonation(event) {
    window.AddPaymentInfo();

    this.setState({ loadingDiv: true });
    if (!event.detail || event.detail === 1) {
      // console.log('dev code', event.detail)
      // document.getElementById("btnDonasi").style.pointerEvents = 'none';
      // this.setprofile()
      setTimeout(() => {
        this.doDonation();
      }, 2000);
    }
  }

  setprofile() {
    if (localStorage.getItem("nama")) {
      this.setState({
        full_name: localStorage.getItem("nama"),
        phone_number: localStorage.getItem("phone_number"),
        email: localStorage.getItem("email"),
      });
    }

    setTimeout(() => {
      this.doDonation();
    }, 2000);
  }

  doDonation() {
    const {
      donation,
      vendor,
      payment_method,
      full_name,
      phone_number,
      email,
      phone,
    } = this.state;

    this.setState({ loadingDiv: true });

    // console.log(vendor.payment_name)
    let url = this.props.location.pathname.split("/");
    if (
      payment_method == "" ||
      payment_method == null ||
      payment_method == "null"
    ) {
      swal("Harap Pilih Metode Pembayaran");
      // document.getElementById("btnDonasi").style.pointerEvents = 'none';
      this.setState({ loadingDiv: false });
    } else {
      if (vendor.payment_type != "e_wallet") {
        if (!donation || donation < 10000) {
          swal("Donasi Untuk Metode Pembayaran Ini Minimal Rp.10.000");
          // document.getElementById("btnDonasi").style.pointerEvents = 'none';
          this.setState({ loadingDiv: false });
        } else if (full_name == "" || full_name == null) {
          swal("Nama Lengkap Tidak Boleh Kosong");
          // document.getElementById("btnDonasi").style.pointerEvents = 'none';
          this.setState({ loadingDiv: false });
        } else if (phone_number == "" || phone_number == null) {
          swal("Nomor Telepon Tidak Boleh Kosong");
          // document.getElementById("btnDonasi").style.pointerEvents = 'none';
          this.setState({ loadingDiv: false });
        } else if (email == "" || email == null) {
          swal("Email Tidak Boleh Kosong");
          // document.getElementById("btnDonasi").style.pointerEvents = 'none';
          this.setState({ loadingDiv: false });
        } else if (payment_method == 0) {
          swal("Pilih Metode Pembayaran");
          // document.getElementById("btnDonasi").style.pointerEvents = 'none';
          this.setState({ loadingDiv: false });
        } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
          let vendor_name = vendor.payment_name;
          // console.log(vendor_name)
          if (vendor_name == "Alfamart" && donation > 2500000) {
            swal("Metode Pembayaran Ini Tidak Bisa Lebih Dari Rp 2.500.000");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (vendor_name == "OVO") {
            if (phone == null || phone == "") {
              swal("Harap Isi Nomor Telepon E-Wallet Anda");
              // document.getElementById("btnDonasi").style.pointerEvents = 'none';
              this.setState({ loadingDiv: false });
            } else {
              document.getElementById("ovo_popup").style.display = "block";
              this.setState({ loadingDiv: false });
              this.sendTransaction();
            }
          } else {
            this.sendTransaction();
          }
        } else {
          swal("Email Tidak Valid");
          // document.getElementById("btnDonasi").style.pointerEvents = 'none';
          this.setState({ loadingDiv: false });
        }
      } else {
        if (vendor.payment_name == "LINKAJA") {
          if (!donation || donation < 100) {
            swal("Donasi Minimal Rp.100");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (full_name == "" || full_name == null) {
            swal("Nama Lengkap Tidak Boleh Kosong");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (phone_number == "" || phone_number == null) {
            swal("Nomor Telepon Tidak Boleh Kosong");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (email == "" || email == null) {
            swal("Email Tidak Boleh Kosong");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (payment_method == 0) {
            swal("Pilih Metode Pembayaran");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
            let vendor_name = vendor.payment_name;
            // console.log(vendor_name)
            if (vendor_name == "OVO") {
              if (phone == null || phone == "") {
                swal("Harap Isi Nomor Telepon E-Wallet Anda");
                // document.getElementById("btnDonasi").style.pointerEvents = 'none';
                this.setState({ loadingDiv: false });
              } else {
                document.getElementById("ovo_popup").style.display = "block";
                this.setState({ loadingDiv: false });
                this.sendTransaction();
                document.getElementById("btnDonasi").style.pointerEvents =
                  "none";
              }
            } else {
              this.sendTransaction();
              document.getElementById("btnDonasi").style.pointerEvents = "none";
            }
          } else {
            swal("Email Tidak Valid");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          }
        } else {
          if (!donation || donation < 1) {
            swal("Donasi Minimal Rp.1");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (full_name == "" || full_name == null) {
            swal("Nama Lengkap Tidak Boleh Kosong");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (phone_number == "" || phone_number == null) {
            swal("Nomor Telepon Tidak Boleh Kosong");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (email == "" || email == null) {
            swal("Email Tidak Boleh Kosong");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (payment_method == 0) {
            swal("Pilih Metode Pembayaran");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          } else if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
            let vendor_name = vendor.payment_name;
            // console.log(vendor_name)
            if (vendor_name == "OVO") {
              if (phone == null || phone == "") {
                swal("Harap Isi Nomor Telepon E-Wallet Anda");
                // document.getElementById("btnDonasi").style.pointerEvents = 'none';
                this.setState({ loadingDiv: false });
              } else {
                document.getElementById("ovo_popup").style.display = "block";
                this.setState({ loadingDiv: false });
                this.sendTransaction();
                document.getElementById("btnDonasi").style.pointerEvents =
                  "none";
              }
            } else {
              this.sendTransaction();
              document.getElementById("btnDonasi").style.pointerEvents = "none";
            }
          } else {
            swal("Email Tidak Valid");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            this.setState({ loadingDiv: false });
          }
        }
      }
    }
  }
  updateDeviceTypeTransaction(id) {
    let parameter = {
      id: id,
      transaksi_id: "P" + id,
      device: "PWA",
    };
    axios
      .post(Constant.UPDATE_DEVICE_AFTER_TRANSACTION, parameter, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response.data)
        this.setState({ go_paid: true });
      })
      // Catch any error here
      .catch((error) => {
        console.log(error);
      });
  }
  sendTransaction() {
    window.Purchase("IDR", this.state.donation);
    const {
      user_id,
      program,
      donation,
      vendor,
      phone,
      full_name,
      phone_number,
      email,
      card_number,
      exp_month,
      exp_year,
      cvn,
    } = this.state;

    console.log(vendor);

    let nomor_hp = null;
    if (vendor.payment_name == "OVO" || vendor.payment_name == "DANA") {
      nomor_hp = phone;
    } else {
      nomor_hp = phone_number;
    }
    let metode_pembayaran = null;
    if (
      vendor.payment_type == "virtual_account" ||
      vendor.payment_type == "e_wallet"
    ) {
      metode_pembayaran =
        vendor.vendor == "midtrans" ? vendor.midtrans_code : vendor.xendit_code;
    } else if (vendor.payment_name == "Alfamart") {
      metode_pembayaran = vendor.payment_name.toUpperCase();
    } else {
      metode_pembayaran = vendor.payment_name;
    }

    if (cookies.get("moota_bank_id")) {
      metode_pembayaran = cookies.get("moota_bank_id");
    }

    let array = [];
    if (vendor.payment_type == "credit_card") {
      array = {
        account_number: card_number,
        exp_month: exp_month,
        exp_year: exp_year,
        cvn: cvn,
      };
      metode_pembayaran = "credit_card";
    }

    const parameter = {
      jumlah_donasi: donation,
      komentar: "",
      nama_produk: program.judul,
      nama: full_name,
      email: email,
      no_telp: phone_number,
      anonim: 0,
      verifikasi: 0,
      user_id: user_id,
      program_id: program.id_program,
      vendor: vendor.vendor,
      payment_type: vendor.payment_type,
      payment_method: metode_pembayaran,
      card_data: array,
      phone: nomor_hp,
      device: "PWA",
      agency: cookies.get(program.seo) ? cookies.get(program.seo).agency : "",
    };

    localStorage.setItem("nama", full_name);
    localStorage.setItem("email", email);
    localStorage.setItem("phone_number", phone_number);
    this.setState({
      loadingDiv: false,
    });

    if (this.state.vendor?.xendit_code == "MANDIRI") {
      document.getElementById("m_banking").hidden = true;
      document.getElementById("m_banking_minus").hidden = true;
      document.getElementById("internet_banking").hidden = true;
      document.getElementById("internet_banking_minus").hidden = true;
      document.getElementById("atm_banking").hidden = true;
      document.getElementById("atm_banking_minus").hidden = true;
      // document.getElementById("moota_popup").style.display = "block"
    } else if (this.state.vendor?.xendit_code == "BRI") {
      document.getElementById("bri_m_banking").hidden = true;
      document.getElementById("bri_m_banking_minus").hidden = true;
      document.getElementById("bri_internet_banking").hidden = true;
      document.getElementById("bri_internet_banking_minus").hidden = true;
      document.getElementById("bri_atm_banking").hidden = true;
      document.getElementById("bri_atm_banking_minus").hidden = true;
    } else if (this.state.vendor?.xendit_code == "BNI") {
      document.getElementById("bni_m_banking").hidden = true;
      document.getElementById("bni_m_banking_minus").hidden = true;
      document.getElementById("bni_internet_banking").hidden = true;
      document.getElementById("bni_internet_banking_minus").hidden = true;
      document.getElementById("bni_atm_banking").hidden = true;
      document.getElementById("bni_atm_banking_minus").hidden = true;
    } else if (this.state.vendor?.xendit_code == "PERMATA") {
      document.getElementById("permata_atm_banking").hidden = true;
      document.getElementById("permata_atm_banking_minus").hidden = true;
    } else if (this.state.vendor?.xendit_code == "BSI") {
      document.getElementById("bsi_m_banking").hidden = true;
      document.getElementById("bsi_m_banking_minus").hidden = true;
      document.getElementById("bsi_internet_banking").hidden = true;
      document.getElementById("bsi_internet_banking_minus").hidden = true;
      document.getElementById("bsi_atm_banking").hidden = true;
      document.getElementById("bsi_atm_banking_minus").hidden = true;
    } else if (this.state.vendor?.xendit_code == "BJB") {
      document.getElementById("bjb_m_banking").hidden = true;
      document.getElementById("bjb_m_banking_minus").hidden = true;
      document.getElementById("bjb_internet_banking").hidden = true;
      document.getElementById("bjb_internet_banking_minus").hidden = true;
      document.getElementById("bjb_atm_banking").hidden = true;
      document.getElementById("bjb_atm_banking_minus").hidden = true;
    } else if (this.state.vendor?.xendit_code == "SAHABAT_SAMPOERNA") {
      document.getElementById("sampoerna_m_banking").hidden = true;
      document.getElementById("sampoerna_m_banking_minus").hidden = true;
      document.getElementById("sampoerna_internet_banking").hidden = true;
      document.getElementById("sampoerna_internet_banking_minus").hidden = true;
      document.getElementById("sampoerna_atm_banking").hidden = true;
      document.getElementById("sampoerna_atm_banking_minus").hidden = true;
    }

    axios.post(Constant.XENDIT_SNAPTOKEN, parameter).then((response) => {
      window.Purchase("IDR", this.state.donatian);
      console.log("didieu saacan if if an");
      if (response.data.snap_token) {
        cookies.remove("linkBefore");
        cookies.remove("customerBio");
        cookies.remove("selected_method");
        cookies.remove("vendor");
        cookies.remove("moota_bank_id");

        this.setState({ loadingDiv: false });
        document.getElementById("btnDonasi").style.pointerEvents = "all";

        window.snap.pay(response.data.snap_token, {
          // Optional
          onSuccess: function (result) {
            cookies.remove(program.seo);
            let pisah_url = window.location.href.split("/");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            window.location =
              pisah_url[0] + "//" + pisah_url[2] + "/notif/notif-wait";
          },
          // Optional
          onPending: function (result) {
            cookies.remove(program.seo);
            let pisah_url = window.location.href.split("/");
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            window.location =
              pisah_url[0] + "//" + pisah_url[2] + "/notif/notif-wait";
          },
          // Optional
          onError: function (result) {
            cookies.remove(program.seo);
            // document.getElementById("btnDonasi").style.pointerEvents = 'none';
            console.log("gagal");
          },
          onClose: function (result) {
            // console.log(response.data)
            let id = "";
            if (vendor.vendor == "midtrans") {
              id = response.data.snap_token;
            }
            const data_send = {
              iDtoken: id,
              status: 1,
            };
            axios
              .post(Constant.DELETE_SNAPTOKEN, data_send)
              .then((response) => {
                // this.setState({loadingDiv: false})
                // document.getElementById("btnDonasi").style.pointerEvents = 'none';
                swal("Transaksi Dibatalkan");
              })
              .catch((error) => {
                console.log(error);
              });
          },
        });
      } else if (response.data.moota) {
        const parameter = {
          amount: response.data.moota.amount,
          bank_number: response.data.moota.bank_number,
          transaction_id: response.data.moota.transaction_id,
        };
        this.setState({
          loadingDiv: false,
          mootaMethod: parameter,
          transaction_id: response.data.moota.transaction_id,
        });

        cookies.remove("linkBefore");
        cookies.remove("customerBio");
        cookies.remove("selected_method");
        cookies.remove("vendor");
        cookies.remove(this.state.program.seo);

        // MANDIRI
        document.getElementById("m_banking").hidden = true;
        document.getElementById("m_banking_minus").hidden = true;
        document.getElementById("internet_banking").hidden = true;
        document.getElementById("internet_banking_minus").hidden = true;
        document.getElementById("atm_banking").hidden = true;
        document.getElementById("atm_banking_minus").hidden = true;

        // BRI
        document.getElementById("bri_m_banking").hidden = true;
        document.getElementById("bri_m_banking_minus").hidden = true;
        document.getElementById("bri_internet_banking").hidden = true;
        document.getElementById("bri_internet_banking_minus").hidden = true;
        document.getElementById("bri_atm_banking").hidden = true;
        document.getElementById("bri_atm_banking_minus").hidden = true;

        // BNI
        document.getElementById("bni_m_banking").hidden = true;
        document.getElementById("bni_m_banking_minus").hidden = true;
        document.getElementById("bni_internet_banking").hidden = true;
        document.getElementById("bni_internet_banking_minus").hidden = true;
        document.getElementById("bni_atm_banking").hidden = true;
        document.getElementById("bni_atm_banking_minus").hidden = true;

        // PERMATA
        document.getElementById("permata_atm_banking").hidden = true;
        document.getElementById("permata_atm_banking_minus").hidden = true;

        // BSI
        document.getElementById("bsi_m_banking").hidden = true;
        document.getElementById("bsi_m_banking_minus").hidden = true;
        document.getElementById("bsi_internet_banking").hidden = true;
        document.getElementById("bsi_internet_banking_minus").hidden = true;
        document.getElementById("bsi_atm_banking").hidden = true;
        document.getElementById("bsi_atm_banking_minus").hidden = true;

        //BJB
        document.getElementById("bjb_m_banking").hidden = true;
        document.getElementById("bjb_m_banking_minus").hidden = true;
        document.getElementById("bjb_internet_banking").hidden = true;
        document.getElementById("bjb_internet_banking_minus").hidden = true;
        document.getElementById("bjb_atm_banking").hidden = true;
        document.getElementById("bjb_atm_banking_minus").hidden = true;

        //SAMPOERNA
        document.getElementById("sampoerna_m_banking").hidden = true;
        document.getElementById("sampoerna_m_banking_minus").hidden = true;
        document.getElementById("sampoerna_internet_banking").hidden = true;
        document.getElementById(
          "sampoerna_internet_banking_minus"
        ).hidden = true;
        document.getElementById("sampoerna_atm_banking").hidden = true;
        document.getElementById("sampoerna_atm_banking_minus").hidden = true;

        document.getElementById("btnDonasi").style.pointerEvents = "none";
        document.getElementById("moota_popup").style.display = "block";
      } else {
        // console.log(metode_pembayaran)
        console.log("didieu else");
        if (response.data.data_ew) {
          console.log("didieu else if data ew");
          cookies.remove("linkBefore");
          cookies.remove("customerBio");
          cookies.remove("selected_method");
          cookies.remove("vendor");
          cookies.remove(this.state.program.seo);

          // this.setState({
          //     transaction_id: response.data.data_ew.transaction_id
          // })
          console.log("didieu link na", response.data.data_ew.checkout_url);
          if (
            response.data.data_ew.ewallet_type == "ID_LINKAJA" ||
            response.data.data_ew.ewallet_type == "ID_DANA" ||
            response.data.data_ew.ewallet_type == "ID_SHOPEEPAY"
          ) {
            window.location.href = response.data.data_ew.checkout_url;
          }
        } else if (response.data.data_ro) {
          console.log("didieu else if data ro");
          cookies.remove("linkBefore");
          cookies.remove("customerBio");
          cookies.remove("selected_method");
          cookies.remove("vendor");
          cookies.remove(this.state.program.seo);

          this.setState({
            loadingDiv: false,
            alfamart_code: response.data.data_ro.payment_code,
            transaction_id: response.data.data_ro.transaction_id,
          });
          document.getElementById("alfamart_popup").style.display = "block";
        } else if (response.data.data_va) {
          console.log("didieu else if data va");
          const parameter = {
            amount: response.data.data_va.amount,
            bank_number: response.data.data_va.account_number,
            transaction_id: "",
          };
          this.setState({
            loadingDiv: false,
            mootaMethod: parameter,
            transaction_id: "",
          });
          cookies.remove("linkBefore");
          cookies.remove("customerBio");
          cookies.remove("selected_method");
          cookies.remove("vendor");
          cookies.remove(this.state.program.seo);
          document.getElementById("btnDonasi").style.pointerEvents = "none";
          document.getElementById("moota_popup").style.display = "block";
        } else {
          console.log("didieu else else");
          if (response.data.messageGagal == "Undefined index: message") {
            swal(
              "Harap gunakan nomor telepon yang benar untuk metode pembayaran tersebut"
            );
          } else {
            swal("pembayaran tidak dilakukan, atau coba lebih cepat lagi");
          }

          document.getElementById("ovo_popup").style.display = "none";
          document.getElementById("btnDonasi").style.pointerEvents = "all";
        }
      }
    });
  }

  numberFormat(value) {
    return value
      .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
      .replace(/\,/g, ".");
  }

  gotBack(v) {
    axios
      .get(Constant.GET_CATEGORY + v)
      .then((res) => {
        // console.log(res)
        this.setState({
          kategori: res.data.seo,
          go_back: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleClosePopup(id) {
    document.getElementById(id).style.display = "none";
    document.getElementById("btnDonasi").style.pointerEvents = "all";
  }

  handleCheckboxChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (value == true) {
      document.getElementById("label_phone").hidden = true;
      document.getElementById("phone").hidden = true;
      this.setState({ phone: this.state.phone_number });
    } else {
      document.getElementById("label_phone").hidden = false;
      document.getElementById("phone").hidden = false;
      this.setState({ phone: "" });
    }
  }

  metodePembayaranHandler(btn_for_hide, btn_for_show, div_id, type) {
    document.getElementById(btn_for_hide).hidden = true;
    document.getElementById(btn_for_show).hidden = false;
    if (type == "plus") {
      document.getElementById(div_id).hidden = false;
    } else {
      document.getElementById(div_id).hidden = true;
    }
  }

  render() {
    const {
      match,
      match: { params },
    } = this.props;
    const {
      program,
      donation,
      full_name,
      vendor,
      phone,
      phone_number,
      email,
      go_back,
      go_paid,
      go_payment,
      mootaMethod,
      kategori,
      alfamart_code,
      nominals,
      loadingDiv,
    } = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    let agency = queryParams.get("agency");

    let foto = "";

    if (vendor) {
      foto = Constant.GET_PICT_VENDOR + vendor.id;
    }

    if (go_back) {
      if (agency) {
        return (
          <Redirect
            exact
            to={
              `/programs/` + kategori + `/` + program.seo + "?agency=" + agency
            }
          />
        );
      } else {
        return (
          <Redirect exact to={`/programs/` + kategori + `/` + program.seo} />
        );
      }
    }
    // return <Redirect exact to={`/detailprogram/`+program.judul} />
    else if (go_paid) return <Redirect exact to="/notif/notif-wait" />;
    else if (go_payment)
      // return <Redirect exact to="/payment_method" />
      return (
        <Redirect
          exact
          to={{
            pathname: "/payment_method",
            state: {
              from: "Donasi",
            },
          }}
        />
      );
    return (
      <div>
        <div>
          <header id="header">
            <nav className="left header-kembali">
              <i
                id="ic-sidebar"
                className="fa fa-arrow-left"
                onClick={() => this.gotBack(program.program.id_category)}
              ></i>
              {String(program.judul).substring(0, 32)}...
            </nav>
          </header>
        </div>

        <CustomBreadcrumbs link={this.props.location.pathname} />

        {/* OVO */}
        <div id="ovo_popup" className="modalfilter">
          <div className="filter-content">
            <span
              className="close"
              onClick={() => this.handleClosePopup("ovo_popup")}
            >
              &times;
            </span>

            <p className="head-filter">OVO</p>
            <p className="all-category">
              <img src={foto} style={{ maxWidth: 150 + "px" }} />
            </p>
            <div
              className="scroller-category"
              style={{ height: 70 + "vh", padding: "30px" }}
            >
              <center>
                <b>Instruksi Pembayaran</b>
                <br />
                Nominal Yang Akan Didonasikan :
                <br />
                <h2>Rp. {this.numberFormat(donation ? donation : 0)}</h2>
                <br />
                Periksa aplikasi OVO anda dan segera selesaikan pembayaran dalam
                30 detik!
              </center>
              <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
              <h4>Order Id : P{this.state.transaction_id}</h4>
              <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
              <b>Notes : </b>
              <br />
              Apabila transaksi gagal, silahkan coba cara berikut :
              <br />
              1. Masukan kembali nomor telepon dan coba kembali pembayaran.
              <br />
              2. Bersihkan cache aplikasi OVO anda.
              <br />
              3. Apabila transaksi masih gagal, silahkan coba kembali dengan
              metode pembayaran yang lain. Anda TIDAK akan dikenakan biaya dua
              kali untuk mencoba kembali pembayaran
              <br />
              <br />
            </div>
            <a
              className="btn-dns"
              style={{ padding: 10 + "px" }}
              onClick={() => this.setState({ go_paid: true })}
            >
              <p>Saya Sudah Bayar</p>
            </a>
          </div>
        </div>

        {/* ALFAMART */}
        <div id="alfamart_popup" className="modalfilter">
          <div className="filter-content">
            <span
              className="close"
              onClick={() => this.handleClosePopup("alfamart_popup")}
            >
              &times;
            </span>

            <p className="head-filter">ALFAMART</p>
            <p className="all-category">
              <img src={foto} style={{ maxWidth: 150 + "px" }} />
            </p>
            <div className="scroller-category">
              <center>
                <b>Instruksi Pembayaran</b>
                <br />
                Nominal Yang Akan Didonasikan :
                <br />
                <h2 style={{ marginBottom: 0 + "px" }}>
                  Rp. {this.numberFormat(donation ? donation : 0)}
                </h2>
                <br />
                <h3 style={{ marginBottom: 0 + "px" }}>{alfamart_code}</h3>
                <br />
                <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                <h4>Order Id : P{this.state.transaction_id}</h4>
                <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                <br />
                Harap simpan kode pembayaran di atas untuk melakukan pembayaran!
              </center>
            </div>
            <a
              className="btn-dns"
              style={{ padding: 10 + "px" }}
              onClick={() => this.setState({ go_paid: true })}
            >
              <p>Selesaikan Pembayaran</p>
            </a>
          </div>
        </div>

        {/* Moota */}
        <div id="moota_popup" className="modalfilter">
          <div className="filter-content" style={{ minHeight: "92vh" }}>
            <span
              className="close"
              onClick={() => this.handleClosePopup("moota_popup")}
            >
              &times;
            </span>

            <p className="head-filter">Konfirmasi Pembayaran</p>
            <p className="all-category">
              <img src={foto} style={{ maxWidth: 150 + "px" }} />
            </p>
            <div className="scroller-category">
              {this.state.vendor?.xendit_code == "MANDIRI" ? (
                <>
                  <center>
                    <br />
                    Nominal Yang Akan Didonasikan :
                    <br />
                    <h2 style={{ marginBottom: 0 + "px" }}>
                      Rp.{" "}
                      {this.numberFormat(
                        mootaMethod.amount ? mootaMethod.amount : 0
                      )}
                    </h2>
                    <br />
                    <h3 style={{ marginBottom: 0 + "px" }}>
                      No Virtual Account <br /> {mootaMethod.bank_number}
                    </h3>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(mootaMethod.bank_number);
                        alert("copied");
                      }}
                    >
                      copy
                    </p>
                    {this.state.transaction_id != "" ? (
                      <h4>Order Id : P{this.state.transaction_id}</h4>
                    ) : (
                      <h4></h4>
                    )}
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <br />
                  </center>

                  <center>
                    <b>Panduan Pembayaran</b>
                  </center>

                  {/* MANDIRI M-Banking Online */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="m_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "m_banking_plus",
                        "m_banking_minus",
                        "m_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>
                      Mandiri m-banking (Mandiri Online)
                    </b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="m_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "m_banking_minus",
                        "m_banking_plus",
                        "m_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>
                      Mandiri m-banking (Mandiri Online)
                    </b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="m_banking">
                    <p>
                      1. Pilih menu "Pembayaran" lalu pilih menu "Multipayment".
                    </p>
                    <p>2. Pilih penyedia jasa "Xendit 88908"</p>
                    <p>
                      3. Masukkan "Nomor Virtual Account" dan "Nominal" yang
                      akan dibayarkan, lalu pilih "Lanjut"
                    </p>
                    <p>4. Setelah muncul tagihan, pilih "Konfirmasi"</p>
                    <p>5. Masukkan PIN Mandiri Online.</p>
                    <p>6. Transaksi selesai, simpan bukti bayar kamu.</p>
                  </div>

                  {/* MANDIRI Internet Banking */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="internet_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "internet_banking_plus",
                        "internet_banking_minus",
                        "internet_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>
                      Mandiri Internet Banking
                    </b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="internet_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "internet_banking_minus",
                        "internet_banking_plus",
                        "internet_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>
                      Mandiri Internet Banking
                    </b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="internet_banking">
                    <p>
                      1. Masuk ke halaman website internet banking Mandiri
                      (https://ib.bankmandiri.co.id)
                    </p>
                    <p>
                      2. Masuk ke akun kamu dengan mengisi User ID dan PIN
                      internet Banking kamu.
                    </p>
                    <p>3. Pilih menu "Bayar" lalu pilih "Multi Payment"</p>
                    <p>4. Pada bagian "Dari Rekening" masukkan rekening kamu</p>
                    <p>
                      5. Pada bagian "Penyedia Jasa" pilih "Xendit 88908" lalu
                      klik "Lanjut"
                    </p>
                    <p>
                      6. Masukkan nomor Virtual Account diatas yang tertera.
                      Hindari mencentang "Simpan Daftar Transfer" karena jumlah
                      pembayaran Anda sebelumnya akan ikut tersimpan dan dapat
                      mengganggu proses pembayaran berikutnya.
                    </p>
                    <p>
                      7. Klik "Lanjutkan" dan periksa informasi pembayaran. Jika
                      sudah benar, beri centang
                    </p>
                    <p>8. Masukkan PIN token kamu dan klik "Kirim".</p>
                  </div>

                  {/* ATM MANDIRI */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="atm_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "atm_banking_plus",
                        "atm_banking_minus",
                        "atm_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM Mandiri</b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="atm_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "atm_banking_minus",
                        "atm_banking_plus",
                        "atm_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM Mandiri</b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="atm_banking">
                    <p>
                      1. Masukkan kartu ATM Mandiri dan PIN ATM Mandiri kamu.
                    </p>
                    <p>
                      2. Pilih menu Bayar/Beli `&gt;` Lainnya `&gt;` Lainnya
                    </p>
                    <p>3. Pilih menu Multipayment</p>
                    <p>4. Masukkan kode perusahaan/institusi</p>
                    <p>
                      5. Masukkan nomor Virtual Account diatas lalu klik benar
                    </p>
                    <p>
                      6. Cek nama dan nominal pembayaran, apabila telah sesuai
                      masukkan angka 1 lalu pilih
                    </p>
                    <p>
                      7. Periksa konfirmasi pembayaran, jika sudah benar pilih
                      ‘YA’
                    </p>
                    <p>
                      8. Transaksi selesai, pilih Tidak untuk tidak melanjutkan
                      transaksi lain.
                    </p>
                  </div>
                </>
              ) : this.state.vendor?.xendit_code == "BRI" ? (
                <>
                  <center>
                    <br />
                    Nominal Yang Akan Didonasikan :
                    <br />
                    <h2 style={{ marginBottom: 0 + "px" }}>
                      Rp.{" "}
                      {this.numberFormat(
                        mootaMethod.amount ? mootaMethod.amount : 0
                      )}
                    </h2>
                    <br />
                    <h3 style={{ marginBottom: 0 + "px" }}>
                      No Virtual Account <br /> {mootaMethod.bank_number}
                    </h3>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(mootaMethod.bank_number);
                        alert("copied");
                      }}
                    >
                      copy
                    </p>
                    {this.state.transaction_id != "" ? (
                      <h4>Order Id : P{this.state.transaction_id}</h4>
                    ) : (
                      <h4></h4>
                    )}
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <br />
                  </center>

                  <center>
                    <b>Panduan Pembayaran</b>
                  </center>

                  {/* BRI M-Banking Online */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bri_m_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bri_m_banking_plus",
                        "bri_m_banking_minus",
                        "bri_m_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>
                      BRI m-banking (Mandiri Online)
                    </b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="bri_m_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bri_m_banking_minus",
                        "bri_m_banking_plus",
                        "bri_m_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>
                      BRI m-banking (Mandiri Online)
                    </b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="bri_m_banking">
                    <p>
                      1. Masukkan ke aplikasi BRI Mobile dan input PIN kamu.
                    </p>
                    <p>2. Pilih menu pembayaran lalu pilih BRIVA</p>
                    <p>
                      3. Masukkan nomor BRI Virtual Account diatas beserta
                      jumlah pembayaran
                    </p>
                    <p>
                      4. Masukkan nomor BRI Virtual Account diatas beserta
                      jumlah pembayaran
                    </p>
                    <p>
                      5. Transaksi selesai, mohon simpan nomor invoice sebagai
                      bukti pembayaran
                    </p>
                  </div>

                  {/* BRI Internet Banking */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bri_internet_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bri_internet_banking_plus",
                        "bri_internet_banking_minus",
                        "bri_internet_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>BRI Internet Banking</b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="bri_internet_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bri_internet_banking_minus",
                        "bri_internet_banking_plus",
                        "bri_internet_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>BRI Internet Banking</b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="bri_internet_banking">
                    <p>
                      1. Masuk ke Halaman website internet banking BRI
                      (ib.bri.co.id)
                    </p>
                    <p>
                      2. Masuk ke akun kamu dengan mengisi User ID dan PIN
                      internet banking kamu
                    </p>
                    <p>3. Pilih menu “Pembayaran” lalu pilih “BRIVA”</p>
                    <p>
                      4. Masukan nomor Virtual Account diatas yang tertera, lalu
                      masukan nominal yang akan dibayarkan
                    </p>
                    <p>5. Masukan Password dan Mtoken Kamu</p>
                    <p>
                      6. Transaksi selesai, mohon simpan nomor invoice sebagai
                      bukti pembayaran
                    </p>
                  </div>

                  {/* ATM BRI */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bri_atm_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bri_atm_banking_plus",
                        "bri_atm_banking_minus",
                        "bri_atm_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BRI</b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="bri_atm_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bri_atm_banking_minus",
                        "bri_atm_banking_plus",
                        "bri_atm_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BRI</b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="bri_atm_banking">
                    <p>1. Masukan kartu ATM BRI dan PIN ATM BRI kamu.</p>
                    <p>2. Pilih “Menu Lainnya” lalu pilih menu “Pembayaran”</p>
                    <p>
                      3. Pilih “BRIVA” lalu masukan nomor Virtual Account diatas
                      (262156720691852366) lalu tekan “Benar”
                    </p>
                    <p>
                      4. Cek nama dan nominal pembayaran, apabila telah sesuai
                      pilih “Ya”.
                    </p>
                    <p>
                      5. Transaksi selesai, pilih “Tidak” untuk tidak
                      melanjutkan transaksi lain.
                    </p>
                  </div>
                </>
              ) : this.state.vendor?.xendit_code == "BNI" ? (
                <>
                  <center>
                    <br />
                    Nominal Yang Akan Didonasikan :
                    <br />
                    <h2 style={{ marginBottom: 0 + "px" }}>
                      Rp.{" "}
                      {this.numberFormat(
                        mootaMethod.amount ? mootaMethod.amount : 0
                      )}
                    </h2>
                    <br />
                    <h3 style={{ marginBottom: 0 + "px" }}>
                      No Virtual Account <br /> {mootaMethod.bank_number}
                    </h3>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(mootaMethod.bank_number);
                        alert("copied");
                      }}
                    >
                      copy
                    </p>
                    {this.state.transaction_id != "" ? (
                      <h4>Order Id : P{this.state.transaction_id}</h4>
                    ) : (
                      <h4></h4>
                    )}
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <br />
                  </center>

                  <center>
                    <b>Panduan Pembayaran</b>
                  </center>

                  {/* BNI M-Banking Online */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bni_m_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bni_m_banking_plus",
                        "bni_m_banking_minus",
                        "bni_m_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>BNI M-Banking</b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="bni_m_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bni_m_banking_minus",
                        "bni_m_banking_plus",
                        "bni_m_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>BNI M-Banking</b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="bni_m_banking">
                    <p>1. Masuk ke aplikasi BNI Mobile dan input PIN kamu..</p>
                    <p>2. Pilih Menu Transfer</p>
                    <p>
                      3. Pilih menu Virtual Account Billing dan masukan nomor
                      Virtual Account diatas lalu klik lanjut.
                    </p>
                    <p>
                      4. Cek nama dan nominal pembayaran, apabila telah sesuai
                      klik OK.
                    </p>
                    <p>5. Masukan PIN BNI Mobile Kamu dan klik OK.</p>
                    <p>
                      6. Transaksi Selesai, Mohon simpan nomor invoice sebagai
                      bukti pembayaran.
                    </p>
                  </div>

                  {/* BNI Internet Banking */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bni_internet_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bni_internet_banking_plus",
                        "bni_internet_banking_minus",
                        "bni_internet_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>BNI Internet Banking</b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="bni_internet_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bni_internet_banking_minus",
                        "bni_internet_banking_plus",
                        "bni_internet_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>BNI Internet Banking</b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="bni_internet_banking">
                    <p>
                      1. Masuk ke Halaman website internet banking BNI
                      (ibank.bni.co.id){" "}
                    </p>
                    <p>
                      2. Masuk ke akun kamu dengan mengisi User ID dan PIN
                      internet banking kamu{" "}
                    </p>
                    <p>
                      3. Pilih menu “Transfer” lalu pilih “Virtual Account
                      Billing”{" "}
                    </p>
                    <p>
                      4.Masukan nomor Virtual Account diatas (8808672066217846)
                      lalu pilih “Lanjut”
                    </p>
                    <p>5. Konfirmasi dengan input Otentikasi Token </p>
                    <p>
                      6. Transaksi selesai, mohon simpan nomor invoice sebagai
                      bukti pembayaran{" "}
                    </p>
                  </div>

                  {/* ATM BNI */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bni_atm_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bni_atm_banking_plus",
                        "bni_atm_banking_minus",
                        "bni_atm_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BNI</b>
                  </div>

                  <div
                    style={{ marginLeft: "15px" }}
                    id="bni_atm_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bni_atm_banking_minus",
                        "bni_atm_banking_plus",
                        "bni_atm_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BNI</b>
                  </div>

                  <div style={{ marginLeft: "15px" }} id="bni_atm_banking">
                    <p>1. Masukan kartu ATM BNI dan PIN ATM BNI kamu. </p>
                    <p>2. Pilih “Menu Lainnya”</p>
                    <p>
                      3. Pilih menu “VIRTUAL ACCOUNT BILLING” Masukan nomor
                      Virtual Account diatas (8808672066217846).
                    </p>
                    <p>
                      4. Cek nama dan nominal pembayaran, apabila telah sesuai
                      pilih “Benar”.
                    </p>
                    <p>
                      5. Transaksi selesai, pilih “Tidak” untuk tidak
                      melanjutkan transaksi lain.
                    </p>
                    <p>6. Terakhir jangan lupa ambil kartu ATM BNI kamu.</p>
                  </div>
                </>
              ) : this.state.vendor?.xendit_code == "PERMATA" ? (
                <>
                  <center>
                    <br />
                    Nominal Yang Akan Didonasikan :
                    <br />
                    <h2 style={{ marginBottom: 0 + "px" }}>
                      Rp.{" "}
                      {this.numberFormat(
                        mootaMethod.amount ? mootaMethod.amount : 0
                      )}
                    </h2>
                    <br />
                    <h3 style={{ marginBottom: 0 + "px" }}>
                      No Virtual Account <br /> {mootaMethod.bank_number}
                    </h3>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(mootaMethod.bank_number);
                        alert("copied");
                      }}
                    >
                      copy
                    </p>
                    {this.state.transaction_id != "" ? (
                      <h4>Order Id : P{this.state.transaction_id}</h4>
                    ) : (
                      <h4></h4>
                    )}
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <br />
                  </center>
                  <center>
                    <b>Panduan Pembayaran</b>
                  </center>

                  {/* ATM PERMATA */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="permata_atm_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "permata_atm_banking_plus",
                        "permata_atm_banking_minus",
                        "permata_atm_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM PERMATA</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="permata_atm_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "permata_atm_banking_minus",
                        "permata_atm_banking_plus",
                        "permata_atm_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM PERMATA</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="permata_atm_banking">
                    <p>
                      1. Masukkan kartu ATM PERMATA dan PIN ATM PERMATA kamu.
                    </p>
                    <p>2. Pilih menu “Transaksi Lainnya” lalu.</p>
                    <p>3. Pilih Pembayaran Lainnya.</p>
                    <p>4. Pilih Akun Virtual.</p>
                    <p>
                      5. Masukkan 16 digit No. Akun (8214672066890244) dan tekan
                      Benar.
                    </p>
                    <p>
                      6. Jumlah yang harus dibayar, nomor akun, dan nama
                      pedagang akan muncul di halaman konfirmasi pembayaran.
                      Jika informasinya benar, tekan Benar.
                    </p>
                    <p>7. Pilih akun pembayaran Anda dan tekan Benar.</p>
                  </div>
                </>
              ) : this.state.vendor?.xendit_code == "BSI" ? (
                <>
                  <center>
                    <br />
                    Nominal Yang Akan Didonasikan :
                    <br />
                    <h2 style={{ marginBottom: 0 + "px" }}>
                      Rp.{" "}
                      {this.numberFormat(
                        mootaMethod.amount ? mootaMethod.amount : 0
                      )}
                    </h2>
                    <br />
                    <h3 style={{ marginBottom: 0 + "px" }}>
                      No Virtual Account <br /> {mootaMethod.bank_number}
                    </h3>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(mootaMethod.bank_number);
                        alert("copied");
                      }}
                    >
                      copy
                    </p>
                    {this.state.transaction_id != "" ? (
                      <h4>Order Id : P{this.state.transaction_id}</h4>
                    ) : (
                      <h4></h4>
                    )}
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <br />
                  </center>
                  <center>
                    <b>Panduan Pembayaran</b>
                  </center>
                  {/* BSI M-Banking Online */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bsi_m_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bsi_m_banking_plus",
                        "bsi_m_banking_minus",
                        "bsi_m_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>BSI M-Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bsi_m_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bsi_m_banking_minus",
                        "bsi_m_banking_plus",
                        "bsi_m_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>BSI M-Banking</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="bsi_m_banking">
                    <p>1. Buka aplikasi BSI Mobile</p>
                    <p>2. Masukkan User ID dan Password</p>
                    <p>3. Pilih Menu PEMBAYARAN</p>
                    <p>4. Pilih Nomor Rekening BSI Anda</p>
                    <p>5. Pilih menu INSTITUSI</p>
                    <p>6. Masukkan nama institusi Xendit (kode 9347)</p>
                    <p>
                      7. Masukkan Nomor Virtual Account diatas tanpa diikuti
                      kode institusi
                    </p>
                    <p>8. Konfirmasi detail transaksi anda</p>
                    <p>9. Masukkan otentikasi transaksi/token</p>
                  </div>
                  {/* // BSI Internet Banking */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bsi_internet_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bsi_internet_banking_plus",
                        "bsi_internet_banking_minus",
                        "bsi_internet_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>BSI Internet Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bsi_internet_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bsi_internet_banking_minus",
                        "bsi_internet_banking_plus",
                        "bsi_internet_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>BSI Internet Banking</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="bsi_internet_banking">
                    <p>1. Buka situs https://bsinet.bankbsi.co.id</p>
                    <p>2. Masukkan User ID dan Password</p>
                    <p>3. Pilih Menu PEMBAYARAN</p>
                    <p>4. Pilih Nomor Rekening BSI Anda</p>
                    <p>5. Pilih menu INSTITUSI</p>
                    <p>6. Masukkan nama institusi Xendit (kode 9347)</p>
                    <p>
                      7. Masukkan 16 digit nomor rekening virtual account diatas
                      Masukkan Nomor Virtual Account tanpa diikuti kode
                      institusi
                    </p>
                    <p>8. Konfirmasi detail transaksi anda</p>
                    <p>9. Masukkan otentikasi transaksi/token</p>
                  </div>
                  {/* ATM BSI */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bsi_atm_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bsi_atm_banking_plus",
                        "bsi_atm_banking_minus",
                        "bsi_atm_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BSI</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bsi_atm_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bsi_atm_banking_minus",
                        "bsi_atm_banking_plus",
                        "bsi_atm_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BSI</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="bsi_atm_banking">
                    <p>1. Masukkan kartu ATM dan PIN anda.</p>
                    <p>2. Pilih menu PEMBAYARAN / PEMBELIAN</p>
                    <p>3. Pilih menu INSTITUSI</p>
                    <p>
                      4. Masukkan kode BSI VA Nomor Virtual Account diatas
                      Contoh: 9347xxxxxxxxxx
                    </p>
                    <p>
                      5. Detail yang ditampilkan: NIM, Nama, & Total Tagihan
                    </p>
                    <p>6. Konfirmasi detail transaksi anda</p>
                  </div>
                </>
              ) : this.state.vendor?.xendit_code == "BJB" ? (
                <>
                  <center>
                    <br />
                    Nominal Yang Akan Didonasikan :
                    <br />
                    <h2 style={{ marginBottom: 0 + "px" }}>
                      Rp.{" "}
                      {this.numberFormat(
                        mootaMethod.amount ? mootaMethod.amount : 0
                      )}
                    </h2>
                    <br />
                    <h3 style={{ marginBottom: 0 + "px" }}>
                      No Virtual Account <br /> {mootaMethod.bank_number}
                    </h3>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(mootaMethod.bank_number);
                        alert("copied");
                      }}
                    >
                      copy
                    </p>
                    {this.state.transaction_id != "" ? (
                      <h4>Order Id : P{this.state.transaction_id}</h4>
                    ) : (
                      <h4></h4>
                    )}
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <br />
                  </center>
                  <center>
                    <b>Panduan Pembayaran</b>
                  </center>
                  {/* // BJB M-Banking Online */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bjb_m_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bjb_m_banking_plus",
                        "bjb_m_banking_minus",
                        "bjb_m_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>BJB M-Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bjb_m_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bjb_m_banking_minus",
                        "bjb_m_banking_plus",
                        "bjb_m_banking",
                        "bjb_minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>BJB M-Banking</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="bjb_m_banking">
                    <p>1. Buka aplikasi BJB Mobile</p>
                    <p>2. Masukkan User ID dan Password</p>
                    <p>3. Pilih menu VIRTUAL ACCOUNT</p>
                    <p>4. Lalu pilih rekening debet yang akan digunakan</p>
                    <p>
                      5. Masukkan 16 digit nomor rekening virtual account diatas
                    </p>
                    <p>6. Konfirmasi detail transaksi anda</p>
                  </div>
                  {/* // BJB Internet Banking */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bjb_internet_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bjb_internet_banking_plus",
                        "bjb_internet_banking_minus",
                        "bjb_internet_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>BJB Internet Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bjb_internet_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bjb_internet_banking_minus",
                        "bjb_internet_banking_plus",
                        "bjb_internet_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>BJB Internet Banking</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="bjb_internet_banking">
                    <p>1. Buka halaman https://ib.bankbjb.co.id/bjb.net</p>
                    <p>2. Masukkan User ID dan Password</p>
                    <p>3. Pilih menu VIRTUAL ACCOUNT</p>
                    <p>4. Lalu pilih rekening debet yang akan digunakan</p>
                    <p>
                      5. Masukkan 16 digit nomor rekening virtual account diatas
                    </p>
                    <p>6. Konfirmasi detail transaksi anda</p>
                  </div>

                  {/* //ATM BJB */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bjb_atm_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bjb_atm_banking_plus",
                        "bjb_atm_banking_minus",
                        "bjb_atm_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BJB</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="bjb_atm_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "bjb_atm_banking_minus",
                        "bjb_atm_banking_plus",
                        "bjb_atm_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM BJB</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="bjb_atm_banking">
                    <p>1. Masukkan kartu ATM dan PIN anda.</p>
                    <p>2. Pilih menu TRANSAKSI LAINNYA → VIRTUAL ACCOUNT</p>
                    <p>
                      3. Masukkan 16 digit nomor rekening virtual account
                      diatas.
                    </p>
                    <p>4. Masukkan nominal pembayaran.</p>
                    <p>
                      5. Cek kembali transaksi anda, lalu pilih YA untuk
                      melanjutkan
                    </p>
                    <p>6. Pembayaran melalui ATM sukses dilakukan.</p>
                  </div>
                </>
              ) : this.state.vendor?.xendit_code == "SAHABAT_SAMPOERNA" ? (
                <>
                  <center>
                    <br />
                    Nominal Yang Akan Didonasikan :
                    <br />
                    <h2 style={{ marginBottom: 0 + "px" }}>
                      Rp.{" "}
                      {this.numberFormat(
                        mootaMethod.amount ? mootaMethod.amount : 0
                      )}
                    </h2>
                    <br />
                    <h3 style={{ marginBottom: 0 + "px" }}>
                      No Virtual Account <br /> {mootaMethod.bank_number}
                    </h3>
                    <p
                      onClick={() => {
                        navigator.clipboard.writeText(mootaMethod.bank_number);
                        alert("copied");
                      }}
                    >
                      copy
                    </p>
                    {this.state.transaction_id != "" ? (
                      <h4>Order Id : P{this.state.transaction_id}</h4>
                    ) : (
                      <h4></h4>
                    )}
                    <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <br />
                  </center>
                  <center>
                    <b>Panduan Pembayaran</b>
                  </center>

                  {/* // SAMPOERNA M-Banking Online */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_m_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "sampoerna_m_banking_plus",
                        "sampoerna_m_banking_minus",
                        "sampoerna_m_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>M-Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_m_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "sampoerna_m_banking_minus",
                        "sampoerna_m_banking_plus",
                        "sampoerna_m_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>M-Banking</b>
                  </div>
                  <div style={{ marginLeft: "15px" }} id="sampoerna_m_banking">
                    <p>
                      1. Jika pembayaran menggunakan transaksi SKN maka harus
                      memasukkan nama tujuan penerima “Bank Sahabat Sampoerna”.
                    </p>
                    <p>2. Login akun Internet Banking pada handphone anda.</p>
                    <p>
                      3. Pilih menu TRANSFER DANA → TRANSFER KE REKENING BANK
                      SAHABAT SAMPOERNA.
                    </p>
                    <p>
                      4. Masukkan 16 digit nomor rekening virtual account
                      diatas.
                    </p>
                    <p>5. Masukkan nominal pembayaran.</p>
                    <p>6. Input token i-Banking anda.</p>
                    <p>7. Pembayaran melalui i-Banking sukses dilakukan.</p>
                  </div>

                  {/* // SAMPOERNA Internet Banking */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_internet_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "sampoerna_internet_banking_plus",
                        "sampoerna_internet_banking_minus",
                        "sampoerna_internet_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>Internet Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_internet_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "sampoerna_internet_banking_minus",
                        "sampoerna_internet_banking_plus",
                        "sampoerna_internet_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>Internet Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_internet_banking"
                  >
                    <p>
                      1. Jika pembayaran menggunakan transaksi SKN maka harus
                      memasukkan nama tujuan penerima “Bank Sahabat Sampoerna”.
                    </p>
                    <p>2. Login akun Internet Banking pada handphone anda.</p>
                    <p>
                      3. Pilih menu TRANSFER DANA → TRANSFER KE ANTAR BANK.
                      Masukkan kode BANK SAHABAT SAMPOERNA yaitu 523 → Pilih YA.
                    </p>
                    <p>
                      4. Masukkan 16 digit nomor rekening virtual account
                      diatas.
                    </p>
                    <p>5. Masukkan nominal pembayaran.</p>
                    <p>6. Input token M-Banking anda.</p>
                    <p>7. Pembayaran melalui M-Banking sukses dilakukan.</p>
                  </div>

                  {/* //ATM SAMPOERNA */}
                  <hr style={{ marginBottom: "0px", marginTop: "0px" }} />
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_atm_banking_plus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "sampoerna_atm_banking_plus",
                        "sampoerna_atm_banking_minus",
                        "sampoerna_atm_banking",
                        "plus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-plus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_atm_banking_minus"
                    onClick={() =>
                      this.metodePembayaranHandler(
                        "sampoerna_atm_banking_minus",
                        "sampoerna_atm_banking_plus",
                        "sampoerna_atm_banking",
                        "minus"
                      )
                    }
                  >
                    &nbsp;<i class="fa fa-minus"></i>
                    <b style={{ marginLeft: "15px" }}>ATM Banking</b>
                  </div>
                  <div
                    style={{ marginLeft: "15px" }}
                    id="sampoerna_atm_banking"
                  >
                    <p>1. Masukkan kartu ATM dan PIN anda.</p>
                    <p>
                      2. Pilih menu TRANSAKSI LAINNYA → TRANSFER → TRANSFER KE
                      BANK SAHABAT SAMPOERNA.
                    </p>
                    <p>
                      3. Masukkan 16 digit nomor rekening virtual account
                      diatas.
                    </p>
                    <p>4. Masukkan nominal pembayaran.</p>
                    <p>
                      5. Cek kembali transaksi anda, lalu pilih YA untuk
                      melanjutkan
                    </p>
                    <p>6. Pembayaran melalui ATM sukses dilakukan.</p>
                  </div>
                </>
              ) : (
                // </div>
                <center>
                  <b>Instruksi Pembayaran</b>
                  <br />
                  Nominal Yang Akan Didonasikan :
                  <br />
                  <h2 style={{ marginBottom: 0 + "px" }}>
                    Rp.{" "}
                    {this.numberFormat(
                      mootaMethod.amount ? mootaMethod.amount : 0
                    )}
                  </h2>
                  <br />
                  <h3 style={{ marginBottom: 0 + "px" }}>
                    No Virtual Account <br /> {mootaMethod.bank_number}
                  </h3>
                  <p
                    onClick={() => {
                      navigator.clipboard.writeText(mootaMethod.bank_number);
                      alert("copied");
                    }}
                  >
                    copy
                  </p>
                  <br />
                  <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                  {this.state.transaction_id != "" ? (
                    <h4>Order Id : P{this.state.transaction_id}</h4>
                  ) : (
                    <h4></h4>
                  )}
                  <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                  <br />
                  *Harap selesaikan pembayaran sesuai dengan nominal yang
                  tertera untuk mempermudah pengecekan !
                  <br />
                  *Sesuaikan nominal sampai 3 digit terakhir (kelebihan
                  transaksi akan didonasikan)
                  <br />
                  *Lakukan pembayaran maksimal 1x24 jam !
                  <br />
                  *Silahkan akses link berikut untuk melihat perkembangan donasi
                  anda! (link dikirim juga ke email anda)
                  <br />
                  <b>
                    <a
                      href={
                        Constant.BASE_URL_WEB +
                        "/donation/track?transaction_code=P" +
                        mootaMethod.transaction_id
                      }
                      target="_BLANK"
                      style={{ color: "red", textDecoration: "none" }}
                    >
                      Pantau Donasi Saya
                    </a>
                  </b>
                  <br />
                  *Tekan tombol dibawah jika sudah melakukan pembayaran!
                  <br />
                </center>
              )}
            </div>
            <a
              className="btn-dns"
              style={{ padding: 10 + "px" }}
              onClick={() => this.setState({ go_paid: true })}
            >
              <p>Selesaikan Pembayaran</p>
            </a>
          </div>
        </div>

        {loadingDiv && (
          <div className="sweet-loading loading-full">
            <ClipLoader
              css={override}
              size={40}
              color={"#e91d24"}
              loading={this.state.loading}
            />
          </div>
        )}

        <section id="donasi-box">
          <center>
            <p className="title-donasi">Donasi</p>
          </center>
          <br />

          <div id="btn_for_login">
            <center>
              <a className="red-text" href="/login">
                Silahkan Login atau isi data di bawah ini
              </a>
            </center>
          </div>

          <p className="label-form">Nama Lengkap</p>
          <input
            type="text"
            name="full_name"
            id="full_name"
            className="input-form"
            value={full_name}
            onChange={(e) => this.handleInputChange(e)}
          />

          <p className="label-form">No Handphone / Whatsapp</p>
          <input
            type="number"
            name="phone_number"
            id="phone_number"
            className="input-form"
            value={phone_number}
            onChange={(e) => this.handleInputChange(e)}
          />

          <p className="label-form">Email</p>
          <input
            type="email"
            name="email"
            id="email"
            className="input-form"
            value={email}
            onChange={(e) => this.handleSpaceEmail(e)}
          />

          <p className="label-form" id="label_phone">
            Nomor Telepon E-Wallet
          </p>
          <input
            type="number"
            name="phone"
            id="phone"
            className="input-form"
            value={phone}
            onChange={(e) => this.handleInputChange(e)}
          />
          <form
            action="#"
            style={{ display: "flex", width: "100%", alignItems: "center" }}
          >
            <input
              type="checkbox"
              id="setSamePhone"
              onChange={(e) => this.handleCheckboxChange(e)}
            />
            <p id="labelSetSamePhone" style={{ marginLeft: "10px" }}>
              gunakan nomor yang sama dengan data diri
            </p>
          </form>

          <p className="label-form">Nominal Donasi</p>
          <div className="sugest-box">
            {nominals.map((nominal, i) => {
              return (
                <a
                  key={i}
                  className="btn-sugest"
                  style={{ padding: 10 + "px" }}
                  onClick={() => this.onSuggestButtonClicked(nominal.value)}
                >
                  <p>Rp. {nominal.value}</p>
                </a>
              );
            })}
            {/* <a className="btn-sugest" style={{padding: 10+"px"}} onClick={() => this.onSuggestButtonClicked(20000)}>
                        <p>Rp. 20.000</p>
                    </a>
                    <a className="btn-sugest" style={{padding: 10+"px"}} onClick={() => this.onSuggestButtonClicked(50000)}>
                        <p>Rp. 50.000</p>
                    </a>
                    <a className="btn-sugest" style={{padding: 10+"px"}} onClick={() => this.onSuggestButtonClicked(100000)}>
                        <p>Rp. 100.000</p>
                    </a> */}
          </div>

          <p className="label-form">Nominal Donasi Lainnya</p>
          <input
            type="text"
            name="donation"
            id="donation"
            className="input-form"
            value={this.numberFormat(donation ? donation : 0)}
            onChange={(e) => this.onAmountChanged(e)}
            onBlur={(e) =>
              window.InitiateCheckout(e.target.value.replace(/\./g, ""))
            }
          />
          <br />
          <table style={{ marginBottom: 0 + "px" }} id="logo_payment">
            <tbody>
              <tr>
                <td width="30%">
                  <img src={foto} style={{ maxWidth: 80 + "px" }} />
                </td>
                <td style={{ verticalAlign: "text-top" }}>
                  {vendor ? vendor.payment_name : ""}
                </td>
              </tr>
            </tbody>
          </table>
          <a
            className="btn-dns"
            id="btn_choose_method"
            style={{ padding: 10 + "px" }}
            onClick={() => this.goToPayment()}
          >
            <p id="btn_pay">Pilih Metode Pembayaran</p>
          </a>
          <br />
          <br />
          <a
            id="btnDonasi"
            className="btn-dns"
            style={{ padding: 10 + "px" }}
            onClick={this.submitDonation}
          >
            <p>Donasi</p>
          </a>
        </section>
      </div>
    );
  }
}

export default Donate;
