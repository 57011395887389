import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../Constant'
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie'
import swal from 'sweetalert'

const cookies = new Cookies();

class Login extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            password: '',
            fcmToken: cookies.get('fcmToken'),

            go_home: false
        }
    }

    componentDidMount() {
        console.log("fcmToken", cookies.get('fcmToken'))
    }

    handleInputChange(e) {
        const { name, value } = e.target
        // if (name == "fcmToken") {
        //     this.setState({ [name]: "12321232123213"})
        // } else {
            this.setState({ [name]: value})
        // }
    }

    doLogin(){
        const {
            username, password, fcmToken, go_home
        } = this.state
        // console.log(username, password, fcmToken)

        if(username == ''){
            swal('Username Tidak Boleh Kosong')
        }else if(password == ''){
            swal('Password Tidak Boleh Kosong')
        }else{
            const parameter = {
                "username": username,
                "password": password,
                "fcmToken": cookies.get('fcmToken')
            }

            axios.post(Constant.LOGIN, parameter,  {
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                if(response.data.status == 400){
                    let result = response.data.errors
                    console.log(result)
                    swal("Username Tidak Terdaftar")
                }else if(response.data.access_token){
                    axios.get(Constant.GET_USER, {
                        headers: {
                            'Authorization': response.data.access_token
                        }
                    }).then(response2 => {
                        // console.log(response2)
                        cookies.set('username', username);
                        cookies.set('email', response2.data.email);
                        cookies.set('user_id', response2.data.id);
                        cookies.set('accessToken', response.data.access_token);

                        console.log("BERHASIL LOGIN")

                        this.setState({go_home: true})
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    render() {
        const {
            username, password, fcmToken, go_home
        } = this.state
        
        if (go_home)
            return <Redirect exact to="/home" />

        return(
        <div>

            <header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    Login
				</nav>
			</header>
            
			<section id="donasi-box">
                <center><p className="title-donasi">Login</p></center>
                <br/>

                <form>
                    <input 
                        type="text" 
                        name="username" 
                        id="username" 
                        className="input-form"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="password" 
                        name="password" 
                        id="password" 
                        className="input-form"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="hidden" 
                        name="fcmToken" 
                        id="fcmToken" 
                        className="input-form"
                        value="12321"
                        value={fcmToken}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <br/><br/>
                    
                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}}
                        onClick={() => this.doLogin()}
                    >
                        <p>Login</p>
                    </a>
                </form>
                <center>
                    <Link to="/lupa_password">
                        lupa password ?
                    </Link>
                </center>

                <br/>

                <Link to="/daftar">
                    Atau Daftar Disini !
                </Link>

                <br/><br/>
            </section>
        </div>
        )
    }
}

export default Login