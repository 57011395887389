import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie'
import swal from '../../../node_modules/sweetalert'
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';

const cookies = new Cookies();

class ZakatEmasHitung extends Component {
    constructor() {
        super()
        this.state = {
            go_back: false,
            go_zakat: false,
            go_zakat_from_count: false,
            go_pay: false,
            zakat: 0,
            jumlahEmas: 0,
            jumlahEmasUsed: 0,
            total: 0,
            hargaEmas: 0,
        }
    }

    componentDidMount() {
        axios.get(Constant.GET_PARAMETER+'harga_emas')
        .then(res => {
            console.log(res.data.data.value)
            this.setState({hargaEmas: res.data.data.value})
        }).catch(err => {
            console.log(err)
        })
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    onAmountChanged(e) {
        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })
    }
    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }
    btnHandlerChange(v){
        console.log(v)
        if(v == 'bayar'){
            this.setState({go_pay:true})
        }
    }

    validateZakat(){
        const {
            zakat
        } = this.state

        if(zakat <= 0){
            swal('Minimal zakat adalah Rp.1')
        }else{
            this.setState({go_zakat: true})
        }
    }
    validateZakatCounted(){
        const {
            total
        } = this.state

        if(total <= 0){
            swal('Minimal zakat adalah Rp.1')
        }else{
            this.setState({go_zakat_from_count: true})
        }
    }
    countZakat(){
        const {
            jumlahEmas, jumlahEmasUsed, hargaEmas, total
        } = this.state

        if(jumlahEmas <= 0){
            swal("Jumlah Emas Harus Lebih Dari 0 gr")
        }else{
            let emasDisimpan = jumlahEmas - jumlahEmasUsed
            let harga = emasDisimpan * hargaEmas
            let total = harga * 0.025
            console.log(emasDisimpan, harga, total)
            
            this.setState({total: parseInt(total)})

            document.getElementById('btn_from_hitung').hidden = false
        }
    }

    render() {
        const {
            go_back, go_pay, go_zakat_from_count, zakat, jumlahEmas, jumlahEmasUsed, total
        } = this.state

        if (go_back)
            return <Redirect exact to="/bayar-zakat" />
        else if(go_pay)
            return <Redirect exact to="/bayar-zakat/zakat-emas" />
        else if(go_zakat_from_count)
            return <Redirect exact to={{
                pathname: "/bayar-zakat/form-zakat",
                state: { 
                    kategori: "Zakat Emas",
                    total_zakat: total
                }
            }} />

        return(
        <div>

            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.setState({ go_back: true })}>
                        </i>
                        Zakat Emas
                    </nav>
                </header>
            </div>

            <CustomBreadcrumbs link={this.props.location.pathname} />

            <section id="donasi-box">
                <center>
                    <p className="zakat-title" style={{marginTop: '0px', maxWidth: 'fit-content'}}>
                        Hitung Zakat Emas
                    </p>
                </center>

                <div className="box-kategori-zakat-detail">
                    <div className="item-kategori-zakat-detail" onClick={() => this.btnHandlerChange('bayar')}>
                            <p className="title-kategori-zakat">Bayar</p>
                    </div>
                    <div className="item-kategori-zakat-detail-active" onClick={() => this.btnHandlerChange('hitung')}>
                            <p className="title-kategori-zakat-active">Hitung</p>
                    </div>
                </div>

                <hr style={{marginTop: '10px'}}/>

                <div className="box-kategori-zakat-detail" id="hitung">
                    <p className="label-form">Jumlah Emas (dalam gram) : </p>
                    <input 
                        type="text" 
                        name="jumlahEmas" 
                        id="jumlahEmas" 
                        className="input-form" 
                        value={this.numberFormat((jumlahEmas)?jumlahEmas:0)} 
                        onChange={(e) => this.onAmountChanged(e)}
                    />
                    <p className="label-form">Jumlah Emas Yang Digunakan (dalam gram) : </p>
                    <input 
                        type="text" 
                        name="jumlahEmasUsed" 
                        id="jumlahEmasUsed" 
                        className="input-form" 
                        value={this.numberFormat((jumlahEmasUsed)?jumlahEmasUsed:0)} 
                        onChange={(e) => this.onAmountChanged(e)}
                    />
                    <p className="label-form">Total : </p>
                    <input 
                        type="text" 
                        name="total" 
                        id="total" 
                        readOnly
                        className="input-form-readonly" 
                        value={this.numberFormat((total)?total:0)} 
                        onChange={(e) => this.onAmountChanged(e)}
                        style={{color: 'white'}}
                    />
                    <a
                        className="btn-dns" 
                        style={{padding: "10px", marginTop: '10px'}}
                        onClick={() => {this.countZakat()}}
                    >
                        <p>Hitung</p>
                    </a>
                    <a
                        className="btn-dns" 
                        style={{padding: "10px", marginTop: '10px'}}
                        onClick={() => {this.validateZakatCounted()}}
                        id="btn_from_hitung"
                    >
                        <p>Lanjutkan Pembayaran</p>
                    </a>
                </div>
            </section>
        </div>
        )
    }
}

export default ZakatEmasHitung