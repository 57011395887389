import React, { Component } from 'react'
import {
  Redirect,
  Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import Axios from 'axios';
import * as Constant from '../Constant'
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

const cookies = new Cookies();
class PaymentMethod extends Component {
    constructor() {
        super()
        this.state = {
            vendors: [],
            vendors_child: [],
            selected_method: 0,
            selected_vendor: null,

            go_back: false,
            loading: true
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
        this.getVendors()
        // console.log(this.props.location.state.from)
    }

    getVendors(){
        const array = this.state.vendors
        Axios.get(Constant.GET_VENDORS)
            .then(response => {
                // console.log('pertama', response)
                // this.setState({vendors: response.data.data})
                let res = response.data.data
                res.map((result, i) => {
                    Axios.get(Constant.GET_VENDORS_CHILD + result.id)
                        .then(response2 => {
                            // console.log(result.category, response2)
                            if(response2.data.data.length > 0){
                                const array2 = {
                                    "id": result.id,
                                    "category": result.category,
                                    "anak": response2.data.data
                                }
                                array.push(array2)
                                this.setState({
                                    vendors: array,
                                    loading: false
                                })
                            }
                        }).catch(error2 => {
                            console.log(error2)
                        })
                })

                this.getVendorsChild()
            })
            .catch(error => {
                console.log(error)
            })
    }
    getVendorsChild(){
        const { vendors } = this.state

        vendors.map((vendors, i) => {
            Axios.get(Constant.GET_VENDORS_CHILD + vendors.id)
                .then(response => {
                    this.setState({ vendors_child: response.data.data })
                })
                .catch(error => {
                    console.log(error)
                })  
        })
    }
    clickAction(v){
        console.log(v)
        this.setState({
            selected_method: v.id,
            selected_vendor: v.vendor
        })

        cookies.set('selected_method', v.id)
        cookies.set('vendor', v)

        if(v.vendor == 'moota'){
            cookies.set('moota_bank_id', v.moota_bank_id)
        }

        this.setState({ go_back: true })
    }
    goBack(){
        cookies.set('selected_method', null);
        cookies.set('vendor', null);
        this.setState({ go_back: true })
    }

    render() {
        const { 
            vendors, go_back
        } = this.state

        if(go_back)
            return <Redirect exact to={`${cookies.get('linkBefore')}`} />

        return (
            <div>
                <link rel="canonical" href={window.location.href}></link>

                <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.goBack()}>
                        </i>
                        Metode Pembayaran
                    </nav>
                </header>

                <section id="detprogram-box">
                    <center><p className="zakat-title" style={{marginTop: 10+'px'}}>Metode Pembayaran</p></center>
                    <br/>

                    <div className="payment-scroller" id="payment-popup-body">
                        {vendors.map((vendor, i) => {
                            if(vendor.category != "Transfer Bank (Verifikasi Otomatis 1x24jam)"){
                                return(
                                    <div key={i}>
                                        <p style={{width: 100+'%', margin: 0 +'!important', padding: 5+'px'+ 10+'px', backgroundColor: '#e6e6e6'}}>{vendor.category}</p>
                                        {vendor.anak.map((child, ii) => {
                                            let foto = Constant.GET_PICT_VENDOR + child.id
                                            return(
                                                <div key={ii}>
                                                    <div style={{paddingLeft: 30+'px !important', padding: 5+'px'+10+'px', borderBottom: 1+'px solid #e6e6e6', display: 'flex',cursor: 'pointer'}} onClick={() => this.clickAction(child)}>
                                                        <img style={{maxHeight: 40+'px', maxWidth: 100+'px'}} src={foto} alt="" />
                                                        <p style={{maxHeight: 40+'px', marginBottom: 0+'px !important', paddingTop: 10+'px', marginLeft: 20+'px'}}>{child.payment_name}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }else{
                                let from = this.props.location.state.from
                                return(
                                    <div key={i}>
                                        <p style={{width: 100+'%', margin: 0 +'!important', padding: 5+'px'+ 10+'px', backgroundColor: '#e6e6e6'}}>{vendor.category}</p>
                                        {vendor.anak.map((budak, ii) => {
                                            if(budak.bank_rekening_moota.used_for == from){
                                                let foto = Constant.GET_PICT_VENDOR + budak.id
                                                return(
                                                    <div key={i}>
                                                        <div key={ii}>
                                                            <div style={{paddingLeft: 30+'px !important', padding: 5+'px'+10+'px', borderBottom: 1+'px solid #e6e6e6', display: 'flex',cursor: 'pointer'}} onClick={() => this.clickAction(budak)}>
                                                                <img style={{maxHeight: 40+'px', maxWidth: 100+'px'}} src={foto} alt="" />
                                                                <p style={{maxHeight: 40+'px', marginBottom: 0+'px !important', paddingTop: 10+'px', marginLeft: 20+'px'}}>{budak.payment_name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                )
                            }
                        })}
                    </div>
                    
                    <div className="sweet-loading" style={{height: 50+'px'}}>
                        <ClipLoader
                            css={override}
                            size={40}
                            color={"#e91d24"}
                            loading={this.state.loading}
                        />
                    </div>
                </section>
            </div>
        )
    }
}

export default PaymentMethod