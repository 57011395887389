import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'

class Aqiqah extends Component {
    constructor() {
        super()
        this.state = {
            
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none"
        window.location.href = '/programs/infaq-sedekah'
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
    }

    render() {
        return(
        <div>
           
        </div>
        )
    }
}

export default Aqiqah