import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../../../Constant'
import { getFirebaseDatabase } from '../../../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie'
import swal from 'sweetalert'

const cookies = new Cookies();

class Form extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            name: '',

            go_chatting: false,
            go_home: false
        }
    }

    componentDidMount() {
        this.checkIfLogin()
    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }

    checkIfLogin(){
        if(localStorage.getItem('lc_email')){
            this.setState({
                name: localStorage.getItem('lc_nama'),
                email: localStorage.getItem('lc_email'),
                email: localStorage.getItem('lc_room'),
                go_chatting: true
                
            })
        }else{
            axios.get(Constant.GET_USER, {
                headers: {
                    'Authorization': cookies.get('accessToken')
                }
            }).then(res => {
                if(res.data.email != null){
                    let email = res.data.email.replace('.', '-dot-')
                    this.setState({email: email})
                    this.startChatting()
                }else{
                    console.log('gaadaEmail')
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
    startChatting(){
        const {email, name} = this.state
        console.log(name, email)
        if(name == ""){
            swal('Nama Tidak Boleh Kosong')
        }else if(email == ""){
            swal('Email Tidak Boleh Kosong')
        }else if(/(.+)@(.+){2,}\.(.+){2,}/.test(email)){
            let newEmail = email.replace(/\./g, '-dot-')

            localStorage.setItem('lc_email', email)
            localStorage.setItem('lc_room', newEmail)
            localStorage.setItem('lc_nama', name)

            this.setState({
                email: newEmail,
                go_chatting: true
            })
        }else{
            swal('Email Tidak Valid')
        }
    }

    render() {
        const {
            email, name, go_home, go_chatting
        } = this.state
        
        if (go_home)
            return <Redirect exact to="/home" />
        else if(go_chatting)
            return <Redirect exact to={{
                pathname: "/firebase/messaging/chatting",
                state: { 
                    room: email,
                    name: name,
                }
            }} />

        return(
        <div>

            <header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    Live Messaging
				</nav>
			</header>
            <div className="live-section">
                <section className="box-chat"  id="donasi-box">
                    <center><p className="title-donasi txt-size-20px">Live Messaging</p></center>
                    <br/>
                    <center><p className="desc-live">Masukan E-mail anda untuk memulai Live Messaging</p></center>
                    <input 
                        type="text" 
                        name="name" 
                        id="name"
                        placeholder="Nama"
                        value={name}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <br/>

                    <input 
                        type="text" 
                        name="email" 
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <br/>

                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}}
                        onClick={() => this.startChatting()}
                    >
                        <p>Mulai Percakapan</p>
                    </a>
                </section>
            </div>
        </div>
        )
    }
}

export default Form