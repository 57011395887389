import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';
import swal from 'sweetalert'
import CustomBreadcrumbs from '../../Components/CustomBreadcrumbs';

const cookies = new Cookies();

class TunaikanZakat extends Component {
    constructor() {
        super()
        this.state = {
            user_id: null,
            categories: [],

            zakat: 0,
            category_id: 0,
            category: '',
            full_name: (localStorage.getItem('nama')) ? localStorage.getItem('nama') : '',
            phone_number: (localStorage.getItem('phone_number')) ? localStorage.getItem('phone_number') : '',
            email: (localStorage.getItem('email')) ? localStorage.getItem('email') : '',

            go_back: false,
            go_paid: false,

            payment_method: 0,
            vendor: [],
            
            phone: '',
            card_number: '',
            exp_month: '',
            exp_year: '',
            cvn: '',
            alfamart_code: 'ALFAMARTCODEHERE!!!',

            mootaMethod: [],
            
            go_payment: false,
            checkout_url: '',
            go_via_checkout: false
        }
    }

    componentDidMount() {
        this.getZakatCategories()
        this.setUserIfLogin()

        if(cookies.get('selected_method')){
            this.getPaymentMethod()
        }else{
            document.getElementById('logo_payment').hidden = true
            document.getElementById('label_phone').hidden = true
            document.getElementById('card_number').hidden = true
            document.getElementById('exp_month').hidden = true
            document.getElementById('exp_year').hidden = true
            document.getElementById('cvn').hidden = true
            document.getElementById('phone').hidden = true
        }
        console.log(this.props.location.state)
        if(this.props.location.state){
            document.getElementById('category').disabled = true
        }

        this.setAgency()

        window.Lead()
    }

    getZakatCategories() {
        axios.get(Constant.GET_ZAKAT_CATEGORIES)
            .then(response => {
                // console.log(response.data)
                this.setState({ 
                    categories: response.data.data
                });
                this.checkCategoryFromProps()
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    setUserIfLogin(){
        if(cookies.get('user_id') != null){
            axios.get(Constant.GET_USER, {
                headers: {
                    'Authorization': cookies.get('accessToken')
                }
            }).then(response => {
                this.setState({
                    user_id: response.data.id,
                    full_name: response.data.name,
                    phone_number: response.data.no_telp,
                    email: response.data.email
                });

                document.getElementById('full_name').readOnly = true
                // document.getElementById('phone_number').readOnly = true
                document.getElementById('email').readOnly = true
                document.getElementById('btn_for_login').hidden = true

            }).catch(error => {
                console.log(error)
            })
        }
    }
    setAgency(){
        let currentUrl = window.location.href
        let breakUrl = currentUrl.split('/')
        if(breakUrl.length == 6){
            let agency = breakUrl[breakUrl.length - 1]
            axios.get(Constant.GET_AGENCY + agency)
            .then(response => {
                // console.log(response.data.id)
                cookies.set('agency_id', response.data.id)
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
        }
    }
    getPaymentMethod(){
        let selected_method = cookies.get('selected_method')
        if(selected_method != null || selected_method != ''){
            cookies.remove('linkBefore')

            if(cookies.get('customerBio')){
                this.setState({
                    zakat: cookies.get('customerBio').zakat,
                    user_id: cookies.get('customerBio').user_id,
                    full_name: cookies.get('customerBio').full_name,
                    phone_number: cookies.get('customerBio').phone_number,
                    email: cookies.get('customerBio').email,
                    category_id: cookies.get('customerBio').category_id,
                    category: cookies.get('customerBio').category,

                    payment_method: cookies.get('selected_method'),
                    vendor: cookies.get('vendor')
                })
            }

            console.log(cookies.get('vendor').payment_name)
            
            if(cookies.get('vendor').payment_name == "OVO" || cookies.get('vendor').payment_name == "LINKAJA"){
                document.getElementById('label_phone').hidden = false
                document.getElementById('phone').hidden = false
            }else{
                document.getElementById('label_phone').hidden = true
                document.getElementById('phone').hidden = true
            }

            if(cookies.get('vendor').payment_name == "Visa, Mastercard, JCB"){
                document.getElementById('card_number').hidden = false
                document.getElementById('exp_month').hidden = false
                document.getElementById('exp_year').hidden = false
                document.getElementById('cvn').hidden = false
            }else{
                document.getElementById('card_number').hidden = true
                document.getElementById('exp_month').hidden = true
                document.getElementById('exp_year').hidden = true
                document.getElementById('cvn').hidden = true
            }

            document.getElementById("btn_pay").innerHTML = "Ganti Metode Pembayaran"
        }
    }
    checkCategoryFromProps() {
        let props_category = this.props.location.state.kategori
        let props_zakat = this.props.location.state.total_zakat

        console.log(props_category, props_zakat)

        if (props_category) {
            this.state.categories.map((category) => {
                if (category.category == props_category)
                    this.setState({ 
                        category_id: category.id,
                        category: props_category
                     })
            })
        }

        if (props_zakat) this.setState({ zakat: props_zakat })
    }
    
    onAmountChanged(e) {
        let { name, value } = e.target
        this.setState({ [name]: parseInt(value.split('.').join('')) })
    }
    handleInputChange(e) {
        const { name, value } = e.target
        if (name == "category") {
            this.setState({
                [name]: e.target.options[e.target.selectedIndex].text,
                [name+"_id"]: value
            })
            // console.log(name, value, e.target.options[e.target.selectedIndex].text)
        } else {
            this.setState({ [name]: value})
        }
    }
    handleSpaceEmail(e){
        const {name, value} = e.target
        
        this.setState({
            email: value.replace(/\ /g,'')
        })
    }
    goToPayment(){
        const { 
            zakat, user_id, full_name, phone_number, email, category_id, category
        } = this.state

        let url = this.props.location.pathname
        let array = {
            'zakat': zakat,
            'user_id': user_id,
            'full_name': full_name,
            'phone_number': phone_number,
            'email': email,
            'category_id': category_id,
            'category': category,
        }

        cookies.set('linkBefore', url);
        cookies.set('customerBio', array);
        this.setState({ go_payment: true })
    }    

    setprofile(){
        if(localStorage.getItem('nama')){
            this.setState({
                full_name: localStorage.getItem('nama'),
                phone_number: localStorage.getItem('phone_number'),
                email: localStorage.getItem('email')
            })
        }

        setTimeout(() => { this.doZakat() }, 2000);
    }

    doZakat() {
        const {
            go_back, user_id, category_id, category,
            zakat, full_name, phone_number, email, vendor, phone
        } = this.state

        if(vendor.payment_type != "e_wallet"){
            if(!zakat || zakat < 10000){
                swal('Zakat Untuk Metode Pembayaran Ini Minimal Rp.10.000')
            } 
        }else{
            if(!zakat || zakat < 1){
                swal('Zakat Minimal Rp.1')
            } 
        }

        window.InitiateCheckout()

        if(category_id == "" || category_id == null){
            swal('Harap Pilih Kategori Zakat')
        }
        // else if(!zakat || zakat < 1){
        //     swal('Zakat Minimal Rp.1')
        // }
        else if(full_name == "" || full_name == null){
            swal('Nama Lengkap Tidak Boleh Kosong')
        }else if(phone_number == "" || phone_number == null){
            swal('Nomor Telepon Tidak Boleh Kosong')
        }else if(email == "" || email == null){
            swal('Email Tidak Boleh Kosong')
        }else if(/(.+)@(.+){2,}\.(.+){2,}/.test(email)){
            let vendor_name = vendor.payment_name
            // console.log(vendor_name, vendor)
            if(!vendor.payment_name){
                swal('Harap Pilih Metode Pembayaran')
            }else if(vendor_name == "Alfamart" && zakat > 2500000){
                swal('Metode Pembayaran Ini Tidak Bisa Lebih Dari Rp 2.500.000')
            }else if(vendor_name == "OVO"){
                if(phone == null || phone == ""){
                    swal('Harap Isi Nomor Telepon E-Wallet Anda')
                }else{
                    document.getElementById("ovo_popup").style.display = "block"
                }
                this.sendTransaction()
            }else{
                this.sendTransaction()
            }
        }else{
            swal('Email Tidak Valid')
        }
    }
    updateDeviceTypeTransaction(id) {
        let parameter = {
            id: id,
            transaksi_id: "Z"+id,
            device: "PWA"
        }
        axios.post(Constant.UPDATE_DEVICE_AFTER_TRANSACTION, parameter, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            .then(response => {
                // console.log(response.data)
                this.setState({ go_paid: true })
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    sendTransaction(){
        const { 
            user_id, category, zakat, vendor, payment_method, phone, 
            full_name, phone_number, email, category_id,
            card_number, exp_month, exp_year, cvn
        } = this.state

        let nomor_hp = null
        if(vendor.payment_name == 'OVO' || vendor.payment_name == 'DANA'){
            nomor_hp = phone
        }else{
            nomor_hp = phone_number
        }

        let metode_pembayaran = null
        if(vendor.payment_type == 'virtual_account'){
            metode_pembayaran = (vendor.vendor == 'midtrans') ? vendor.midtrans_code : vendor.xendit_code
        }else if(vendor.payment_name == "Alfamart"){
            metode_pembayaran = vendor.payment_name.toUpperCase()
        }else if(vendor.payment_name == "GOPAY"){
            metode_pembayaran = vendor.payment_name.toLowerCase()
        }else{
            metode_pembayaran = vendor.payment_name
        }

        if(cookies.get('moota_bank_id')){
            metode_pembayaran = cookies.get('moota_bank_id')
        }

        let array = []
        if(vendor.payment_type == 'credit_card'){
            array = {
                'account_number': card_number,
                'exp_month': exp_month,
                'exp_year': exp_year,
                'cvn': cvn
            }
        }

        const parameter = {
            'jumlah_zakat': zakat,
            'nama_produk': category,
            'nama': full_name,
            'email': email,
            'no_telp': phone_number,
            'user_id': user_id,
            'vendor': vendor.vendor,
            'payment_type': vendor.payment_type,
            'payment_method': metode_pembayaran,
            'card_data': array,
            'phone': nomor_hp,
            'device': 'PWA',
            'category_id': category_id,
            'agency_id' : cookies.get('agency_id')
        }
        // console.log(parameter, category)

        localStorage.setItem('nama', full_name)
        localStorage.setItem('email', email)
        localStorage.setItem('phone_number', phone_number)

        axios.post(Constant.XENDIT_SNAPTOKEN_ZAKAT, parameter)
            .then(response => {
                console.log(response)
                if(response.data.snap_token != null){
                    cookies.remove('linkBefore')
                    cookies.remove('customerBio')
                    cookies.remove('selected_method')
                    cookies.remove('vendor')
                    cookies.remove('moota_bank_id')
                    cookies.remove('agency_id')
                    console.log(response)
                    window.snap.pay(response.data.snap_token, {
                        // Optional
                        onSuccess: function(result){
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait'
                            // console.log(pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait')
                        },
                        // Optional
                        onPending: function(result){
                            let pisah_url = window.location.href.split('/')
                            window.location = pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait'
                            // console.log(pisah_url[0]+'//'+pisah_url[2]+'/notif/notif-wait')
                        },
                        // Optional
                        onError: function(result){
                            console.log("gagal")
                        },
                        onClose: function(result){
                            console.log(response.data)
                            let id = ''
                            if(vendor.vendor == "midtrans"){
                                id = response.data.snap_token
                            }
                            const data_send = {
                                iDtoken: id,
                                status: 1
                            }
                            axios.post(Constant.DELETE_SNAPTOKEN_ZAKAT, data_send)
                            .then(response => {
                                swal("Transaksi Dibatalkan")
                            })
                            .catch(error => {
                                console.log(error)
                            })
                        }
                    })
                }else if(response.data.moota){
                    console.log(response.data.moota)
                    const parameter = {
                        'amount': response.data.moota.amount,
                        'bank_number': response.data.moota.bank_number,
                        'transaction_id': response.data.moota.transaction_id
                    }
                    this.setState({
                        mootaMethod: parameter,
                        transaction_id: response.data.moota.transaction_id
                    })
                    cookies.remove('linkBefore')
                    cookies.remove('customerBio')
                    cookies.remove('selected_method')
                    cookies.remove('vendor')
                    cookies.remove('agency_id')
                    document.getElementById("moota_popup").style.display = "block"
                }
                else if(response.data.data_va){
                    // console.log("asdsasdasdasd", response.data.data_va)
                    const parameter = {
                        'amount': response.data.data_va.amount,
                        'bank_number': response.data.data_va.account_number,
                        'transaction_id': ''
                    }
                    this.setState({
                        loadingDiv: false,
                        mootaMethod: parameter,
                        transaction_id: ''
                    })
                    cookies.remove('linkBefore')
                    cookies.remove('customerBio')
                    cookies.remove('selected_method')
                    cookies.remove('vendor')
                    cookies.remove('agency_id')
                    // document.getElementById("btnDonasi").style.pointerEvents = 'none';
                    document.getElementById("moota_popup").style.display = "block"
                }
                else{
                    cookies.remove('linkBefore')
                    cookies.remove('customerBio')
                    cookies.remove('selected_method')
                    cookies.remove('vendor')
                    cookies.remove('agency_id')
                    if(response.data.data_ew){
                        this.setState({transaction_id: response.data.data_ew.transaction_id})
                    }else if(response.data.data_ro){
                        this.setState({
                            alfamart_code: response.data.data_ro.payment_code,
                            transaction_id: response.data.data_ro.transaction_id
                        })
                    }

                    window.setTimeout(function() {
                        if(metode_pembayaran == 'DANA' || metode_pembayaran == 'LINKAJA'){
                            window.location.href = response.data.data_ew.checkout_url
                        }
                        if(response.data.data_ro){
                            document.getElementById("alfamart_popup").style.display = "block"
                        }
                    }, 1000)
                }
            })
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    copy(){
        let code = this.state.alfamart_code
        console.log(code)

        var copyText = document.getElementById('code');
		copyText.select();
		copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
    }

    render() {
        const {
            go_back, categories, category_id, category,
            zakat, full_name, phone_number, email, go_paid,
            go_payment, alfamart_code, phone, card_number, exp_month, exp_year, cvn, vendor, mootaMethod
        } = this.state

        let foto = ''

        if(vendor){
            foto = Constant.GET_PICT_VENDOR+vendor.id
        }

        if (go_back)
            return <Redirect exact to="/bayar-zakat" />
        else if(go_paid)
            return <Redirect exact to="/notif/notif-wait" />
        else if(go_payment){
            // return <Redirect exact to="/payment_method" />
            return <Redirect exact to={{
                pathname: "/payment_method",
                state: { 
                    from: "Zakat",
                }
            }} />
        }

        return(
        <div>

            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.setState({ go_back: true })}>
                        </i>
                        Tunaikan Zakat
                    </nav>
                </header>
            </div>

            <CustomBreadcrumbs link={this.props.location.pathname} />

            {/* OVO */}
            <div id="ovo_popup" className="modalfilter">
                <div className="filter-content">
                    <span className="close" onClick={() => document.getElementById("ovo_popup").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">OVO</p>
                    <p className="all-category">
                        <img src={foto} style={{maxWidth: 150+'px'}}/>
                    </p>
                    <div className="scroller-category" style={{height: 70+'vh', padding: '30px'}}>
                        <center>
                            <b>Instruksi Pembayaran</b>
                            <br/>
                            Nominal Yang Akan Didonasikan : 
                            <br/>
                            <h2 >Rp. {this.numberFormat((zakat)?zakat:0)}</h2>
                            <br/>
                            Periksa aplikasi OVO anda dan segera selesaikan pembayaran dalam 30 detik!
                        </center>
                        <hr style={{marginTop: '0px', marginBottom: '0px'}}/>
                            <h4>
                                Order Id : P{this.state.transaction_id}
                            </h4>
                        <hr style={{marginTop: '0px', marginBottom: '0px'}}/>
                        <b>Notes : </b>
                        <br/>
                        Apabila transaksi gagal, silahkan coba cara berikut : 
                        <br/>
                        1. Masukan kembali nomor telepon dan coba kembali pembayaran.
                        <br/>
                        2. Bersihkan cache aplikasi OVO anda.
                        <br/>
                        3. Apabila transaksi masih gagal, silahkan coba kembali dengan metode pembayaran yang lain. Anda TIDAK akan dikenakan biaya dua kali untuk mencoba kembali pembayaran
                        <br/>
                        <br/>
                    </div>
                    <a className="btn-dns" style={{padding: 10+"px"}} onClick={() => this.setState({go_paid: true})}><p>Saya Sudah Bayar</p></a>
                </div>
            </div>

            {/* ALFAMART */}
            <div id="alfamart_popup" className="modalfilter">
                <div className="filter-content">
                    <span className="close" onClick={() => document.getElementById("alfamart_popup").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">ALFAMART</p>
                    <p className="all-category">
                        <img src={foto} style={{maxWidth: 150+'px'}}/>
                    </p>
                    <div className="scroller-category">
                        <center>
                            <b>Instruksi Pembayaran</b>
                            <br/>
                            Nominal Yang Akan Didonasikan : 
                            <br/>
                            <h2 style={{marginBottom: 0+'px'}}>Rp. {this.numberFormat((zakat)?zakat:0)}</h2>
                            <br/>
                            <h3 style={{marginBottom: 0+'px'}}>{alfamart_code}</h3>
                            <br/>
                            <hr style={{marginTop: '0px', marginBottom: '0px'}}/>
                                <h4>
                                    Order Id : P{this.state.transaction_id}
                                </h4>
                            <hr style={{marginTop: '0px', marginBottom: '0px'}}/>
                            <br/>
                            Harap simpan kode pembayaran di atas untuk melakukan pembayaran!
                        </center>
                    </div>
                    <a className="btn-dns" style={{padding: 10+"px"}} onClick={() => this.setState({go_paid: true})}><p>Selesaikan Pembayaran</p></a>

                </div>
            </div>

            {/* Moota */}
            <div id="moota_popup" className="modalfilter">
                <div className="filter-content" style={{minHeight: '92vh'}}>
                    <span className="close" onClick={() => document.getElementById("moota_popup").style.display = "none"}>
                        &times;
                    </span>

                    <p className="head-filter">Nama Bank Nya</p>
                    <p className="all-category">
                        <img src={foto} style={{maxWidth: 150+'px'}}/>
                    </p>
                    <div className="scroller-category">
                        <center>
                            <b>Instruksi Pembayaran</b>
                            <br/>
                            Nominal Yang Akan Didonasikan : 
                            <br/>
                            <h2 style={{marginBottom: 0+'px'}}>Rp. {this.numberFormat((mootaMethod.amount)?mootaMethod.amount:0)}</h2>
                            <br/>
                            <h3 style={{marginBottom: 0+'px'}}>No Rek : {mootaMethod.bank_number}</h3>
                            <br/>
                            <hr style={{marginTop: '0px', marginBottom: '0px'}}/>
                                {(this.state.transaction_id != '') ? <h4>Order Id : Z{this.state.transaction_id}</h4> : <h4></h4> }
                            <hr style={{marginTop: '0px', marginBottom: '0px'}}/>
                            <br/>
                            *Harap selesaikan pembayaran sesuai dengan nominal yang tertera untuk mempermudah pengecekan !
                            <br/>
                            *Sesuaikan nominal sampai 3 digit terakhir (kelebihan transaksi akan didonasikan)
                            <br/>
                            *Lakukan pembayaran maksimal 1x24 jam !
                            <br/>
                            *Silahkan akses link berikut untuk melihat perkembangan donasi anda! (link dikirim juga ke email anda)
                            <br/>
                            <b><a href={Constant.BASE_URL_WEB+'/zakat/track?transaction_code=P'+mootaMethod.transaction_id} target="_BLANK" style={{color: 'red', textDecoration: 'none'}}>Pantau Donasi Saya</a></b>
                            <br/>
                            *Tekan tombol dibawah jika sudah melakukan pembayaran!
                            <br/>
                        </center>
                    </div>
                    <a className="btn-dns" style={{padding: 10+"px"}} onClick={() => this.setState({go_paid: true})}><p>Selesaikan Pembayaran</p></a>

                </div>
            </div>

            <section id="donasi-box">
                <center><p className="title-donasi">Tunaikan Zakat</p></center>
                <br />
                <form>
                    <div id="btn_for_login">
                        <center>
                            <a className="red-text" href="/login">
                                Silahkan Login atau isi data di bawah ini
                            </a>
                        </center>
                    </div>

                    <p className="label-form">Nama Lengkap</p>
                    <input 
                        type="text"
                        name="full_name"
                        id="full_name"
                        className="input-form"
                        value={full_name}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <p className="label-form">No Handphone / Whatsapp</p>
                    <input 
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        className="input-form"
                        value={phone_number}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <p className="label-form">Email</p>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className="input-form"
                        value={email}
                        onChange={(e) => this.handleSpaceEmail(e)}
                    />
                    <select 
                        name="category" 
                        id="category" 
                        className="input-form" 
                        value={category_id} 
                        onChange={(e) => this.handleInputChange(e)}
                    >                        
                
                    {/* <p className="label-form">Kategpri</p> */}
                    <option value="0" key="-1">--- Pilih Kategori ---</option>
                    {categories.map((category, i) => {
                        console.log(category)
                        return(
                            <option value={category.id} key={i}>
                                {category.category}
                            </option>
                        )
                    })}
                    </select>
                    {/* <input 
                        type="text" 
                        name="category" 
                        id="category" 
                        className="input-form" 
                        value={category} 
                        readOnly
                        onChange={(e) => this.onAmountChanged(e)}
                    /> */}

                    <p className="label-form">Jumlah Zakat</p>
                    <input 
                        type="text" 
                        name="zakat" 
                        id="zakat" 
                        className="input-form" 
                        value={this.numberFormat((zakat)?zakat:0)} 
                        onChange={(e) => this.onAmountChanged(e)}
                    />

                    <br />
                    <table style={{marginBottom:0+'px'}} id="logo_payment">
                        <tbody>
                            <tr>
                                <td width="30%">
                                    <img src={foto} style={{maxWidth: 80+'px'}}/>
                                </td>
                                <td style={{verticalAlign: 'text-top'}}>
                                    {(vendor) ? vendor.payment_name : ''}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <a className="btn-dns" id="btn_choose_method" style={{padding: 10+"px"}} onClick={() => this.goToPayment()}><p id="btn_pay">Pilih Metode Pembayaran</p></a>

                    <p className="label-form" id="label_phone">Nomor Telepon E-Wallet</p>
                    <input type="number" name="phone" id="phone" className="input-form" value={phone} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* credit_card */}
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="card_number" placeholder="Card Number" id="card_number" className="input-form" value={card_number} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="exp_month" placeholder="Expired Month" id="exp_month" className="input-form" max="12" value={exp_month} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="exp_year" placeholder="Expired Year" id="exp_year" className="input-form" max="2100" value={exp_year} onChange={(e) => this.handleInputChange(e)} />
                    
                    {/* <p className="label-form" id="label_phone"></p> */}
                    <input type="number" name="cvn" placeholder="CVN" id="cvn" className="input-form" value={cvn} onChange={(e) => this.handleInputChange(e)} />
                    {/* end credit_card */}
                    <br />
                    <br />

                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}} 
                        onClick={() => {this.doZakat()}}
                    >
                        <p>Lanjutkan Pembayaran</p>
                    </a>
                </form>
               
            </section>
        </div>
        )
    }
}

export default TunaikanZakat