import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../Constant'
import axios from 'axios'
import Cookies from 'universal-cookie';
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

const cookies = new Cookies();

class RiwayatZakat extends Component {
    constructor() {
        super()
        this.state = {
            page:1 ,
            last_page: 1,
            berhasil: [],
            pending: [],
            gagal: [],
            products: [],
            loading: true,

            go_home: false
        }

        this.timer = null
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        // this.trackScrolling()
        this.getHistoryZakat()
        this.getParamterStatusBerhasil()
        this.getParamterStatusPending()
        this.getParamterStatusGagal()

        document.addEventListener('scroll', this.trackScrolling)
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }
    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight  && 
            this.state.page != this.state.last_page) 
        {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({ 
                page: next,
                loading: true 
            })
            this.getHistoryZakat()
            console.log('there it is')
        }
    }
    getHistoryZakat() {
        const { 
            page
        } = this.state
        let query_url = `?page=${page}`

        axios.get(Constant.RIWAYAT_ZAKAT + query_url, {
            headers: {
                'Authorization': cookies.get('accessToken')
            }
        })
        .then(response => {
            if (page == 1) {
                this.setState({ 
                    products: response.data.data,
                    last_page: response.data.last_page,
                    loading: false
                })
            } else {
                let newUpdates = this.state.products.concat(response.data.data)
                this.setState({ 
                    products: newUpdates,
                    last_page: response.data.last_page,
                    loading: false
                })
            }
        })
        // Catch any error here
        .catch(error => {
            console.log(error)
        })
    }
    getParamterStatusBerhasil(){
        const {
            berhasil
        } = this.state

        axios.get(Constant.GET_PARAMETER + 'status_berhasil',
        ).then(response => {
            let array = response.data.data.value.split(',')

            this.setState({berhasil: array})
        }).catch(error => {
            console.log(error)
        })
    }
    getParamterStatusPending(){
        const {
            pending
        } = this.state

        axios.get(Constant.GET_PARAMETER + 'status_pending',
        ).then(response => {
            let array = response.data.data.value.split(',')

            this.setState({pending: array})
        }).catch(error => {
            console.log(error)
        })
    }
    getParamterStatusGagal(){
        const {
            gagal
        } = this.state

        axios.get(Constant.GET_PARAMETER + 'status_gagal',
        ).then(response => {
            let array = response.data.data.value.split(',')
            
            this.setState({gagal: array})
        }).catch(error => {
            console.log(error)
        })   
    }

    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret', 
                'April', 'Mei', 'Juni', 'Juli', 
                'Agustus', 'September', 'Oktober', 
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", " 
                                + new_date.getDate() + " "
                                + months[new_date.getMonth()] + " "
                                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    render() {
        const {
            berhasil, pending, gagal,
            products, go_home
        } = this.state

        if (go_home)
            return <Redirect exact to="/home" />

        return(
        <div>

			<header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    Riwayat Zakat
				</nav>
			</header>
            
			<section id="riwayat-box-2">
                <div className="scroller-detail-program" id="programs_scroller">
                    {products.map((product, i) => {
                        let status = ""
                        let text = ""

                        berhasil.map((success, i2) => {
                            if(product.status == success){
                                status = "status-green"
                                text = "Berhasil"
                            }
                        })

                        pending.map((pend, i3) => {
                            if(product.status == pend){
                                status = "status-orage"
                                text = "Tertunda"
                            }
                        })

                        gagal.map((failed, i2) => {
                            if(product.status == failed){
                                status = "status-red"
                                text = "Gagal"
                            }
                        })

                        let final_day = new Date(product.updated_at)
                        let waktu = product.updated_at.split(' ')
                        let jam = waktu[1].split(':')
                        let final_jam = jam[0]+':'+jam[1]+' WIB'

                        return(
                            <div className="card-program" key={i}>
                                <table className="tbl-qurban" style={{marginBottom: 0}}>
                                    <tbody>
                                        <tr>
                                            <td className="first-td">Jenis Zakat</td>
                                            <td>:</td>
                                            <td>{(product.detail.category) ? product.detail.category.category : null}</td>
                                        </tr>
                                        <tr>
                                            <td className="first-td">Nominal</td>
                                            <td>:</td>
                                            <td>Rp. {this.numberFormat(product.total_zakat)}</td>
                                        </tr>
                                        <tr>
                                            <td className="first-td">Status</td>
                                            <td>:</td>
                                            <td><p className={status}>{text}</p></td>
                                            {/* <!-- status-green/red/orage --> */}
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="garis-card"></div>
                                <div className="tanggal-card">
                                    <i id="ic-kalendar" className="fa fa-calendar"></i>
                                    <p id="tanggal">
                                        {this.dateFormat(final_day)}
                                    </p>
                                </div>
                                <div className="kategori-card" style={{color:'#94999F'}}>
                                    {final_jam}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="sweet-loading" style={{height: 50+'px'}}>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={"#e91d24"}
                        loading={this.state.loading}
                    />
                </div>
            </section>
        </div>
        )
    }
}
export default RiwayatZakat