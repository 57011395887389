import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import * as Constant from '../Constant'
import axios from 'axios'
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

class ListCustomerService extends Component {
    constructor() {
        super()
        this.state = {
            customerServices: [],
            page: 1,
            last_page: 1,
            go_home: false,
            loading: true,
        }

        this.timer = null
        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling)
        this.getPrograms()
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }

    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight && 
            this.state.page != this.state.last_page) 
        {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({ 
                page: next,
                loading: true 
            })
            this.getPrograms()
            // console.log('there it is')
        }
    }

    getPrograms() {
        const { page, search, category_id, category_id_before } = this.state
        
        let halaman = 1;
        if(category_id != category_id_before){
            this.setState({
                page: 1
            })
            halaman = 1
        }else{
            halaman = page
        }

        let query_url = `?page=${halaman}`

        axios.get(Constant.GET_CS + query_url)
            .then(response => {
                // console.log(response.data.last_page, "didieu")
                if (page == 1) {
                    this.setState({ 
                        customerServices: response.data.data.data,
                        last_page: response.data.data.last_page,
                        loading: false
                    })
                } else {
                    if(category_id != category_id_before){
                        console.log('timpa')
                        this.setState({ 
                            customerServices: response.data.data.data,
                            last_page: response.data.data.last_page,
                            page: 1,
                            loading: false
                        })  
                        console.log(response.data.data.data)
                    }else{
                        console.log('tambah')
                        let newCustomerServices = this.state.customerServices.concat(response.data.data.data)
                        this.setState({ 
                            customerServices: newCustomerServices,
                            last_page: response.data.data.last_page,
                            loading: false
                        })
                        console.log(response.data.data.data)
                    }
                }
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const { match } = this.props
        const { 
            go_home, customerServices
        } = this.state

        // console.log(customerServices, "disini")

        if (go_home)
            return <Redirect exact to="/home" />
        
        return (
        <section id="program-list-box">

            <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_home:true})}>
                    </i>
                    List Customer Service
                </nav>
            </header>
            
            <div className="scroller-program" id="programs_scroller">
                {/* <div className="card-program" onClick={() => window.open('https://wa.me/6282112409497', '_blank')}>
                    <div>
                    <div className="img-program" style={{backgroundImage: `url(https://mizanamanah.or.id/cewe.jpg)`}}></div>
                    <div className="desc-program">
                    <p className="title-card-program">Vira (Virtual Assistant)</p>
                        <p className="desc-card-program">+6282112409497</p>
                    </div>
                    </div>
                </div> */}
            {customerServices.map((cs, i) => {
                let foto, phone_number_62, url
                if(cs.username == 'cs_muri'){
                    foto = 'https://mizanamanah.or.id/cowo.jpg'
                }else{
                    foto = 'https://mizanamanah.or.id/cewe.jpg'
                }

                if(cs.no_telp != null){
                    phone_number_62 = '+62'+cs.no_telp.substring(1)
                    url = 'https://wa.me/'+phone_number_62
                }else{
                    phone_number_62 = 'Maaf, Nomor Telepon Customer Service Akan Segera Diperbarui'
                    url = ''
                }
                return (
                    <div className="card-program" key={i} onClick={() => window.open(url, '_blank')}>
                        <div>
                        <div className="img-program" style={{backgroundImage: `url(${foto})`}}></div>
                        <div className="desc-program">
                        <p className="title-card-program">{cs.name}</p>
                            <p className="desc-card-program">{phone_number_62}</p>
                        </div>
                        </div>
                    </div>
                )
            })}
            </div>

            <div className="sweet-loading" style={{height: 50+'px'}}>
                <ClipLoader
                    css={override}
                    size={40}
                    color={"#e91d24"}
                    loading={this.state.loading}
                />
            </div>
        </section>
        )
    }
}

export default ListCustomerService