import React, { Component } from 'react'
import {
  Redirect,
  Link
} from "react-router-dom"
import Cookies from 'universal-cookie'
import Axios from 'axios';
import * as Constant from '../Constant'
import swal from 'sweetalert'

const cookies = new Cookies();
class Header extends Component {
    constructor() {
        super()
        this.state = {
            id: '',
            gone: '',
            go_profile: false,
            go_notif: false,

            menus: [],
        }
    }

    componentDidMount() {
        this.ifLogin()
        this.getMenus()
    }

    klikEvent(){
        console.log("asda")
    }

    handleCheck(e){
        console.log(e)
    }

    ifLogin(){
        // console.log(cookies.get('user_id'))
        if(cookies.get('user_id') != null){
            document.getElementById('login').hidden = true
            Axios.get(Constant.GET_USER, {
                headers: {
                    'Authorization': cookies.get('accessToken')
                }
            }).then(response => {
                this.setState({id: response.data.id})
            }).catch(error => {
                console.log(error)
            })
        }else{
            document.getElementById('logout').hidden = true
            document.getElementById('hr1').hidden = true
            document.getElementById('riwayat_donasi').hidden = true
            document.getElementById('riwayat_zakat').hidden = true
            document.getElementById('riwayat_qurban').hidden = true
            document.getElementById('hr2').hidden = true
            document.getElementById('profile').hidden = true
            document.getElementById('ganti_password').hidden = true
            document.getElementById('ic_notify').hidden = true
            document.getElementById('profile_pic').hidden = true
            document.getElementById('header_kanan').hidden = true

            this.setState({gone: "none"})
        }
    }
    getMenus(){
        Axios.get(Constant.GET_MENUS)
        .then(res => {
            this.setState({
                menus: res.data.data
            })
        }).catch(err => {
            console.log(err)
        })
    }

    render() {
        const { 
            id, gone, go_profile, go_notif
        } = this.state

        let image_link = ''
        
        if(cookies.get('user_id') != null)
            image_link = Constant.GET_PROFILE_PICTURE + id

        if(go_profile)
            return <Redirect exact to='/profile' />
        else if(go_notif)
            return <Redirect exact to='/notification' />

        return (
        <div>
            <header id="header">
                <nav className="left">
                    <a href="#menu"><span>Menu</span></a>
                </nav>
                <a href="#" className="logo">
                    <img src="images/logo-mizan-amanah-white.png" alt="" id="logo-img" />
                </a>
                <div className="div-notif" id="header_kanan">
					<div onClick={() => this.setState({go_notif: true})}><i id="ic_notify" className="fa fa-bell" style={{color: '#ffffff', fontSize: 25+'px', display: `${gone}`}}></i></div>
					<div id="profile_pic" className="img-profile" style={{backgroundImage: `url(${image_link})`}} onClick={ () => this.setState({go_profile: true}) }></div>
				</div>
            </header>

            <nav id="menu">
                <center style={{marginBottom:20+"px"}}>
                    <img src="images/logo-mizan-amanah-panjang.png" alt="" id="logo-sidebar" />
                </center>
                <ul className="links">
                    <li>
                    <Link to="/home">
                        <i id="ic-sidebar" className="fa" style={{marginRight: '20px', maxWidth: '25px'}}><img src='/icon/Icon Mizan Home.png' /></i>
                        Home
                    </Link>
                    </li>
                    
                    {
                        this.state.menus.map((menu, m) => {
                            let url = ''
                            let icon = ''
                            
                            if(menu.menu.icon != null){
                                icon = '/'+menu.menu.icon
                            }else{
                                icon = '/icon/Icon Mizan Update.png'
                            }

                            if(menu.judul_menu == 'Program'){
                                url = '/programs'
                            }else{
                                url = '/'+menu.menu.url
                            }
                            if(menu.judul_menu == "Qurban"){
                                return(
                                    <li key={m}>
                                    <a href="https://qurban.mizanamanah.or.id">
                                        <i id="ic-sidebar" className="fa" style={{marginRight: '22px',  maxWidth: '25px'}}><img src="/icon/Icon Mizan Qurban.png" /></i>
                                        Qurban
                                    </a>
                                    </li>
                                )
                            }else if(menu.judul_menu == 'Event'){
                                return(
                                    <li onClick={() => console.log("clicked!")} key={m}>
                                        <div onClick={() => console.log("clicked!")}>
                                        <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Event.png' /></i>
                                            Event
                                        </div>

                                        <div className="itm-dropdown" id="konserAmal" style={{lineHeight: 3+'em',paddingLeft: 44+'px'}}>
                                            <i id="ic-sidebar" className="fa" style={{maxWidth: '20px'}}><img src='/icon/Icon Mizan Konser Amal.png' /></i>
                                            <a href="https://konseramal.mizanamanah.or.id" style={{textDecoration: 'none',color: '#000', marginLeft: '-15px'}}>
                                                Konser Amal
                                            </a>
                                        </div>
                                    </li>
                                )
                            }else if(menu.judul_menu == 'Galang Dana'){
                                // if(cookies.get('user_id')){
                                    return(
                                        <li key={m}>
                                        <Link to={url}>
                                            <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src={icon} /></i>
                                            {menu.judul_menu}
                                        </Link>
                                        </li>
                                    )
                                // }else{
                                //     return(
                                //         <li key={m} onClick={() => swal("Harus Login Dulu")}>
                                //             <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src={icon} /></i>
                                //             {menu.judul_menu}
                                //         </li>
                                //     )
                                // }
                            }else{
                                return(
                                    <li key={m}>
                                    <Link to={url}>
                                        <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src={icon} /></i>
                                        {menu.judul_menu}
                                    </Link>
                                    </li>
                                )
                            }
                        })
                    }

                    {/* <li>
                    <Link to="/aqiqah">
                    <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Aqiqah.png' /></i>
                        Aqiqah Online
                    </Link>
                    </li> */}

                    <hr style={{marginTop: 0+"px", marginBottom: 0+"px"}} id="hr1"/>
                        <li id="riwayat_donasi">
                        <Link to="/riwayat_donasi">
                            <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Riwayat Donasi.png' /></i>
                            Riwayat Donasi
                        </Link>
                        </li>
                        
                        <li id="riwayat_zakat">
                        <Link to="/riwayat_zakat">
                            <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Riwayat Zakat.png' /></i>
                            Riwayat Zakat
                        </Link>
                        </li>
                        
                        <li id="riwayat_qurban">
                        <Link to="/riwayat_qurban">
                            <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Riwayat Qurban.png' /></i>
                            Riwayat Qurban
                        </Link>
                        </li>
                    <hr style={{marginTop: 0+"px", marginBottom: 0+"px"}} id="hr2"/>
                    
                    <li id="profile">
                        <a href="/profile">
                            <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Profile.png' /></i>
                            Profil
                        </a>
                    </li>

                    <li id="ganti_password">
                        <a href="/profile/ganti_password">
                            <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Ganti Password.png' /></i>
                            Ganti Password
                        </a>
                    </li>

                    <li id="login">
                    <Link to="/login">
                        <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Login.png' /></i>
                        Login
                    </Link>
                    </li>

                    <li id="logout">
                    <Link to="/logout">
                        <i id="ic-sidebar" className="fa" style={{maxWidth: '25px'}}><img src='/icon/Icon Mizan Logout.png' /></i>
                        Logout
                    </Link>
                    </li>
                </ul>
            </nav>
        </div>
        )
    }
}

export default Header