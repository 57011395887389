import React, { Component } from 'react'
// import * as Constant from '../Constant'
import {
    Redirect,
} from 'react-router-dom'

class AfterPay extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
    }

    render() {
        const { go_home } = this.state

        if (go_home)
            return <Redirect exact to='/home' />

        return(
        <div>       

            <header id="header">
                <nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({ go_home: true })}>
                    </i>
                    Notifikasi
                </nav>
            </header>

            <section id="detprogram-box">
                <center><p className="zakat-title" style={{marginTop: 0+'px'}}>Notifikasi Transaksi</p></center>
                <center>
                    <div style={{width: 100+'px'}}>
                        <img src="/bell.png" style={{marginTop: 50+'px'}}/>
                    </div>
                </center>

                <br />
                <p className="txt-zakat">
                Terima kasih atas donasi dan partisipasi anda. Setelah anda selesai melakukan transfer dana donasi. anda akan mendapatkan konfirmasi melalui email. Semoga Allah membalas dengan pahala yang berlipat.
                </p>

                <a 
                    className="btn-dns" 
                    style={{padding: "10px"}} 
                    onClick={() => this.setState({ go_home: true })}
                >
                    <p>Kembali Ke Home</p>
                </a>
            </section>
        </div>
        )
    }
}

export default AfterPay