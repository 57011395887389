import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import * as Constant from '../../Constant'
import axios from 'axios'
// Loading
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from 'universal-cookie';
// -----

// loading css
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #e91d24;
`;

const cookies = new Cookies();

class GalangDanaDetial extends Component {
    constructor() {
        super()
        this.state = {
            galdan: {},
            image_link: '',

            target: 0,
            collected: 0,
            percentage: 0,
            final_day: Date.now(),
            donatur: 0,

            total_donatur: 0,
            donaturs: [],
            
            page: 1,
            last_page: 1,
            loading: true,

            go_back: false,
            go_donate: false
        }

        this.trackScrolling = this.trackScrolling.bind(this)
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none"
        this.getProgramDetail()

        document.addEventListener('scroll', this.trackScrolling)
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
        
        document.removeEventListener('scroll', this.trackScrolling)
    }
    
    trackScrolling() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.getElementById("programs_scroller")
        const html = document.documentElement
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight  && 
            this.state.page != this.state.last_page) 
        {
            // console.log(windowBottom + " == " + docHeight)
            // reach to bottom
            let next = this.state.page + 1
            this.setState({ 
                page: next,
                loading: true 
            })
            this.getDonaturs()
        }
    }
    getProgramDetail() {
        let { match:{ params } } = this.props
        axios.get(Constant.GET_GALANG_DANA_DETAIL + params.seoKey)
            .then(response => {
                let galdan = response.data
                let image_link = ''

                if(galdan.location == 'WEB'){
                    image_link = Constant.BASE_URL_WEB+'/admin/assets/media/foto-program/'+galdan.slider
                }else{
                    image_link = Constant.BASE_URL+'/admin/assets/media/foto-program/'+galdan.slider
                }
                
                this.setState({ 
                    galdan: galdan,
                    image_link: image_link,

                    target: galdan.target,
            
                    final_day: galdan.jangka_waktu,
                });
            })
            // Catch any error here
            .catch(error => {
                console.log(error)
            })
    }
    numberFormat(value) {
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }).replace(/\,/g, '.')
    }
    dateFormat(date) {
        if (date) {
            let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum`at', 'Sabtu']
            let months = [
                'Januari', 'Februari', 'Maret', 
                'April', 'Mei', 'Juni', 'Juli', 
                'Agustus', 'September', 'Oktober', 
                'November', 'Desember'
            ]

            let new_date = new Date(date)
            let formatted_date = days[new_date.getDay()] + ", " 
                                + new_date.getDate() + " "
                                + months[new_date.getMonth()] + " "
                                + new_date.getFullYear()

            return formatted_date
        } else {
            return "Tanpa Batas Waktu"
        }
    }

    render() {
        const { match, match:{ params } } = this.props
        const { 
            galdan, image_link, go_back, go_donate,
            target, collected, percentage, final_day, donatur, total_donatur, donaturs
        } = this.state

        let seo = galdan.seo

        let url = this.props.location.pathname.split('/')
        let pls = null

        if(url[3]){
            pls = seo+"/"+url[3]
        }else{
            pls = seo
        }

        if (go_back)
            return <Redirect exact to='/galang_dana' />
            
        return(
            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i id="ic-sidebar" className="fa fa-arrow-left" onClick={() => this.setState({ go_back: true })}></i>
                        {String(galdan.judul).substring(0, 32)}...
                    </nav>
                </header>

                <section id="riwayat-box">
                    <div className="scroller-program" id="programs_scroller" style={{marginTop: 10+'px'}}>
                        <div className="card-program">
                            <div className="banner-program" style={{backgroundImage: `url(${image_link})`}}>
                            </div>
                            <p className="title-card-program txt-18">{galdan.judul}</p>
                            <p className="txt-batas-waktu">{this.dateFormat(final_day)}</p>
                            <progress id="file" value={percentage} max="100"> {percentage}% </progress>
                            <p className="txt-donasi">{(galdan.approve == 1) ? 'Approved' : 'Not Yet Approved'}</p>
                            <p className="txt-batas-waktu">Dana Target Rp. {this.numberFormat(target)}</p>
                            <div className="content-detail">
                                { ReactHtmlParser(galdan.deskripsi) }
                            </div>
                        </div>
                    </div>
                    
                    
                </section>
            </div>
        )
    }
}

export default GalangDanaDetial