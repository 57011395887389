import React, { Component } from 'react'
import {
    Redirect,
} from 'react-router-dom'
import Axios from 'axios';
import * as Constant from '../Constant'
import ReactHtmlParser from 'react-html-parser';
import CustomBreadcrumbs from '../Components/CustomBreadcrumbs';

class SyaratDanKetentuan extends Component {
    constructor() {
        super()
        this.state = {
            go_home: false,
            data: {}
        }
    }

    componentDidMount() {
        document.body.style.backgroundImage = "none";
        this.getData()
    }
    componentWillUnmount() {
        document.body.style.backgroundImage = `url('/images/.jpg')`
    }

    getData(){
        Axios.get(Constant.SYARAT_DAN_KATENTUAN)
        .then(response => {
            this.setState({
                data: response.data
            })
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        const { go_home, data } = this.state

        if (go_home)
            return <Redirect exact to='/home' />

        return(
        <div>  
            
            <div>
                <header id="header">
                    <nav className="left header-kembali">
                        <i 
                            id="ic-sidebar" 
                            className="fa fa-arrow-left" 
                            onClick={() => this.setState({ go_home: true })}>
                        </i>
                        {data.judul_page}
                    </nav>
                </header>
            </div>

            <CustomBreadcrumbs link={this.props.location.pathname}/>

            <section id="detprogram-box">
                <center><p className="zakat-title" style={{marginTop: 0+'px'}}>{data.judul_page}</p></center>
                <br/>
                { ReactHtmlParser(data.konten_page) }
            </section>
        </div>
        )
    }
}

export default SyaratDanKetentuan