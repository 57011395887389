import React, { Component } from 'react'
import {
    Redirect,
    Link
} from 'react-router-dom'
import * as Constant from '../Constant'
import { askForPermissioToReceiveNotifications } from '../push-notification'
import axios from 'axios'
import Cookies from 'universal-cookie';
import swal from 'sweetalert'

const cookies = new Cookies();

class Daftar extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            email: '',
            nama: '',
            no_telp: '',
            password: '',
            password_confirm: '',
            bio: '',
            alamat: '',

            go_back: false,
            go_home: false
        }
    }

    componentDidMount() {
        
    }

    handleInputChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }

    send(){
        const {
            username, email, nama, no_telp, password, password_confirm, bio, alamat
        } = this.state

        if(username == null || username == ''){
            swal('Username Tidak Boleh Kosong')
        }else if(email == null || email == ''){
            swal('Email Tidak Boleh Kosong')
        }else if(nama == null || nama == ''){
            swal('Nama Tidak Boleh Kosong')
        }else if(no_telp == null || no_telp == ''){
            swal('Nomor Telepon Tidak Boleh Kosong')
        }else if(password == null || password == ''){
            swal('Password Tidak Boleh Kosong')
        }else if(password_confirm == null || password_confirm == ''){
            swal('Ketik Ulang Password Tidak Boleh Kosong')
        }else if(password != password_confirm){
            swal('Password Tidak Sama')
        }else if(bio == null || bio == ''){
            swal('Bio Tidak Boleh Kosong')
        }else if(alamat == null || alamat == ''){
            swal('Alamat Tidak Boleh Kosong')
        }else if(/(.+)@(.+){2,}\.(.+){2,}/.test(email)){
            const parameter = {
                "username": username,
                "email": email,
                "name": nama,
                "no_telp": no_telp,
                "password": password,
                "bio": bio,
                "alamat": alamat
            }

            axios.post(Constant.REGISTER, parameter).
            then(response => {
                if(response.data.status == 400){
                    swal(response.data.error)
                }else{
                    swal('Email Terkirim, mohon cek email untuk reset password !')
                    this.setState({go_home: true})
                }
            }).catch(error => {
                console.log(error)
            })
        }else{
            swal('Email Tidak Valid')
        }
    }

    render() {
        const {
            username, email, nama, no_telp, password, password_confirm, bio, alamat, go_back, go_home
        } = this.state
        
        if (go_back)
            return <Redirect exact to="/login" />
        else if(go_home)
            return <Redirect exact to="/home" />

        return(
        <div>

            <header id="header">
				<nav className="left header-kembali">
                    <i 
                        id="ic-sidebar" 
                        className="fa fa-arrow-left" 
                        onClick={() => this.setState({go_back:true})}>
                    </i>
                    Daftar
				</nav>
			</header>
            
			<section id="donasi-box">
                <center><p className="title-donasi">Daftar</p></center>
                <br/>

                <form>
                    <input 
                        type="text"
                        name="username" 
                        id="username" 
                        className="input-form"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="email"
                        name="email" 
                        id="email" 
                        className="input-form"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="text"
                        name="nama" 
                        id="nama" 
                        className="input-form"
                        placeholder="Nama"
                        value={nama}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="number"
                        name="no_telp" 
                        id="no_telp" 
                        className="input-form"
                        placeholder="No Telp"
                        value={no_telp}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="password"
                        name="password" 
                        id="password" 
                        className="input-form"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="password"
                        name="password_confirm" 
                        id="password_confirm" 
                        className="input-form"
                        placeholder="Ketik Ulang Password"
                        value={password_confirm}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="text"
                        name="bio" 
                        id="bio" 
                        className="input-form"
                        placeholder="Bio"
                        value={bio}
                        onChange={(e) => this.handleInputChange(e)}
                    />
                    
                    <input 
                        type="text"
                        name="alamat" 
                        id="alamat" 
                        className="input-form"
                        placeholder="Alamat"
                        value={alamat}
                        onChange={(e) => this.handleInputChange(e)}
                    />

                    <br/><br/>
                    
                    <a 
                        className="btn-dns" 
                        style={{padding: "10px"}}
                        onClick={() => this.send()}
                    >
                        <p>Daftar Sekarang</p>
                    </a>
                </form>
                <center>
                    <Link to="/lupa_password">
                        Sudah Punya Akun, Masuk
                    </Link>
                </center>
                <br/><br/>
            </section>
        </div>
        )
    }
}

export default Daftar